import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-tripSummaryReports',
    templateUrl: './tripSummaryReports.component.html',
    styleUrls: ['./tripSummaryReports.component.scss']
})

export class TripSummaryReports implements OnInit {

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;




    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicleNumber: any;
    dashboardData: any;

    tripSummaryData: any = [];
    imeiNumber: any;
    address: any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getVehicleList();
        // this.getTripSummaryData();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicleNumber = this.gpsDevicesArray[0].vehicleNumber;
            this.imeiNumber = this.gpsDevicesArray[0].imeiNumber;
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
            // console.log('--------------selectedVehicleNumber>>>>', this.selectedVehicleNumber);
            // console.log('--------------imeiNumber>>>>', this.imeiNumber);
        })
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {

        let value = [];
        this.dashboardData.forEach(tripDateObj => {
            tripDateObj.tripData.forEach(trip => {
                let subValueArray = [];
                subValueArray.push(tripDateObj.tripDate);
                subValueArray.push(trip.startTime);
                subValueArray.push(trip.endTime);
                subValueArray.push(trip.totalDistance);
                subValueArray.push(trip.stopDuration);
                subValueArray.push(trip.endLocation);
                value.push(subValueArray);
            });

        });
        let headerName = "Trip Summary Report (" + this.fromDate.formatted + " to " + this.toDate.formatted + ")";
        let body = {
            "headerName": headerName,
            "excelName": "tripSummaryReport",
            "header": [
                "Date (M/D/Y)",
                "Trip Start Time",
                "Trip End Time",
                "Number of KM",
                "Trip Duration",
                "End Point"
            ],
            "value": value
        }
        // console.log("body ====" + JSON.stringify(body));
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    async getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        const dashBoardParams = {
            compLocation: { 'lat': 12.6960116, 'lng': 77.443121 },
            toDate: this.toDate.formatted,
            fromDate: this.fromDate.formatted,
            // imeiNumber: '0868003030837574',
            imeiNumber: this.imeiNumber,
        }

        //  console.log("dashBoardParams === >>" + JSON.stringify(dashBoardParams));

        try {
            let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
            let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
            let dashboardData = await this.httpService.httpPostPromise(AppConstants.get_trans_company_vehicle_trip_report, dashBoardParams, "", reqOptions);
            // console.log(JSON.stringify(dashboardData));
            this.isPdfNotAvailable = false;
            if (dashboardData && dashboardData.statusCode === 200) {
                this.dashboardData = dashboardData.result;
                // console.log(this.dashboardData)
                for (const dayTrip of this.dashboardData) {
                    dayTrip.tripData.forEach(async trip => {

                        trip.stopDuration = this.getTimeDifference(trip.tripStartTime, trip.tripEndTime)
                        trip.startTime = this.convertToLocalStringFormatHhMmSs(new Date(trip.tripStartTime));
                        trip.endTime = this.convertToLocalStringFormatHhMmSs(new Date(trip.tripEndTime));
                        // console.log('element startTime');
                        // console.log(element.stopDuration);
                        if (trip.tripEndLocation) {
                            let path = `${AppConstants.Google_GeoCoding_API}latlng=${trip.tripEndLocation.lat},${trip.tripEndLocation.lng}`;
                            let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
                            let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
                            try {
                                let addressObj = await this.httpService.httpGetPromise(path, "", reqOptions);
                                trip.endLocation = addressObj.results[0].formatted_address;
                            } catch (err) {
                                console.log('Error:' + err);
                            }
                        }

                    });
                }
                // this.dashboardData.startAndStopCoords.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
                this.loading = false;
            }
        } catch (err) {
            this.loading = false;
        }
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/genericxls', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange() {
        this.getTableData();
    }

    // getTripSummaryData() {
    //     this.httpService.getTripSummary().subscribe((tripSummary) => {
    //         console.log('***********************************************************************');
    //         console.log(tripSummary);
    //         this.tripSummaryData = tripSummary.result[0];
    //         console.log(this.tripSummaryData[0].tripDate);
    //         console.log(this.tripSummaryData[0].tripData.vehicleNumber);
    //     })
    // }

    convertToLocalStringFormatHhMmSs(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        // minutes = minutes < 10 ? '0'+minutes : minutes;
        minutes = ('0' + minutes).slice(-2);
        seconds = ('0' + seconds).slice(-2);
        // let localTimeFormat = hours + ':' + minutes + ' ' + ampm;
        let localTimeFormat = `${hours}:${minutes}:${seconds} ${ampm}`;
        return localTimeFormat;
    }

    getTimeDifference(startTime, endTime) {
        let startDate = new Date(startTime);
        // Do your operations
        let endDate = new Date(endTime);
        let seconds = (endDate.getTime() - startDate.getTime()) / 1000;

        let d = Number(seconds);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        let hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
        let mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
        let sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
        return hDisplay + mDisplay + sDisplay;
    }
    // addressData(addressObject) {

    //     this.getAddressFromGoogle(addressObject.lat, addressObject.lng).then((google_response) => {
    //         const geo_address: any = google_response.results[0].formatted_address;

    //         this.address = geo_address;
    //         // alert(address);
    //         // console.log('====> kk' + this.address);
    //     }).catch((google_error) => {
    //         // handle errors
    //         console.log('Googole Error' + google_error);

    //     });


    // }

    // getAddressFromGoogle(lat, lng) {
    //     let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
    //     let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
    //     let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    //     return this.httpService.httpGetPromise(path, "", reqOptions);
    // }


}
