export class Utils {
    public static log(msg:string){
        console.log(msg);
    }
    public static async fileUploadToAwsS3(imageData) {

        var dataURItoBlob = (dataURI) => {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return  new Blob([new Uint8Array(array)], { type: "image/jpeg" });
        };

        //imgData returned from camera getPicture
        var body = await dataURItoBlob(imageData);
        // var body = imageData;

        return  await new Promise((resolve, reject) => {
            let AWS = (<any>window).AWS;
            AWS.config.update({
                accessKeyId: 'AKIAJFGUQHJS5H6BPROQ',
                secretAccessKey: '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',
                region: 'ap-south-1',
                endpoint: 'https://s3.ap-south-1.amazonaws.com/',
                sslEnabled: false,
                s3ForcePathStyle: true,
                signatureVersion: 'v4'
            });

            let awsS3Options = {
                'params': { 'Bucket': 'bizdeskimguploads' },
                'accessKeyId': 'AKIAJFGUQHJS5H6BPROQ',
                'secretAccessKey': '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',
                'endpoint': 'https://s3.ap-south-1.amazonaws.com/',
                'region': 'ap-south-1'
            };


            let modifiedName: string = `${Date.now()}_attached`;
            let awsS3UploadParams = {
                'Bucket': 'bizdeskimguploads',
                'Key': `engimgs/${modifiedName}`,
                'ContentType': "image/jpeg",
                'Body': body
            };

            let bucket = new AWS.S3(awsS3Options);
            bucket.upload(awsS3UploadParams, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data);

            });
        });

    }
}