import { Injectable } from '@angular/core';

@Injectable()
export class URLStringFormatter {

    format(...args: any[]) {
        var theString = args[0];
        for (var i = 1; i < args.length; i++) {
            var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
            theString = theString.replace(regEx, args[i]);
        }
        
        return theString;
    }
}