import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, DoCheck {

    // hide SideBare
    hideSideBar: boolean = true;

    isSpeedViolationVisible: boolean = false;
    isMunispalityReportVisible: boolean = false;
    isStoppageReportVisible: boolean = false;
    isDeviationReportVisible: boolean = false;
    isDailySummaryReportVisible: boolean = false;
    isVehicleAndClusterPlannerVisible: boolean = false;
    isVehicleHistorySummaryVisible: boolean = false;
    isTripSummaryVisible: boolean = false;
    isallVehicleHistoryVisible: boolean = false;
    isSingleVehicleHistoryVisible: boolean = false;
    isDeviceInstallationReportVisible: boolean = false;
    isAcUsageVisible: boolean = false;
    isFuelSummaryReportVisible: boolean = false;
    isDestinationReportVisible: boolean = false;
    isLRCreation: boolean = false;
    isLRHistory: boolean = false;
    isVehicleEnter: boolean = false;

    isDashBoardVisible: boolean = false;
    issandAvalabityVisible: boolean = false;
    isdumpsReachesVisible: boolean = false;
    iscontactsVisible: boolean = false;
    istrackYourOrderVisible: boolean = false;
    isSandTripDetailsVisible: boolean = false;
    isSandVehicleTrackingVisible: boolean = false;
    isDilyStopageReportVisible: boolean = false;
    isIntervalBandLocationVisible: boolean = false;

    isAisDeviceDashboardVisible: boolean = false;
    isDeviceRequestVisible: boolean = false;
    isDeviceAssignVisible: boolean = false;

    assignedRole: any;
    activePage: any;
    componentToShow: any;
    pageToShow: any = [];

    constructor(private router: Router) {
    }

    ngOnInit() {
        if (localStorage.getItem("assignedRole")) {
            this.assignedRole = JSON.parse(localStorage.getItem("assignedRole"));
            this.consutructSideBar();
        } else {
            this.router.navigate(['/landing']);
        }
    }

    consutructSideBar() {
        this.assignedRole.reports.forEach(element => {
            // console.log('element ===>', element)
            if (element == 'OverSpeed') {
                let pageObject = {
                    'displayName': 'Speed Violation',
                    'isActive': false,
                    'component': 'OverSpeed'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'Stopage') {
                let pageObject = {
                    'displayName': 'Stoppage Report',
                    'isActive': false,
                    'component': 'Stopage'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'Deviation') {
                let pageObject = {
                    'displayName': 'Deviation Report',
                    'isActive': false,
                    'component': 'Deviation'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'Muncipality') {
                let pageObject = {
                    'displayName': 'Muncipalitylity Report',
                    'isActive': false,
                    'component': 'Muncipality'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'DailySummary') {
                let pageObject = {
                    'displayName': 'Daily Summary Report',
                    'isActive': false,
                    'component': 'DailySummary'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'VehicleAndClusterPlanner') {
                let pageObject = {
                    'displayName': 'Client Cluster Planner',
                    'isActive': false,
                    'component': 'VehicleAndClusterPlanner'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'tripSummary') {
                let pageObject = {
                    'displayName': 'Trip Summary Report',
                    'isActive': false,
                    'component': 'tripSummary'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'allVehicleHistory') {
                let pageObject = {
                    'displayName': 'All Vehicle History',
                    'isActive': false,
                    'component': 'allVehicleHistory'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'singleVehicleHistory') {
                let pageObject = {
                    'displayName': 'Single Vehicle History',
                    'isActive': false,
                    'component': 'singleVehicleHistory'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'deviceInstallationReport') {
                let pageObject = {
                    'displayName': 'Device Installation Report',
                    'isActive': false,
                    'component': 'deviceInstallationReport'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'acUsageReport') {
                let pageObject = {
                    'displayName': 'AC Usage Report',
                    'isActive': false,
                    'component': 'acUsageReport'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'fuelUsageReport') {
                let pageObject = {
                    'displayName': 'Fuel Summary Report',
                    'isActive': false,
                    'component': 'FuelSummary'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'createSourceDestination') {
                let pageObject = {
                    'displayName': 'Destination Reports',
                    'isActive': false,
                    'component': 'createSourceDestination'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'lRCreation') {
                let pageObject = {
                    'displayName': 'Lorry Receipt',
                    'isActive': false,
                    'component': 'lRCreation'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'lRHistory') {
                let pageObject = {
                    'displayName': 'Lorry Receipt History',
                    'isActive': false,
                    'component': 'lRHistory'
                }
                this.pageToShow.push(pageObject);

            } else if (element == 'Dashboard') {
                let pageObject = {
                    'displayName': 'Dashboard',
                    'isActive': false,
                    'component': 'Dashboard'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'DumpsAndReaches') {
                let pageObject = {
                    'displayName': 'Dumps & Reaches',
                    'isActive': false,
                    'component': 'DumpsAndReaches'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'SandAvailability') {
                let pageObject = {
                    'displayName': 'Sand Availability',
                    'isActive': false,
                    'component': 'SandAvailability'
                }
                this.pageToShow.push(pageObject);

            } else if (element == 'SandTripDetails') {
                let pageObject = {
                    'displayName': 'Sand Trip Details',
                    'isActive': false,
                    'component': 'SandTripDetails'
                }
                this.pageToShow.push(pageObject);

            } else if (element == 'SandVehicleTrack') {
                let pageObject = {
                    'displayName': 'Sand Vehicle Track',
                    'isActive': false,
                    'component': 'SandVehicleTrack'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'Contacts') {
                let pageObject = {
                    'displayName': 'Contacts',
                    'isActive': false,
                    'component': 'Contacts'
                }
                this.pageToShow.push(pageObject);
            } else if (element == 'TrackYourOrder') {
                let pageObject = {
                    'displayName': 'Track Your Order',
                    'isActive': false,
                    'component': 'TrackYourOrder'
                }
                this.pageToShow.push(pageObject);
            }
            else if (element == 'lRVehicleEntry') {
                let pageObject = {
                    'displayName': 'LR Vehicle Entry',
                    'isActive': false,
                    'component': 'PlantEntry'
                }
                this.pageToShow.push(pageObject);
            }
            else if (element == 'DailyStopageReport') {
                let pageObject = {
                    'displayName': 'Daily Stopage Report',
                    'isActive': false,
                    'component': 'DailyStopageReport'
                }
                this.pageToShow.push(pageObject);
            }
            else if (element == 'IntervalBandLocation') {
                let pageObject = {
                    'displayName': 'Interval Band Location',
                    'isActive': false,
                    'component': 'IntervalBandLocation'
                }
                this.pageToShow.push(pageObject);
            }
            else if (element == 'ClientClustering') {
                let pageObject = {
                    'displayName': 'Client  Clustering',
                    'isActive': false,
                    'component': 'ClientClustering'
                }
                this.pageToShow.push(pageObject);
            }
            else if (element == 'DeviceRequest') {
                let pageObject = {
                    'displayName': 'Device Request',
                    'isActive': false,
                    'component': 'DeviceRequest'
                }
                this.pageToShow.push(pageObject);
            }
            else if (element == 'AISDeviceDashboard') {
                let pageObject = {
                    'displayName': 'AIS Device Dashboard',
                    'isActive': false,
                    'component': 'AISDeviceDashboard'
                }
                this.pageToShow.push(pageObject);
            }

            else if (element == 'DeviceAssign') {
                let pageObject = {
                    'displayName': 'Device Assign',
                    'isActive': false,
                    'component': 'DeviceAssign'
                }
                this.pageToShow.push(pageObject);
            }

            else if (element == 'CentralizedLR') {
                let pageObject = {
                    'displayName': 'Centralized LR',
                    'isActive': false,
                    'component': 'CentralizedLR'
                }
                this.pageToShow.push(pageObject);
            }
            else if (element == 'MedicalTripSummaryReport') {
                let pageObject = {
                    'displayName': 'Pharma Logistsics Report',
                    'isActive': false,
                    'component': 'MedicalTripSummaryReport'
                }
                this.pageToShow.push(pageObject);
            }

            else if (element == 'MedicalTripTracker') {
                let pageObject = {
                    'displayName': 'Medical Trip Tracker',
                    'isActive': false,
                    'component': 'MedicalTripTracker'
                }
                this.pageToShow.push(pageObject);
            }

        });
        this.pageToShow[0].isActive = true;

        // if (this.pageToShow[0].component == 'OverSpeed') {
        //     this.onSpeedViolationClick();
        // } else if (this.pageToShow[0].component == 'Stopage') {
        //     this.onStoppageReportClick();
        // } else if (this.pageToShow[0].component == 'Deviation') {
        //     this.onDeviationReportClick();
        // } else if (this.pageToShow[0].component == 'Muncipality') {
        //     this.onMunispalityReportClick();
        // } else if (this.pageToShow[0].component == 'DailySummary') {
        //     this.onDailySummaryReportClick();
        // } else if (this.pageToShow[0].component == 'VehicleAndClusterPlanner') {
        //     this.onVehicleAndClusterPlannerClick();
        // } else if (this.pageToShow[0].component == 'vehicleHistorySummary') {
        //     this.onVehicleHistorySummaryClick();
        // } else if (this.pageToShow[0].component == 'tripSummary') {
        //     this.onTripSummaryClick();
        // } else if (this.pageToShow[0].component == 'allVehicleHistory') {
        //     this.onallVehicleHistoryClick();
        // } else if (this.pageToShow[0].component == 'singleVehicleHistory') {
        //     this.onSingleVehicleHistoryClick();
        // } else if (this.pageToShow[0].component == 'deviceInstallationReport') {
        //     this.onDeviceInstallationReportClick();
        // } else if (this.pageToShow[0].component == 'acUsageReport') {
        //     this.onAcUsageReportClick();
        // } else if (this.pageToShow[0].component == 'FuelSummary') {
        //     this.onFuelSummaryReportClick();
        // } else if (this.pageToShow[0].component == 'createSourceDestination') {
        //     this.onDestinationReportClick();
        // } else if (this.pageToShow[0].component == 'lRCreation') {
        //     this.onLRCreationk();
        // } else if (this.pageToShow[0].component == 'lRHistory') {
        //     this.onLRHistory();
        // } else if (this.pageToShow[0].component == 'Dashboard') {
        //     this.onDashboard();
        // } else if (this.pageToShow[0].component == 'DumpsAndReaches') {
        //     this.onDumpsAndReaches();
        // } else if (this.pageToShow[0].component == 'SandAvailability') {
        //     this.onSandAvailability();
        // } else if (this.pageToShow[0].component == 'SandTripDetails') {
        //     this.onSandTripDetails();
        // } else if (this.pageToShow[0].component == 'SandVehicleTrack') {
        //     this.onSandVehicleTrack();
        // } else if (this.pageToShow[0].component == 'Contacts') {
        //     this.onContacts();
        // } else if (this.pageToShow[0].component == 'TrackYourOrder') {
        //     this.onTrackYourOrder();
        // } else if (this.pageToShow[0].component == 'PlantEntry') {
        //     this.onPlantEntry();
        // } else if (this.pageToShow[0].component == 'DailyStopageReport') {
        //     this.onDailyStopageReport();
        // } else if (this.pageToShow[0].component == 'IntervalBandLocation') {
        //     this.onIntervalBandLocation();
        // }  else if (this.pageToShow[0].component == 'DeviceRequest') {
        //     this.onDeviceRequestComponent();
        // } else if (this.pageToShow[0].component == 'AISDeviceDashboard') {
        //     this.onAisDeviceDashboardComponent();
        // } else if (this.pageToShow[0].component == 'DeviceAssign') {
        //     this.onDeviceAssignComponent();
        // }
        // this.componentToShow = this.pageToShow[0].component;
    }

    onReportClick(page, index) {
        // console.log(`${page.component}`)
        this.router.navigate([`/${page.component}`]);
        this.pageToShow.forEach(element => {
            element.isActive = false;
        });
        this.pageToShow[index].isActive = true;
        // if (page.component == 'OverSpeed') {
        //     this.onSpeedViolationClick();
        // } else if (page.component == 'Stopage') {
        //     this.onStoppageReportClick();
        // } else if (page.component == 'Deviation') {
        //     this.onDeviationReportClick();
        // } else if (page.component == 'Muncipality') {
        //     this.onMunispalityReportClick();
        // } else if (page.component == 'DailySummary') {
        //     this.onDailySummaryReportClick();
        // } else if (page.component == 'VehicleAndClusterPlanner') {
        //     this.onVehicleAndClusterPlannerClick();
        // } else if (page.component == 'vehicleHistorySummary') {
        //     this.onVehicleHistorySummaryClick();
        // } else if (page.component == 'tripSummary') {
        //     this.onTripSummaryClick();
        // } else if (page.component == 'allVehicleHistory') {
        //     this.onallVehicleHistoryClick();
        // } else if (page.component == 'singleVehicleHistory') {
        //     this.onSingleVehicleHistoryClick();
        // } else if (page.component == 'deviceInstallationReport') {
        //     this.onDeviceInstallationReportClick();
        // } else if (page.component == 'acUsageReport') {
        //     this.onAcUsageReportClick();
        // } else if (page.component == 'FuelSummary') {
        //     this.onFuelSummaryReportClick();
        // } else if (page.component == 'createSourceDestination') {
        //     this.onDestinationReportClick();
        // } else if (page.component == 'lRCreation') {
        //     this.onLRCreationk();
        // } else if (page.component == 'lRHistory') {
        //     this.onLRHistory();
        // } else if (page.component == 'Dashboard') {
        //     this.onDashboard();
        // } else if (page.component == 'DumpsAndReaches') {
        //     this.onDumpsAndReaches();
        // } else if (page.component == 'SandAvailability') {
        //     this.onSandAvailability();
        // } else if (page.component == 'SandTripDetails') {
        //     this.onSandTripDetails();
        // } else if (page.component == 'SandVehicleTrack') {
        //     this.onSandVehicleTrack();
        // } else if (page.component == 'Contacts') {
        //     this.onContacts();
        // } else if (page.component == 'TrackYourOrder') {
        //     this.onTrackYourOrder();
        // } else if (page.component == 'PlantEntry') {
        //     this.onPlantEntry();
        // } else if (page.component == 'DailyStopageReport') {
        //     this.onDailyStopageReport();
        // } else if (page.component == 'IntervalBandLocation') {
        //     this.onIntervalBandLocation();
        // } else if (page.component == 'DeviceRequest') {
        //     this.onDeviceRequestComponent();
        // }  else if (page.component == 'AISDeviceDashboard') {
        //     this.onAisDeviceDashboardComponent();
        // } else if (page.component == 'DeviceAssign') {
        //     this.onDeviceAssignComponent();
        // }
        this.activePage = page;
        this.componentToShow = page.component;
    }

    onSpeedViolationClick() {
        this.isMunispalityReportVisible = false;
        this.isStoppageReportVisible = false;
        this.isSpeedViolationVisible = true;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;
        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;

    }

    onMunispalityReportClick() {
        this.isMunispalityReportVisible = true;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onStoppageReportClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = true;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onDeviationReportClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = true;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onDailySummaryReportClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = true;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onVehicleAndClusterPlannerClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = true;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onVehicleHistorySummaryClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isTripSummaryVisible = false;
        this.isVehicleHistorySummaryVisible = true;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onallVehicleHistoryClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = true;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onSingleVehicleHistoryClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = true;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onDeviceInstallationReportClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = true;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onTripSummaryClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = true;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onAcUsageReportClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = true;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onFuelSummaryReportClick() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = true;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onDestinationReportClick() {

        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = true;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onLRCreationk() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = true;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }
    onLRHistory() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = true;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }

    onDashboard() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = true;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }
    onDumpsAndReaches() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = true;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;

    }
    onSandAvailability() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = true;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }
    onSandTripDetails() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = true;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }
    onSandVehicleTrack() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = true;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;


    }
    onContacts() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = true;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }
    onTrackYourOrder() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = true;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;

    }
    onPlantEntry() {
        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = true;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }
    onDailyStopageReport() {

        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = true;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;
    }
    onIntervalBandLocation() {

        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = true;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;

    }
    onAisDeviceDashboardComponent() {

        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = true;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = false;

    }
    onDeviceRequestComponent() {

        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = true;
        this.isDeviceAssignVisible = false;

    }
    onDeviceAssignComponent() {

        this.isMunispalityReportVisible = false;
        this.isSpeedViolationVisible = false;
        this.isStoppageReportVisible = false;
        this.isDeviationReportVisible = false;
        this.isDailySummaryReportVisible = false;
        this.isVehicleAndClusterPlannerVisible = false;
        this.isVehicleHistorySummaryVisible = false;
        this.isTripSummaryVisible = false;
        this.isallVehicleHistoryVisible = false;
        this.isSingleVehicleHistoryVisible = false;
        this.isDeviceInstallationReportVisible = false;
        this.isAcUsageVisible = false;
        this.isFuelSummaryReportVisible = false;
        this.isDestinationReportVisible = false;
        this.isLRCreation = false;
        this.isLRHistory = false;

        this.isDashBoardVisible = false;
        this.issandAvalabityVisible = false;
        this.isdumpsReachesVisible = false;
        this.iscontactsVisible = false;
        this.istrackYourOrderVisible = false;
        this.isSandTripDetailsVisible = false;
        this.isSandVehicleTrackingVisible = false;
        this.isVehicleEnter = false;
        this.isDilyStopageReportVisible = false;
        this.isIntervalBandLocationVisible = false;
        this.isAisDeviceDashboardVisible = false;
        this.isDeviceRequestVisible = false;
        this.isDeviceAssignVisible = true;

    }

    onLogoutClick() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }
    ngDoCheck() {
        const url = this.router.url;
        const spliting = url.lastIndexOf('/');
        const result = url.substring(spliting + 1);
        // console.log(' URL results');
        // console.log(result);
        if (result === 'landing') {
            localStorage.clear();
            this.pageToShow = [];
            return this.hideSideBar = false;
        } if (result === 'login') {
            this.pageToShow = [];
            localStorage.clear();
            return this.hideSideBar = false;
        } else {
            // this.consutructSideBar();
            return this.hideSideBar = true;
        }

    }
}
