import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Message } from 'primeng/primeng';
import { FileUpload } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { Headers, RequestOptions } from '@angular/http';
declare var require: any;
import Swal from 'sweetalert2'

import { Utils } from '../shared/utilsClass/Utils';

require('aws-sdk/dist/aws-sdk');


@Component({
  selector: 'app-device-request',
  templateUrl: './device-request.component.html',
  styleUrls: ['./device-request.component.scss']
})
export class DeviceRequestComponent implements OnInit {

  date: { year: number, month: number };
  model: NgbDateStruct;
  deviceRequest: FormGroup;
  submitted = false;
  userToken: any;
  supplyDeviceAssignData: any = [];


  selectedDate: any;

  msgs: Message[];
  uploadedFiles: any[] = [];
  @ViewChild('fileInput') fileInput: FileUpload;


  UrgencyType: any = [
    { 'name': 'Slow', 'value': 0 },
    { 'name': 'Moderate', 'value': 1 },
    { 'name': 'Urgent', 'value': 2 },
  ];
  urgencyLevel: number;
  loading: boolean = false;

   docObj = {
    docUrl: '',
    comment: '',
    title: ''
  };

  DeviceRequestObj: any;
  addDeviceRequestObj = [];
  isVisbaleAddFile = false;
  base64:any;
  imageUrl: any;
  userMobileNumber: any;
  todayDate: any;

  constructor(private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private sanitizer: DomSanitizer,
    private httpService: HttpService,
  ) { }

  ngOnInit() {
    this.createdeviceRequest();
    this.userMobileNumber = localStorage.getItem('userMobile')
    console.log('this.userMobileNumber', this.userMobileNumber);
    this.userToken = localStorage.getItem("userToken");
    this.getSupplyDeviceAssignByMobileNumber();
  }

  changePaymentType($event) {
    console.log($event.target.value);
    this.urgencyLevel = parseInt($event.target.value);
    console.log('this.paymentmodevalue', this.urgencyLevel);
    this.deviceRequest.get('registrationPaymentMode').setValue($event.target.value, {
      onlySelf: true
    });
  }

  createdeviceRequest() {
    this.deviceRequest = this.formBuilder.group({
      mobileNumber: '',
      noOfPieces: '',
      amountDeposited:'',
      dateOfReqistion: '',
      urgencyLevel: '',
      // slipImageUrl:this.formBuilder.array([]),
      slipImageUrl:''
    });
  }

  onSubmit() {
    this.submitted = true;
    this.deviceRequest.value.mobileNumber = this.userMobileNumber;
    this.deviceRequest.value.dateOfReqistion = new Date().toISOString();
    this.deviceRequest.value.slipImageUrl = this.docObj;
    if(this.addDeviceRequestObj.length !=0){
     this.addDeviceRequestObj.forEach(addComplaint => {
       Utils.fileUploadToAwsS3(addComplaint.docUrl).then((awsS3FileResult: any) => {
         addComplaint.docUrl =  awsS3FileResult.Location;
        this.deviceRequest.value.slipImageUrl = addComplaint;
          console.log(this.deviceRequest.value);
        

      let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option 
      
        this.httpService.httpPostPromise(AppConstants.post_create_supply_device_request, this.deviceRequest.value, '', reqOptions).then((data) => {
             console.log(' Sucess data', data);
             Swal.fire({
                position: 'top',
                type: 'success',
                title: 'Request has been completed',
                showConfirmButton: false,
                timer: 1500
              })

               this.isVisbaleAddFile = false;
               this.docObj = {
                  docUrl: '',
                  comment: '',
                  title: '',
                };
                // this.deviceRequest.reset(this.deviceRequest.value);
                this.deviceRequest.reset();
                this.addDeviceRequestObj = [];

        }).catch((err) => {
            let timerInterval
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong please try again later!',
                  timer: 2000,
                  onBeforeOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                    }, 100)
                  },
                  onClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.timer
                  ) {
                    console.log('I was closed by the timer')
                  }
                })
            console.log(err)
            this.loading = false;

        })
        });});
      } else {
         console.log("Else ",this.deviceRequest.value);

      let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option 
      
        this.httpService.httpPostPromise(AppConstants.post_create_supply_device_request, this.deviceRequest.value, '', reqOptions).then((data) => {
             console.log(' Sucess data', data);
               this.isVisbaleAddFile = false;
               this.docObj = {
                  docUrl: '',
                  comment: '',
                  title: '',
                };
                // this.deviceRequest.reset(this.deviceRequest.value);
                this.deviceRequest.reset();
                this.addDeviceRequestObj = [];
                Swal.fire({
                position: 'top',
                type: 'success',
                title: 'Request has been completed Without Attachemt',
                showConfirmButton: true,
              })

        }).catch((err) => {
            let timerInterval
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong please try again later!',
                  timer: 2000,
                  onBeforeOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                    }, 100)
                  },
                  onClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.timer
                  ) {
                    console.log('I was closed by the timer')
                  }
                })
            console.log(err)
            this.loading = false;

        })
      }



   
  }
   onAddRow() {
    this.isVisbaleAddFile = true;
  }

  deleteFieldaddCheif(index) {
    this.addDeviceRequestObj.splice(index, 1);
  }
  hideChiefComplaint() {
    this.isVisbaleAddFile = false;
     this.docObj = {
        docUrl: '',
        comment: '',
        title: '',
      };
  }

  addFieldValue(deviceObj) {
      deviceObj.docUrl = this.base64;

      this.addDeviceRequestObj.push(deviceObj);
      console.log(this.addDeviceRequestObj);

      this.docObj = {
        docUrl: '',
        comment: '',
        title: '',
      };
      // this.model = null;
      //  this.addDeviceRequestObj.forEach(addComplaint => {
      //  Utils.fileUploadToAwsS3(addComplaint.docUrl).then((awsS3FileResult: any) => {
      //   let data = awsS3FileResult.Location;
      //   console.log('imageS3',data);
      //   // this.patientObj.forEach(cheifComplaint => {
      //   //   const cheifCompObj = {
      //   //     docUrl: this.imageS3,
      //   //     title: addComplaint.title,
      //   //     comment: addComplaint.comment,
      //   //     submissionDate: addComplaint.submissionDate
      //   //   };
      //   //   cheifComplaint.cheifComplaintsObj.push(cheifCompObj);
      //   //   console.log(this.patientObj[0].cheifComplaintsObj);
      //   //   this.updateMedicalHistory(this.patientObj[0]);
      //   // });


      // });});
     
      this.isVisbaleAddFile = false;
    
  }

    public picked(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.handleInputChange(file); // turn into base64
      // }
    } else {
      alert('No file selected');
    }
  }

  handleInputChange(files) {
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
        this.docObj = {
        docUrl: '',
        comment: '',
        title: '',
      };
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    const base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    this.base64 = 'data:image/jpeg;base64,' + base64result;

    console.log(this.base64);

  }
   getMyProperty(chiefObj) {

    if (chiefObj.docUrl.startsWith('data:image/png;base64,')) {
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(chiefObj.docUrl);
      // this.imgURL = imgUrls;
      // console.log(imgUrl);
    } else {
      if (chiefObj.docUrl.startsWith('https://s3.ap-south-1.amazonaws.com')) {
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(chiefObj.docUrl);
      } else {
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + chiefObj.docUrl);
      }

    }
    return this.imageUrl;

  }

  getSupplyDeviceAssignByMobileNumber(){
     //let path = `${AppConstants.get_supply_device_assign_by_mobile_number}${this.userMobileNumber}`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        //console.log(AppConstants.get_supply_device_assign_by_mobile_number + '9999999999');
          this.httpService.httpGetPromise(AppConstants.get_supply_device_assign_by_mobile_number + this.userMobileNumber, "", reqOptions)
          .then((assignDevice) => {
           this.supplyDeviceAssignData = assignDevice.supplyDeviceAssignData;
           console.log(this.supplyDeviceAssignData);
        })
  }


}
