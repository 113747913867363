import { Component, OnInit } from '@angular/core';
import $ from 'jquery'

@Component({
    selector: 'app-livePin',
    templateUrl: './livePin.html',
    styleUrls: ['./livePin.scss']
})

export class LivePinComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}
