import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-trackYourOrder',
    templateUrl: './trackYourOrder.component.html',
    styleUrls: ['./trackYourOrder.component.scss']
})

export class TrackYourOrder implements OnInit {


    @ViewChild("mapRef") mapRef: ElementRef;

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    map: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    selectedVehicleNumber: any;
    speedLimit: any = "40";
    dateWiseData: any = {};
    data: any;
    markersArray: any = [];
    polyLinesArray: any = [];
    googleBounds: any = new google.maps.LatLngBounds();
    userMobile: any;
    directionsDisplay: any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {
        this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true, polylineOptions: { strokeColor: "green" } });
        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.showMap();
        // this.getVehicleList();
    }

    showMap() {

        const location = { lat: 16.3067, lng: 80.4365 };
        var options = {
            center: location,
            zoom: 7
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // this.showPolygonsOnMap();
        // this.calcRoute();
        //krishna river sand reach mid point
        // this.applymarkers({ lat: 16.493096, lng: 80.626315 }, "Krishna River Sand Reach", google.maps.SymbolPath.CIRCLE, "red", { lat: 16.493096, lng: 80.626315 }, 0)
        // //krishna river dumps
        this.applymarkers({ lat: 16.493754, lng: 80.161505 }, "Patamata Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)
        this.applymarkers({ lat: 16.291381, lng: 80.440652 }, "Customer Home Location", 8, "green", { lat: 16.493096, lng: 80.626315 }, 0)
        this.applymarkers({ lat: 16.347395, lng: 80.300576 }, "Current Vehicle Location", 9, "green", { lat: 16.493096, lng: 80.626315 }, 0)
    }

    onSearchClick() {
        this.calcRoute({ lat: 16.493754, lng: 80.161505 }, { lat: 16.291381, lng: 80.432412 })
    }

    applymarkers(locObject, vehicleNumber, type, color, sandReachloc, sandReachType) {

        //google.maps.SymbolPath.FORWARD_CLOSED_ARROW

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);
        let vehicleMarker;

        if (type == 8) {

            vehicleMarker = new google.maps.Marker({
                position: new google.maps.LatLng(16.291381, 80.432412),
                animation: google.maps.Animation.DROP,
                map: this.map
            });

        } else if (type == 9) {

            vehicleMarker = new google.maps.Marker({
                position: new google.maps.LatLng(16.347395, 80.300576),
                animation: google.maps.Animation.DROP,
                map: this.map
            });
            vehicleMarker.setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png')
        }


        else {

            vehicleMarker = new google.maps.Marker({
                position: presentPos,
                icon: {
                    path: type,
                    strokeColor: color,
                    scale: 6
                },
                animation: google.maps.Animation.DROP,
                map: this.map
            });
        }

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {
            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                let contentString = `<table style="width:200px">
              <tr>
              <td> <b>  ` + vehicleNumber + ` </b></td>
              </tr>
              <tr><td> <b> Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);

            }).catch((google_error) => {
                // handle errors 

            });
        });
    }

    calcRoute(startLoc, endLoc) {

        let directionsService = new google.maps.DirectionsService();
        let start = new google.maps.LatLng(startLoc.lat, startLoc.lng);
        let end = new google.maps.LatLng(endLoc.lat, endLoc.lng);
        let request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, (response, status) => {
            // console.log("response == >" + JSON.stringify(response.routes[0].legs[0].distance.text));

            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }
}
