import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';
import { VehicleIcons } from '../base/VehicleIcons';
import { AnimateHelpers } from '../base/animatehelpers';

@Component({
  selector: 'app-singleVehicleHistory',
  templateUrl: './singleVehicleHistory.component.html',
  styleUrls: ['./singleVehicleHistory.component.scss']
})

export class SingleVehicleHistoryReport implements OnInit {

  @ViewChild("mapRef") mapRef: ElementRef;

  vehicleDetails: any = { 'result': [] };
  isTodayClicked: boolean = false;
  loading = false;
  selectedFromTime: any;
  selectedToTime: any;
  map: any;
  // Initialized to specific date (09.10.2018).
  public fromDate: any;
  public toDate: any;
  isSubmitClick: boolean = false;
  isPdfNotAvailable: boolean = true;
  userToken: any;
  userId: any;
  selectedVehicle: any;
  speedLimit: any = "40";
  dateWiseData: any = {};
  data: any;
  markersArray: any = [];
  polyLinesArray: any = [];
  gpsDevicesArray: any = [];
  googleBounds: any = new google.maps.LatLngBounds();
  userMobile: any;
  totalDistanceTravelled: any = 0;
  isPauseVisible: boolean = false;
  asignedGpsDevice: any = [];
  defalultVehicle:any;
  picker:any;

  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd-mm-yyyy',
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }

  };

  public myDatePickerOptions2: IMyDpOptions = {
    // other options...
    dateFormat:  'dd-mm-yyyy',
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  };

  constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

  }

  ngOnInit() {

    this.userToken = localStorage.getItem("userToken");
    this.userId = localStorage.getItem("userId");
    this.userMobile = localStorage.getItem("userMobile");
    this.showMap();
    this.getVehicleList();
  }
  setFromTime(fromTime){
     this.selectedFromTime = fromTime;
  }
  setToTime(toTime){
    this.selectedToTime = toTime;
 }
  getVehicleList() {
    let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
    let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
      this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
      this.selectedVehicle = this.gpsDevicesArray[0];
      this.gpsDevicesArray.forEach(element => {
        this.asignedGpsDevice.push(element.vehicleNumber);
      });
      this.defalultVehicle = this.asignedGpsDevice[0];
      // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
    })
  }


  onTodayClick() {
    this.isTodayClicked = !this.isTodayClicked;
  }

  onDateChanged(event: IMyDateModel) {


    if (event && event.jsdate) {
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.myDatePickerOptions2 = copy;
    }
  }

  getCopyOfEndDateOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
  }


  onClearClick() {
    this.isPdfNotAvailable = true;
    this.fromDate = null;
    this.toDate = null;
    this.isSubmitClick = false;
    this.vehicleDetails = null;
  }

  todaysDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getTableData() {

    if (!(this.fromDate && this.fromDate.formatted)) {
      alert('Please select From Date first');
      return;
    }

    if (!(this.toDate && this.toDate.formatted)) {
      alert('Please select To Date first');
      return;
    }

    if (!(this.selectedFromTime)) {
      alert('Please select From Time first');
      return;
    }

    if (!(this.selectedToTime)) {
      alert('Please select To Time first');
      return;
    }

    this.isSubmitClick = true;
    this.isPdfNotAvailable = true;

         var splippedFromDate = this.fromDate.formatted.split("-");
         var splippedToDate = this.toDate.formatted.split("-");
       
         let oriFromDate = new Date(splippedFromDate[2]+"-"+splippedFromDate[1]+"-"+splippedFromDate[0]);
         let oriToDate = new Date(splippedToDate[2]+"-"+splippedToDate[1]+"-"+splippedToDate[0]);

         let FromDateFormated = new Date(oriFromDate.getTime() - oriFromDate.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]
         let ToDateFormated = new Date(oriToDate.getTime() - oriToDate.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]
         
         
    let reqBody = {
      "userId": this.userMobile,
      "fromDate": FromDateFormated,
      "toDate": ToDateFormated,
      "fromTimeStamp": this.selectedFromTime,
      "toTimeStamp": this.selectedToTime,
      "imeiNumber": this.selectedVehicle.imeiNumber,
    }
    this.loading = true;
    // console.log("====" + JSON.stringify(reqBody));

   
    // this.userToken = userDataArray.userToken;
    let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    this.httpService.httpPostPromise(AppConstants.get_history_for_one_vehicle_bw_interval, reqBody, '', reqOptions).then((data) => {
      // console.log("===========" + JSON.stringify(data));
      delete data.statusMessage;
      this.data = data;
      this.isPdfNotAvailable = false;
      this.loading = false;
      // this.constructTableData();
      this.clearOverlays();
     
      this.totalDistanceTravelled = this.calculateTotalDistanceFromHaversineFormula(this.data.dataList[0].locationDetails);
     
      this.data.dataList.forEach(data => {
        data.locationDetails.forEach((locObj, index) => {
          this.googleBounds.extend(new google.maps.LatLng(locObj.lat, locObj.lng));
          if (index == 0 || index == data.locationDetails.length - 1) {
            this.applymarkers(locObj, data.vehicleNumber, data.totalDistance);
          }
        });
        let randomColor = "#000000".replace(/0/g, () => { return (~~(Math.random() * 16)).toString(16); });
        this.drawPolyline(data.locationDetails, randomColor)
      });
      this.map.fitBounds(this.googleBounds);

    }).catch((err) => {
      this.loading = false;
    })
  }

  showMap() {

    const location = { lat: 12.916476, lng: 77.602750 };
    var options = {
      center: location,
      zoom: 15
    }

    this.map = new google.maps.Map(this.mapRef.nativeElement, options);
  }

  applymarkers(locObject, vehicleNumber, totalDistance) {

    let infoWindow = new google.maps.InfoWindow({
      content: ''
    });
    let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);

    let vehicleMarker = new google.maps.Marker({
      position: presentPos,
      // animation: google.maps.Animation.DROP,
      map: this.map
    });

    google.maps.event.clearInstanceListeners(vehicleMarker);
    this.markersArray.push(vehicleMarker);
    vehicleMarker.addListener('click', () => {

      this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
        let geo_address = google_response.results[0].formatted_address;

        let address = geo_address;

        // console.log("====> kk" + address);
        // <tr>
        // <td colspan="2" id = "address">`+ totalDistance + `</td>
        // </tr>
        //<tr><td> <b> Total Distance : </b></td></tr>

        // (<HTMLInputElement>document.getElementById('address')).innerHTML = geo_address;
        let contentString = `<table style="width:200px">
              <tr>
              <td> <b> Vehicle : ` + vehicleNumber + ` </b></td>
              </tr>
              <tr>
              <td> <b> Time : ` + new Date(locObject.timestamp).toLocaleTimeString(); + ` </b></td>
              </tr>
              </table>`;
        infoWindow.setContent(contentString);
        infoWindow.open(this.map, vehicleMarker);
      }).catch((google_error) => {
        // handle errors

      });

      infoWindow.open(this.map, vehicleMarker);

    });
  }

  getAddressFromGoogle(lat, lng) {
    let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
    let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
    let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    return this.httpService.httpGetPromise(path, "", reqOptions);
  }

  clearOverlays() {
    for (var i = 0; i < this.markersArray.length; i++) {
      this.markersArray[i].setMap(null);
    }
    this.markersArray.length = 0;

    this.polyLinesArray.forEach(polyLine => {
      polyLine.setMap(null);
    });
  }


  drawPolyline(PolylineCoords, color) {
    // console.log("hiiiii 4444");

    let historyPolyLine = new google.maps.Polyline({
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 3,
      path: PolylineCoords,
      clickable: false
    });
    historyPolyLine.setMap(this.map);
    this.polyLinesArray.push(historyPolyLine)
  }

  onVehicleNumberChange(device) {
    this.showMap();
    this.selectedVehicle = device;
    this.getTableData();
    console.log(this.selectedVehicle);
  }

  calculateTotalDistanceFromHaversineFormula(locationDetails) {
    if (locationDetails.length >= 3) {
      // locationDetails.sort((a, b) => Number(new Date(a.timestamp).getTime()) - Number(new Date(b.timestamp).getTime()));
      let lengthOfLocationDetails = locationDetails.length;

      let totalDistance = 0;
      for (let i = 0; i < lengthOfLocationDetails - 2; i++) {
        let currentLatLng = locationDetails[i];
        let nextLatLng = locationDetails[i + 1];
        totalDistance += this.distanceFrom(currentLatLng, nextLatLng);
      }
       // let totalDistanceInKms = ((((+totalDistance / 1000) * 7) / 100) + (+totalDistance / 1000)).toFixed(2);
          let totalDistanceInKms = ((+totalDistance / 1000)).toFixed(2);
   
      return totalDistanceInKms;
    }
    else {
      return;
    }
  }
  distanceFrom(oldLatLng, newLatLng) {
    let EarthRadiusMeters = 6378137.0; // meters
    let lat1 = oldLatLng.lat;
    let lon1 = oldLatLng.lng;
    let lat2 = newLatLng.lat;
    let lon2 = newLatLng.lng;
    let dLat = (lat2 - lat1) * Math.PI / 180;
    let dLon = (lon2 - lon1) * Math.PI / 180;
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = EarthRadiusMeters * c;
    return d;

  }
  doSelectVehical(event) {
    const vehicalNo = this.gpsDevicesArray.filter(vehicle=>vehicle.vehicleNumber === event);
    this.selectedVehicle = vehicalNo[0];
    console.log('doSelectVehical', event);
    console.log('selectedVehicle', this.selectedVehicle);
    this.showMap();
    this.getTableData();
  }
  doNgxDefault(){
    return this.defalultVehicle;
  }

}
