import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-allVehicleHistory',
    templateUrl: './allVehicleHistory.component.html',
    styleUrls: ['./allVehicleHistory.component.scss']
})

export class AllVehicleHistoryReport implements OnInit {

    @ViewChild("mapRef") mapRef: ElementRef;

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    selectedFromTime: any;
    selectedToTime: any;
    map: any;
    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    selectedVehicleNumber: any;
    speedLimit: any = "40";
    dateWiseData: any = {};
    data: any;
    markersArray: any = [];
    polyLinesArray: any = [];
    googleBounds: any = new google.maps.LatLngBounds();
    userMobile: any;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobile = localStorage.getItem("userMobile");
        this.showMap();
    }

    onTodayClick() {
        this.isTodayClicked = !this.isTodayClicked;
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            Swal.fire({
              type: 'info',
              title: 'Oops...',
              text: 'Please select From Date first!'
            })
            // alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
              Swal.fire({
              type: 'info',
              title: 'Oops...',
              text: 'Please select To Date first!'
            })
            // alert('Please select To Date first');
            return;
        }

        if (!(this.selectedFromTime)) {
             Swal.fire({
              type: 'info',
              title: 'Oops...',
              text: 'Please select From Time first!'
            })
            // alert('Please select From Time first');
            return;
        }

        if (!(this.selectedToTime)) {
              Swal.fire({
              type: 'info',
              title: 'Oops...',
              text: 'Please select To Time first!'
            })
            // alert('Please select To Time first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;

        let reqBody = {
            "userId": this.userMobile,
            "fromDate": this.fromDate.formatted,
            "toDate": this.toDate.formatted,
            "fromTimeStamp": this.selectedFromTime,
            "toTimeStamp": this.selectedToTime
        }
        this.loading = true;
        //  console.log("====" + JSON.stringify(reqBody));
        //  console.log("====" + JSON.stringify(this.userToken));

        //this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option 
        this.httpService.httpPostPromise(AppConstants.get_history_for_all_vehicles_bw_interval, reqBody, '', reqOptions).then((data) => {
            // console.log("===========" + JSON.stringify(data));
            delete data.statusMessage;
            this.data = data;
            this.isPdfNotAvailable = false;
            this.loading = false;
            // this.constructTableData();
            this.clearOverlays();
            this.data.dataList.forEach(data => {
                data.locationDetails.forEach((locObj, index) => {
                    this.googleBounds.extend(new google.maps.LatLng(locObj.lat, locObj.lng));
                    if (index == 0 || index == data.locationDetails.length - 1) {
                        this.applymarkers(locObj, data.vehicleNumber);
                    }
                });
                let randomColor = "#000000".replace(/0/g, () => { return (~~(Math.random() * 16)).toString(16); });
                this.drawPolyline(data.locationDetails, randomColor)
            });
            this.map.fitBounds(this.googleBounds);

        }).catch((err) => {
                 let timerInterval
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong please try again later!',
                  timer: 2000,
                  onBeforeOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                    }, 100)
                  },
                  onClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.timer
                  ) {
                    console.log('I was closed by the timer')
                  }
                })
                console.log(err);
                this.loading = false;

        })
    }

    showMap() {

        const location = { lat: 12.916476, lng: 77.602750 };
        var options = {
            center: location,
            zoom: 15
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
    }

    applymarkers(locObject, vehicleNumber) {

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);

        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            // animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {

            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                // console.log("====> kk" + address);


                // (<HTMLInputElement>document.getElementById('address')).innerHTML = geo_address;
                let contentString = `<table style="width:200px">
                <tr><td> <b> Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              <tr>
              <td> <b> Vehicle : ` + vehicleNumber + ` </b></td>
              </tr>
              <tr>
              <td> <b> Time : ` + new Date(locObject.timestamp).toLocaleTimeString(); + ` </b></td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);
            }).catch((google_error) => {
                // handle errors 

            });

            infoWindow.open(this.map, vehicleMarker);

        });
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

    clearOverlays() {
        for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
        }
        this.markersArray.length = 0;

        this.polyLinesArray.forEach(polyLine => {
            polyLine.setMap(null);
        });
    }


    drawPolyline(PolylineCoords, color) {
        // console.log("hiiiii 4444");

        let historyPolyLine = new google.maps.Polyline({
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 3,
            path: PolylineCoords,
            clickable: false
        });
        historyPolyLine.setMap(this.map);
        this.polyLinesArray.push(historyPolyLine)
    }

}
