import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var google: any;
declare var MarkerClusterer: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-vehicleAndClusterPlanner',
    templateUrl: './vehicleAndClusterPlanner.component.html',
    styleUrls: ['./vehicleAndClusterPlanner.component.scss']
})

export class VehicleAndClusterPlanner implements OnInit {

    @ViewChild("mapRef") mapRef: ElementRef;
    @ViewChild("search") public searchElementRef: ElementRef;

    isTodayClicked: boolean = false;
    loading = false;
    map: any;
    isBirdViewClicked: boolean = true;

    userToken: any;
    userId: any;
    data: any;
    markersArray: any = [];
    googleBounds: any;

    selectedRouteName: any;
    selectedLocationList: any[] = [];
    allLocationslist: any[] = [];
    fetchedAddress: any;
    fetchedAddressToSend: any;
    fetchedAddressMarker: any;
    fetchedLatLng: any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter, private ngZone: NgZone, ) {

    }

    ngOnInit() {
        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getInitializationData();
        this.initAutoComplete();
    }


    initAutoComplete() {

        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ["address"], componentRestrictions: { country: 'in' }
        });
        autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {

                //get the place result
                let place = autocomplete.getPlace();
                //verify result
                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }
                this.fetchedAddressToSend = place.formatted_address;
                this.fetchedLatLng = place.geometry.location;
                this.map.panTo(this.fetchedLatLng);

                if (this.fetchedAddressMarker) {
                    this.fetchedAddressMarker.setMap(null);
                }
                this.fetchedAddressMarker = new google.maps.Marker({
                    position: this.fetchedLatLng,
                    icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + "N" + '|0000FF|FFFFFF',
                    visible: true,
                    map: this.map
                });

            });
        });
    }

    onSearchClick() {

        if (!(this.selectedRouteName)) {
            alert('Please Enter a Route Number !! Example -  1 or 2 or 3');
            return;
        }

        this.allLocationslist.forEach(element => {
            if (element.routeNo == this.selectedRouteName) {
                this.selectedLocationList = element.listOfDropLoc;
            }
        });
    }

    getInitializationData() {
        this.loading = true;

        // this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option 
        this.httpService.httpGetPromise(AppConstants.get_optimal_route_details_from_db, '', reqOptions).then((data) => {
            delete data.statusMessage;
            this.data = data;
            // console.log("data =>>>> " + JSON.stringify(data.optimalRouteDetailsData[0].routeDetails));
            this.allLocationslist = data.optimalRouteDetailsData[0].routeDetails;
            this.showMap();
            this.loading = false;
        }).catch((err) => {
            this.loading = false;
        })
    }


    showMap() {
        // navigator.geolocation.getCurrentPosition((position) => {
        //     console.log("Got position", position.coords);
        //     var options = {
        //         center: { lat: position.coords.latitude, lng: position.coords.longitude },
        //         zoom: 15
        //     }
        //     this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // });

        this.clearOverlays();

        const location = { lat: 12.916476, lng: 77.602750 };
        var options = {
            center: location,
            zoom: 15
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);

        this.allLocationslist.forEach(route => {

            let latLng: any;
            let marker: any;
            latLng = new google.maps.LatLng(route.listOfDropLoc[0].location.lat,
                route.listOfDropLoc[0].location.lng);

            this.googleBounds.extend(latLng);

            marker = new google.maps.Marker({
                position: latLng,
                icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + route.routeNo + '|000000|FFFFFF',
                routeNo: route.routeNo,
                index: 0,
                map: this.map
            });

            let infoWindow = new google.maps.InfoWindow({
                content: ''
            });

            marker.addListener('click', () => {


                let contentString = `<table style="width:200px">
                    <tr><td> <b> Route No. : </b>`+ route.listOfDropLoc[0].routeNo + `</td></tr>
                    <tr><td> <b> Address : </b></td></tr>
                  <tr>
                  <td colspan="2" id = "address">`+ route.listOfDropLoc[0].address + `</td>
                  </tr>
                  </table>`;
                infoWindow.setContent(contentString);

                infoWindow.open(this.map, marker);

            });


            route.listOfDropLoc.forEach((element, index) => {
                if (index > 0) {
                    let innerLatLng = new google.maps.LatLng(element.location.lat, element.location.lng);
                    let innerMarker = new google.maps.Marker({
                        position: innerLatLng,
                        icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + route.routeNo + '|FF0000|000000',
                        visible: false,
                        routeNo: route.routeNo,
                        index: index,
                        map: this.map
                    });
                    this.markersArray.push(innerMarker);

                    let innerInfoWindow = new google.maps.InfoWindow({
                        content: ''
                    });

                    innerMarker.addListener('click', () => {


                        let contentString = `<table style="width:200px">
                            <tr><td> <b> Route No. : </b>`+ element.routeNo + `</td></tr>
                            <tr><td> <b> Address : </b></td></tr>
                          <tr>
                          <td colspan="2" id = "address">`+ element.address + `</td>
                          </tr>
                          </table>`;
                        innerInfoWindow.setContent(contentString);

                        innerInfoWindow.open(this.map, innerMarker);

                    });
                }
            });

            google.maps.event.addListener(marker, 'click', (data) => {

                this.markersArray.forEach(xMarker => {

                    if (xMarker.get("routeNo") == marker.get("routeNo")) {
                        if (xMarker.getVisible()) {
                            xMarker.setVisible(false);
                        } else {
                            xMarker.setVisible(true);
                        }
                    }

                    if (xMarker.get("index") == 0) {
                        xMarker.setVisible(true);
                    }
                });
            });

            this.markersArray.push(marker);
        });

        this.map.fitBounds(this.googleBounds);

        // >>>>>>>>>>>>>>>>>>>>cluster start

        /*  var mcOptions = {
             imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
             styles: [{
 
                 url: "https://googlemaps.github.io/js-marker-clusterer/images/m1.png",
                 width: 53,
                 height: 53,
                 fontFamily: "comic sans ms",
                 textSize: 15,
                 textColor: "white",
                 //color: #00FF00,
             }],
             maxZoom: 12
 
         };
         let markerCluster = new MarkerClusterer(this.map, markers, mcOptions); */

        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>> cluster end

        // Construct the polygon.
        /*  var bermudaTriangle = new google.maps.Polygon({
             paths: data,
             strokeColor: '#FF0000',
             strokeOpacity: 0.8,
             strokeWeight: 2,
             fillColor: '#FF0000',
             fillOpacity: 0.35
         });
         bermudaTriangle.setMap(this.map); */

        // for (var i=0; i < data.length; i++) {
        //     var wellCircle = new google.maps.Circle({
        //         strokeColor: '#FF0000',
        //         strokeOpacity: 0.8,
        //         strokeWeight: 2,
        //         fillColor: '#FF0000',
        //         fillOpacity: 0.35,
        //         map: this.map,
        //         center: new google.maps.LatLng(data[i].lat, data[i].lng),
        //         radius: 10000
        //     });
        // };
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

    clearOverlays() {
        this.googleBounds = new google.maps.LatLngBounds();
        if (this.markersArray && this.markersArray.length > 0) {
            for (var i = 0; i < this.markersArray.length; i++) {
                this.markersArray[i].setMap(null);
            }
            this.markersArray.length = 0;

        }
    }

    onBirdViewClick() {
        this.showMap();
    }

    onDetailedViewClick() {

        this.clearOverlays();
        this.allLocationslist.forEach(route => {
            route.listOfDropLoc.forEach((element, index) => {
                let innerLatLng = new google.maps.LatLng(element.location.lat, element.location.lng);
                this.googleBounds.extend(innerLatLng);
                let innerMarker = new google.maps.Marker({
                    position: innerLatLng,
                    icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + route.routeNo + '|FF0000|000000',
                    visible: true,
                    routeNo: route.routeNo,
                    index: index,
                    map: this.map
                });
                this.markersArray.push(innerMarker);

                let innerInfoWindow = new google.maps.InfoWindow({
                    content: ''
                });

                innerMarker.addListener('click', () => {


                    let contentString = `<table style="width:200px">
                        <tr><td> <b> Route No. : </b>`+ element.routeNo + `</td></tr>
                        <tr><td> <b> Address : </b></td></tr>
                      <tr>
                      <td colspan="2" id = "address">`+ element.address + `</td>
                      </tr>
                      </table>`;
                    innerInfoWindow.setContent(contentString);

                    innerInfoWindow.open(this.map, innerMarker);

                });

            });
        });
        this.map.fitBounds(this.googleBounds);
    }

    onAddClick() {

        if (!(this.selectedRouteName)) {
            alert('Please Search For a Route');
            return;
        }

        if (!(this.fetchedAddress)) {
            alert('Please Select a location first');
            return;
        }

        let newDropLoc = {

            "routeNo": this.selectedRouteName,
            "CPName": "",
            "SCode": "",
            "address": this.fetchedAddressToSend,
            "days": {
                "Monday": 0,
                "Tuesday": 0,
                "Wednesday": 0,
                "Thursday": 0,
                "Friday": 0,
                "Saturday": 0
            },
            "location": {
                "lat": this.fetchedLatLng.lat(),
                "lng": this.fetchedLatLng.lng()
            },
            "driverMobNo": "",
            "driverName": ""
        }

        // this.allLocationslist.forEach(element => {
        //     if (element.routeNo == this.selectedRouteName) {
        //         element.listOfDropLoc.push(newDropLoc);
        //     }
        // });

        this.loading = true;

        // this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option 
        let reqBody = {
            "routeDetails": this.allLocationslist
        }

        // console.log("reqBody =====>" + JSON.stringify(reqBody));

        this.httpService.httpPostPromise(AppConstants.update_optimal_route_details, newDropLoc, '', reqOptions).then((data) => {
            this.loading = false;
            if (this.fetchedAddressMarker) {
                this.fetchedAddressMarker.setMap(null);
            }
            this.getInitializationData();
            this.selectedLocationList = [];
        }).catch((err) => {

        })
    }
}
