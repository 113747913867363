import { HttpService } from "./../base/http.service";
import { Component, ElementRef, OnInit, ViewChild,Input } from '@angular/core';
import { AppConstants } from '../base/appconstants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as XLSX from 'xlsx';
declare var google: any;
declare var jQuery: any;
type AOA = any[][];



import { Headers, RequestOptions } from '@angular/http';


@Component({
    selector: 'app-medicalTripTracker',
    templateUrl: './medicalTripTracker.component.html',
    styleUrls: ['./medicalTripTracker.component.scss'],
})


export class MedicalTripTracker implements OnInit {

    @ViewChild("stopList") stopListUI: ElementRef;

    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    asignedGpsDevice: any = [];
    loading: boolean;
    defalultVehicle: any;
    trip: any;
    driverName : any;
    userMobileNumber : any;
    customerBaseData : any;
    allLatesttripsOfImei : any;
    selectedTrip : any;
    selectedImeiForTripDetails : any;
    defaultTrip : any;
    defaultStopID : any;
    selectedTripForTracking : any;
    selectedStopTracking : any;
    selectedStopID : any;
    selectedStopIDEle : any;
    selectedVehicleNo : any;
    showMsg: boolean = false;
    selectedDriverName : any;
    selectedVehicleNumber : any;
    selectedMultipleStops: any;
    repeatTripYesNo: any;
    defaultTepeatTrip: any;

    @Input() editedDriverName : any; 
    @Input()  tripNameIdentifire : any; 


    myForm:FormGroup;
        disabled = false;
        ShowFilter = true;
        limitSelection = false;
        stops: any = [];
        stopsList: any = [];
        repeatList:any= []
        allTripsOfIMEI : any= [];
        selectedItems: any = [];

        dropdownSettings: any = {};
        excellStopData: any = [];
        stopsFromExcel: any = []; 


    constructor(private httpService: HttpService, private fb: FormBuilder) {
       
        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobileNumber = localStorage.getItem('userMobile')
        this.getVehicleList();
        this.repeatList[0] = "YES"
        this.repeatList[1] = "NO";
    }

    

    data: AOA = [[1, 2], [3, 4]];
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    fileName: string = 'SheetJS.xlsx';
  
    onFileChange(evt: any) {
      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>(evt.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
  
        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  
        /* save data */
        this.excellStopData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
        this.excellStopData.shift();
        console.log(this.excellStopData);

        this.excellStopData.forEach(stopLocation => {
                this.stopsFromExcel.push({"stopID": stopLocation ,"stopName" : stopLocation });

            });
          


      };
      reader.readAsBinaryString(target.files[0]);
    }
  
  
    export(): void {
      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            this.gpsDevicesArray.forEach(element => {
                this.asignedGpsDevice.push(element.vehicleNumber);
            });
           // this.defalultVehicle = this.asignedGpsDevice[0];
            // this.getVehicleTripDetais();
        })
    }

    getVehicleTripDetais(imeiNumber,mobileNumber) {
       
        let path = AppConstants.get_Medical_Vehicle_Trip_Tracking;
        let reqBody = {};
        if(imeiNumber!= undefined && imeiNumber != ""){
             reqBody = {
                  "imeiNumber": imeiNumber,
                  "mobileNumber" : mobileNumber
                    }
                   
        }else{
             reqBody = {
                   "imeiNumber": this.selectedTripForTracking.imeiNumber,
                   "mobileNumber" : this.selectedTripForTracking.mobileNumber
             };
        this.customerBaseData.stopLocationsList.forEach(element => {
            this.stopsList.push(element.stopID);
             });  
          }
       
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(path, reqBody, '', reqOptions).then((data) => {
          
            this.loading = false;
            this.trip = data.dataList;
            if(imeiNumber!= undefined && imeiNumber != ""){
           
            }else{
                this.selectedStopIDEle = "";
            }
             console.log(this.trip); 
             
            this.scrollToCurrentTrip();
        }).catch((err) => {
            this.loading = false;
        })
    }

    doSelectVehical(event) {
        const vehicalNo = this.gpsDevicesArray.filter(vehicle => vehicle.vehicleNumber === event);
        this.selectedVehicle = vehicalNo[0];
        this.editedDriverName = this.selectedVehicle.vehicleNumber;
       // console.log('doSelectVehical', event);
       // console.log('selectedVehicle', this.selectedVehicle);
       // this.getVehicleTripDetais();
    }

    tripRepeatOrNo(event) {
        this.repeatTripYesNo = event;
        console.log(this.repeatTripYesNo);
     } 

    doSelectTrip(event) {
        console.log("doDelectTrip-->",this.allLatesttripsOfImei);
        const tripNo = this.allLatesttripsOfImei.filter(tripDetails => tripDetails.driverName === event);
        this.selectedTripForTracking = tripNo[0];
        this.selectedImeiForTripDetails = this.selectedTripForTracking.imeiNumber;
        
        this.getVehicleTripDetais("","");
       //  console.log('selectedVehicle', this.selectedVehicle);
       // this.getVehicleTripDetais(this.selectedImeiForTripDetails);
    }
    doSelectStop(event){
      
        const stopDetails = this.customerBaseData.stopLocationsList.filter(stopDetails => stopDetails.stopID === event);
        this.selectedStopTracking = stopDetails[0];
        this.selectedStopID = this.selectedStopTracking.stopID;

        let getLatestTripOfSpecificStop  = AppConstants.get_Latest_Trip_Of_Specific_Stop;
        let reqBody = {
          
            "stopId": this.selectedStopID,
            "mobileNumber" : this.userMobileNumber
        }

        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(getLatestTripOfSpecificStop, reqBody, '', reqOptions).then((data) => {
           
            this.loading = false;
            this.defaultTrip = data.driverName;
            let tempallLatesttripsOfImei = this.allLatesttripsOfImei;
            this.allLatesttripsOfImei = [];
            this.allLatesttripsOfImei = tempallLatesttripsOfImei;
            this.defaultTrip = data.driverName;
           
           // this.selectedStopIDEle = "";
            
            
            
            //this.selectedTripForTracking.imeiNumber = data.imeiNumber;
            //this.selectedTripForTracking.mobileNumber = data.mobileNumber;
           console.log(data.imeiNumber);
           console.log(data.mobileNumber);
           this.getVehicleTripDetais(data.imeiNumber,data.mobileNumber);

            this.scrollToCurrentTrip();
        }).catch((err) => {
            this.loading = false;
        })
       
        //this.getVehicleTripDetais();

    }


    doNgxDefaultVehicle() {
        return this.defalultVehicle;
    }

    doNgxDefaultTrip() {
        return this.defaultTrip;
    }

    doNgxDefaultStop() {
        return this.defaultStopID;
    }
    doNgxDefaultRepeatTrip() {
         return this.defaultTepeatTrip;
    }

    

    scrollToCurrentTrip() {
        const tripsToReach = this.stopListUI.nativeElement && this.stopListUI.nativeElement.querySelectorAll('li.bg-danger');
        if (tripsToReach && tripsToReach[0]) {
            setTimeout(() => {
                tripsToReach[0].scrollIntoView({ block: "start" });
            }, 500)
        }
    }

    ngOnInit() {

       

        let pathMediBaseData = `${AppConstants.get_Medi_Customer_BaseData}${this.userMobileNumber}`;
      
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(pathMediBaseData, "", reqOptions).then((customerBaseDataResp) => {
            this.customerBaseData = customerBaseDataResp;
            this.stops = this.customerBaseData.stopLocationsList;

            this.customerBaseData.stopLocationsList.forEach(element => {
                this.stopsList.push(element.stopID);
            });  
             // this.defaultStopID = this.stopsList[0];
           // console.log(this.stopsList);
       
        })

        let latestTripForIMEI = `${AppConstants.get_All_Latest_Trips_Of_every_IMEI}${this.userMobileNumber}`;
        this.httpService.httpGetPromise(latestTripForIMEI, "", reqOptions).then((allLatestTrips) => {
       
        this.allLatesttripsOfImei = allLatestTrips;
        console.log("Just Fetched --> ",this.allLatesttripsOfImei);

        allLatestTrips.forEach(element => {
            this.allLatesttripsOfImei.push(element.driverName);
        });
        

       // this.defaultTrip = this.allLatesttripsOfImei[0];
        
    });

        this.selectedItems = [];
        this.dropdownSettings = {
        singleSelection: false,
        idField: 'stopID',
        textField: 'stopName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 4,
        allowSearchFilter: this.ShowFilter,

    };
    this.myForm = this.fb.group({
        stop: [this.selectedItems]
    });  
        

    }

    onItemSelect(item: any) {
        console.log('onItemSelect', item);
    }
    onSelectAll(items: any) {
        console.log('onSelectAll', items);
    }
    toogleShowFilter() {
        this.ShowFilter = !this.ShowFilter;
        this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
    }

    handleLimitSelection() {
        if (this.limitSelection) {
            this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
        } else {
            this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
        }
    }

    successAlertNotification(){
        Swal.fire('Hi', 'Trip Creation successfull', 'success')

       

      }
    failureAlertNotification(){
        Swal.fire('Hi', 'Something went wrong', 'success')
      }

    onTripCreationSubmit(){
        this.loading = true;
        let createdTripDetails : any = {};
        
      
       
       if(this.stopsFromExcel.length>0){
        createdTripDetails.vehicleStopsList = this.stopsFromExcel;
       }else{
        createdTripDetails.vehicleStopsList = this.myForm.controls['stop'].value; 
       }
        
        
        createdTripDetails.driverName =this.editedDriverName ;
        createdTripDetails.customerName = this.customerBaseData.customerName;
        createdTripDetails.sourceLocationsList = this.customerBaseData.sourceLocationsList;
        createdTripDetails.mobileNumber = this.userMobileNumber;
        createdTripDetails.vehicleNo = this.selectedVehicle.vehicleNumber
        createdTripDetails.imeiNumber = this.selectedVehicle.imeiNumber;
        createdTripDetails.repeatTrip = this.repeatTripYesNo;
       
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
      
        let drivernameSplit = createdTripDetails.driverName.split("-");
        if(drivernameSplit.length != 3){
                 alert(" Driver Name Not in Correct Format eg: Name-Code-VehicleNo");
                 this.loading = false;
        }else if(createdTripDetails.vehicleStopsList.length == 0){
                 alert(" One or More Chemist Code is Mandatory "); 
                 this.loading = false;
        }
        else{
            this.loading = true; 
        this.httpService.httpPostPromise(AppConstants.save_Medical_Vehicle_Trip_Info, createdTripDetails, "", reqOptions).then((tripCreateResponse) => {
             console.log("tripCreateResponse ..............", JSON.stringify(tripCreateResponse));
             this.loading = false; 
             
             this.editedDriverName = "";
             this.defalultVehicle = "";
             this.selectedVehicleNo = "";
             this.repeatTripYesNo = "";
             this.selectedItems = [];
           
            
          
             this.successAlertNotification()
        }).catch((dashboardErr) => {
            this.loading = false;
            // console.log("dashboardErr...", JSON.stringify(dashboardErr));
        });
    }



    }


}
