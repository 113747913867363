import { Component, OnInit } from '@angular/core';
import { Headers, RequestOptions } from '@angular/http';
import { AppConstants } from 'app/base/appconstants';
import { HttpService } from 'app/base/http.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-aisdevicedashboard',
  templateUrl: './aisdevicedashboard.component.html',
  styleUrls: ['./aisdevicedashboard.component.scss']
})
export class AisDeviceDashboardComponent implements OnInit {

  userToken: any;
  loader: boolean = false;
  allRequestData: any = [];
  cols1 = [];

  constructor(private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  dummyData = [
    {
      'date': '01/02/2019',
      'vehicleNumber': 'AP04AQ4647',
      'entryTime': '5:30:00',
      'comment': 'abcdef'

    },
    {
      'date': '01/01/2019',
      'truckNo': 'AP04AQ4659',
      'entryTime': '9:30:00',
      'comment': 'ghijklmno'

    }
  ]

  ngOnInit() {

    this.cols1 = [
      { field: 'mobileNumber', header: 'Mobile Number' },
      { field: 'noOfPieces', header: 'No Of Pieces' },
      { field: 'amountDeposited', header: 'Amount Deposit' },
      { field: 'urgencyLevel', header: 'Urgency Level' }
    ];

    this.userToken = localStorage.getItem("userToken");
    this.onSubmit();
  }

  onRowSelect(event): void {
    // console.log('onrowSelect', event);
    this.router.navigate(['/deviceAssign']);
    // console.log('this.router.navigate([ / deviceAssign]);')
  }

  onSubmit() {
    this.loader = true;
    let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken });
    let reqOptions = new RequestOptions({ headers: headers });
    this.httpService.httpGetPromise(AppConstants.get_all_supply_device_request, "", reqOptions).then((DevicesAssignResponse) => {

      // console.log('--------------oooooooooo0000000>>>>', gpsDevicesResponse);
      this.allRequestData = DevicesAssignResponse.supplyDeviceRequestData;
      // console.log(this.allRequestData)
    })
  }
}
