import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
import { SelectItem } from 'primeng/primeng';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-lorryReceiptHistory',
    templateUrl: './lorryReceiptHistory.component.html',
    styleUrls: ['./lorryReceiptHistory.component.scss']
})

export class LorryReceiptHistory implements OnInit {

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    dashboardData: any;
    tableValue:any = [];
    status : SelectItem[];
    selectedStatus:any;
    lrRecordUpdateStatus:any;
    lrNo:any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter
       ) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getVehicleList();
         this.status = [
            { label: 'Select', value: 'Select' },
            { label: 'Progess', value: 'Progess' },
            { label: 'Reached', value: 'Reached'},
            { label: 'Return', value: 'Return'},
            { label: 'End', value: 'End' }
        ];
        this.getAllLRRecords();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
        })
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {
        let body = [];
        body.push(this.dashboardData);
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        let dashBoardParams = {
            imeiNumber: this.selectedVehicle.imeiNumber,
            toDate: this.toDate.formatted,
            fromDate: this.fromDate.formatted
        }

        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.get_dashboard_data_for_report, dashBoardParams, "", reqOptions).then((dashboardData) => {
            // console.log("dashboarddata..............", JSON.stringify(dashboardData));
            this.isPdfNotAvailable = false;
            this.loading = false;
            if (dashboardData && dashboardData.statusCode === 200) {
                this.dashboardData = dashboardData;
                this.dashboardData.recordList.forEach(element => {
                    let dateTimeStamp = new Date(element.historyDate).getTime();
                    element.historyTimeStamp = dateTimeStamp;
                });
                this.dashboardData.recordList.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
            }
        }).catch((dashboardErr) => {
            this.loading = false;
            // console.log("dashboardErr...", JSON.stringify(dashboardErr));
        });
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/dailysummaryreport', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange(device) {
        this.selectedVehicle = device;
        this.getTableData();
    }

    getAllLRRecords(){
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_all_lr_records, '', reqOptions).then(getAlllrRecords =>{
            this.loading = false;
             const allLRRecordsList = getAlllrRecords.lRRecordsData;
            // console.log("getAllLRRecords",JSON.stringify(getAlllrRecords));
            allLRRecordsList.forEach(recodrd=>{
               
                 const sourceNames = recodrd.source;
                const allname = []
                recodrd.destination.forEach(Element=>{
                allname.push(Element.placeName)
                })

                recodrd.destinationName = allname.join();
                const vehicalNos = recodrd.truckNo;
               
                recodrd.sourceName = sourceNames.placeName;
                recodrd.vehicalNo = vehicalNos.vehicleNumber;

                if(recodrd.currentStatus===0){
                    recodrd.statusInfo = 'Progress'
                }else if(recodrd.currentStatus===1){
                    recodrd.statusInfo = 'Reached'
                }else if(recodrd.currentStatus===2){
                    recodrd.statusInfo = 'Return'
                }else if(recodrd.currentStatus===3){

                    //recodrd._id !== recodrd._id;
                     recodrd.statusInfo = 'Ended'
                }
                
            });
            this.tableValue = allLRRecordsList.filter(function(el) { return el.statusInfo === "Ended"; });
        }) .catch((getAlllrRecordsError) => {
            this.loading = false;
            console.log("getAlllrRecordsError...", getAlllrRecordsError);
        });
    }

    callLRCreation(changeStatus){
        if (confirm(`Are you sure you do you want chage the status of LR No? ${this.lrNo} `)) {
            this.onLrReportStatusChange(changeStatus);

        } else {
           console.log("NO Changes", changeStatus)
        }
    }

     onLrReportStatusChange(e){

        console.log(e);
        if(e === 'Progess'){
            console.log('I am Progress');
            //  this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 0
            // })
            console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]

            this.updateCurrentStatusLRRecords(updatedStateObj._id, 0);

        }else  if(e === 'Reached'){
            console.log('I am Reached');
            // this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 1
            // })
            console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]

            this.updateCurrentStatusLRRecords(updatedStateObj._id, 1);
        }else  if(e === 'Return'){
            console.log('I am Return');
            //  this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 2
            // })
            console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]
            this.updateCurrentStatusLRRecords(updatedStateObj._id, 2);
        }
        else  if(e === 'End'){
            console.log('I am end')
            //  this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 3
            // })
            console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]
            this.updateCurrentStatusLRRecords(updatedStateObj._id, 3);
        }

    }    
    valueChanged(data){
        console.log(data);
        this.lrNo=data.lrNo;
        const dataObj = []
        dataObj.push(data);
        this.lrRecordUpdateStatus = this.removeDuplicates(dataObj);
    }

    removeDuplicates(array) {
    let uniq = [];
    return array.filter(obj => !uniq[obj._id] && (uniq[obj._id] = true))
  }
  updateCurrentStatusLRRecords(reqBody, id){
      this.loading = true;
     let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
      let reqOptions = new RequestOptions({ headers: headers }); // Create a request option

      this.httpService.httpGetPromise(AppConstants.update_current_status_lr_records + reqBody +'/'+ id, "", reqOptions)
        .then((updateCurrentStatus) => {
            this.loading = false;
            console.log(updateCurrentStatus);
            this.getAllLRRecords();

        }).catch(err=>{
            this.loading = false;
            console.log("Update Current Status of LR Recored  Error");
        });
}  
 printDataBasedOnDate():void{
      let printContents, popupWin;
      popupWin = window.open('', '_blank');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
           <style>
                table.borderStyle{
                border: 1px solid black;
            }
            table.borderStyle thead tr th{
              border-bottom: 1px solid black;
                 font-size: 12px;
            }
            table.borderStyle thead tr th{
              border-right: 1px solid black;
            }

            table.borderStyle tbody tr td{
              border-right: 1px solid black;
            }

             table.borderStyle tbody tr td{
                 font-size: 11px;
                 padding:4px;
            }
            table.borderStyle tbody tr td{
              border-bottom: 1px solid black;
            }
            table.borderStyle thead tr th{
              border-right: 1px solid black;
            }
            table {
              counter-reset: section;
            }

            .count:before {
              counter-increment: section;
              content: counter(section);
            }

             body{
             width:100%;
           }
           tr td h3{
               text-align:center;
               padding-bottom:20px;
           }
            @media print {
                body, page[size="A4"] {
                margin: 0;
                box-shadow: 0;
            }
            @media print{@page {size: landscape}}
            @page {
                size: A4 landscape;
            }

           </style>

          </head>

        <body onload="window.print()">
             <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%" style="border: 0;">
                <tr>
                    <td style="border-right:0px;border-left:0px">
                        <span style="font-weight:bold">TLOA(</span>
                        <span>Transin logistics pvt ltd<span>
                        <span  style="font-weight:bold">)Daily Report</span>
                    </td>
                </tr>
            </table>
            <table width="100%" id="print-section" cellspacing="0" cellpadding="0" class="borderStyle" style="border-bottom:0px;">
                <thead class="borderStyle">
                    <tr>

                        <th>
                            S.No
                        </th>
                        <th>
                            Date
                        </th>
                        <th width="5%">
                            LRN No
                        </th>
                        <th width="5%">
                            Vehical No
                        </th>
                        <th width="15%">
                           Destination
                        </th>
                        <th width="5%">
                           Lr Qty
                        </th>
                        <th width="5%">
                           Rate
                        </th>
                        <th width="5%">
                           Total Amount
                        </th>
                        <th width="5%">
                           Advance
                        </th>
                        <th width="15%">
                           Transporter / Owner Name
                        </th>
                        <th width="10%">
                           Account No
                        </th>
                        <th width="10%">
                           Bank Name / Branch
                        </th>
                        <th width="5%">
                           IFSC Code
                        </th>
                        <th width="10%">
                           Pan No
                        </th>
                    <tr>
                </thead>
                <tbody>
                   ${this.tableValue.map(vehical =>` 
                      <tr>
                          <td class="count"></td>
                         <td>
                             ${new Date(vehical.creationDateAndTime).toLocaleDateString()}
                         </td>
                        <td width="5%">
                           ${vehical.lrNo}
                        </td>
                        <td width="5%">
                            ${vehical.vehicalNo}
                        </td>
                        <td width="15%">
                            ${vehical.destinationName}
                        </td>
                        <td>
                            ${vehical.quantity}
                        </td>
                        <td width="5%">
                          ${vehical.rate}
                        </td>
                        <td width="5%">
                            ${vehical.amount}
                        </td>
                        <td width="5%">
                            ${vehical.advance}
                        </td>
                        <td width="15%">
                            ${vehical.consignor}
                        </td>
                        <td width="10%">
                            ${vehical.accountNumber}
                        </td>
                        <td width="10%">
                            ${vehical.bankName}
                        </td>
                        <td width="5%">
                            ${vehical.ifsCode}
                        </td>
                        <td width="10%">
                            ${vehical.pan}
                        </td>
                      </tr>
                      `).join('')}
                </tbody>
            </table>
            <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%"  style="border-top:0px;" >
                <tr>
                  <td  style="padding-top:;text-align:center;" width="100%" >
                      ${new Date().toString()}
                  </td>
              </tr>
            </table>
        </html>`
      );
      popupWin.document.close();

    }
}
