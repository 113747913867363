import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
import { SelectItem } from 'primeng/primeng';
import * as $AB from 'jquery';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import * as jquery from 'jquery';
import { Observable } from 'rxjs';
declare var google: any;
declare var jQuery: any;
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

declare var jquery: any;   // not required
declare var $: any;   // not required

import { Headers, RequestOptions } from '@angular/http';


@Component({
    selector: 'app-plantEntry',
    templateUrl: './plantEntry.component.html',
    styleUrls: ['./plantEntry.component.scss'],


})


export class PlantEntry implements OnInit {
    lrCreationForm: FormGroup;
    statusForm: FormGroup;
    newEntryForm: FormGroup;
    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    private value: any = {};
    dataModel;
    cols: any[];
    cols1: any[];
    sortF: any;

    dummyData = [
        {
            'date': '01/02/2019',
            'truckNo': 'AP04AQ4647',
            'entryTime': '5:30:00',
            'comment': 'abcdef'

        },
        {
            'date': '01/01/2019',
            'truckNo': 'AP04AQ4659',
            'entryTime': '9:30:00',
            'comment': 'ghijklmno'

        }
    ]


    sourceList = []
    destinationList = []
    sourceListObj = []
    destinationListObj = []
    selectedVehicalObj
    selectedSource
    selectedDestination: any = [];
    lrReportStatus
    status: SelectItem[];
    selectedStatus: any;
    lrRecordUpdateStatus: any;
    UpdateselectedVehical;
    UpdateselectedSource;
    UpdateselectedDestination;
    tableValue = [];
    removeEndedTripFromTable = [];
    seletedTest;
    lrId;
    outgoingVehicle;
    map: any;
    @ViewChild("mapRef") mapRef: ElementRef;
    markersArray: any = [];
    directionsDisplay: any;
    grossWeight: any;
    tareweight: any;
    testList;
    netValue;




    StatusCheck = [{ value: "Proggress", selected: 0 },
    { value: "Reached", selected: 1 },
    { value: "Return", selected: 1 },];
    StatusType: any = [{ 'name': 'Please Select', 'value': -1 },
    { 'name': 'Proggress', 'value': 0 },
    { 'name': 'Reached', 'value': 1 },
    { 'name': 'Return', 'value': 2 },
    { 'name': 'End', 'value': 3 }];
    EntryVehicleNumber: any;
    entryVehicleList: any = [];
    outGoingVehicle: any;
    tareweightInput: any;

    public typed(value: any): void { let el: HTMLElement = document.getElementsByClassName("ui-select-choices dropdown-menu")[0] as HTMLElement; if (typeof el !== 'undefined') { el.style.display = 'block'; } }

    config = {
        displayKey: "description", //if objects array passed which key to be displayed defaults to description
        search: true, //true/false for the search functionlity defaults to false,
        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
        customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
        // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'No results found!',// text to be displayed when no items are found while searching
        searchPlaceholder: 'Search', // label thats displayed in search input,
        searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public lrDate: any;
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    selectedVehicleNumber: any;
    dashboardData: any;
    vehicalNo = [];
    setForUpdateTrackNo: any;
    setForUpdateSource: any;
    setForUpdateDestination: any;

    constructor(private httpService: HttpService,
        private urlFormatter: URLStringFormatter,
        private formBuilder: FormBuilder, ) {

    }

    newEntryCreationForm() {
        this.newEntryForm = this.formBuilder.group({
            truckNo: [''],
            comment: '',
        })
    }
    LorryReportCreationForm() {
        this.lrCreationForm = this.formBuilder.group({
            lrNo: [''],
            consignor: [''],
            consignee: [''],
            noOfPackages: [''],
            natureOfGoods: [''],
            actualWeight: [''],
            amount: [''],
            rate: [' '],
            quantity: [' '],
            currentStatus: 0,
            pan: [''],
            date: [''],
            truckNo: [''],
            invoiceNo: [''],
            wayBillNo: [''],
            freight: [''],
            advance: [''],
            stationaryCharges: [''],
            totalAmount: [''],
            destination: [''],
            source: [''],
            lorryHandlingCharges: [''],
            valueDeclared: [''],
            grossWeight: [''],
            tareweight: [''],
            netWeight: [{ value: '', disabled: true }],
            bankName: [''],
            accountName: [''],
            accountNumber: [''],
            ifsCode: [''],
        });
    }


    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getAllEntryVehicles();
        this.getVehicleList();
        this.getVehicalNo();
        this.LorryReportCreationForm();
        this.newEntryCreationForm();
        this.getAllLRLocations();
        this.getAllLRRecords();
        this.outsdiedCaptureEvent();
        this.outsdiedCaptureEvent1();

        this.statusForm = this.formBuilder.group({
            lrCreationstatus: ''
        })

        this.cols1 = [
            { field: 'date', header: 'Date' },
            { field: 'vehicleNumber', header: 'Vehicle Number' },
            { field: 'entryTime', header: 'EntryTime' },
            { field: 'comment', header: 'Comment' }
        ];

        // this.cols = [
        //     {field: 'lrNo', header: 'lR No'},
        //     {field: 'vehicalNo', header: 'Vehical No'},
        //     {field: 'sourceName', header: 'Source'},
        //     {field: 'destinationName', header: 'Destination'},
        //     {field: 'statusInfo', header: 'Info'}
        // ];

        // this.status = [
        //     { label: 'Select', value: 'Select' },
        //     { label: 'Progess', value: 'Progess' },
        //     { label: 'Reached', value: 'Reached'},
        //     { label: 'Return', value: 'Return'},
        //     { label: 'End', value: 'End' }
        // ];
        //this.showMap()
    }

    onNewEntryCreation() {

        // console.log('abcdef', this.newEntryForm.value.date.toLocaleDateString());
        // console.log('ghijk', this.newEntryForm.value.date.toLocaleTimeString());
        this.loading = true;
        this.selectedVehicalObj.forEach(Vehicle => {
            this.EntryVehicleNumber = Vehicle.vehicleNumber;
        });
        this.newEntryForm.get('truckNo').setValue(this.EntryVehicleNumber);
        // console.log('%%%%%%%%%%%', this.newEntryForm.value.truckNo);
        // console.log('zzzzzzzzz', this.EntryVehicleNumber);
        const obj = {
            vehicleNumber: this.EntryVehicleNumber,
            comment: this.newEntryForm.value.comment,
            status: 0
        }

        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_create_lr_entry, obj, "", reqOptions).then((dashboardData) => {
            // console.log('dashboardData', dashboardData);
            this.loading = false;
            this.getAllEntryVehicles();

        }).catch(err => {
            this.loading = false;
            console.log('Error at  onNewEntryCreation()')
        });
        jQuery('#lrnewEntryModal').modal('hide');
    }

    getAllEntryVehicles() {
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_create_all_lr_entry, "", reqOptions)
            .then((getdestinaitons) => {
                this.loading = false;
                // console.log('CCCCCCCCCC', getdestinaitons.lREntryData);
                // this.entryVehicleList = getdestinaitons.lREntryData;
                getdestinaitons.lREntryData.forEach(entrydata => {
                    // console.log('xxxx', entrydata.vehicleNumber);

                    entrydata.comment = entrydata.comment,
                        entrydata.vehicleNumber = entrydata.vehicleNumber,
                        entrydata.date = (new Date((entrydata.creationDateAndTime)).toLocaleDateString()),
                        entrydata.entryTime = (new Date((entrydata.creationDateAndTime)).toLocaleTimeString())

                    // console.log('QQQQQQQQQQQQ', this.entryVehicleList);
                });
                this.entryVehicleList = getdestinaitons.lREntryData.filter(el => {
                    return el.status != 1
                })
            }).catch(err => {
                this.loading = false;
                console.log('Error at  onNewEntryCreation()')
            })
    }


    outsdiedCaptureEvent1() {
        jQuery("#lrnewEntryModal").on("hidden.bs.modal", () => {
            this.newEntryForm.reset();
        });
    }

    outsdiedCaptureEvent() {
        jQuery("#onLRUpdateModel").on("hidden.bs.modal", () => {
            this.resetLRCreationForms();
        });
    }



    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
            this.gpsDevicesArray.forEach(Element => {
                this.vehicalNo.push(Element.vehicleNumber);
                // console.log('this.vehicalNo', this.vehicalNo);
            })
            this.selectedVehicleNumber = this.gpsDevicesArray[0].vehicleNumber;
        })
    }
    getVehicalNo() {
        // alert();
        //  this.gpsDevicesArray.forEach(Element=>{
        //     console.log("yes");
        //     console.log(JSON.stringify(Element));
        // });
    }
    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {
        let body = [];
        body.push(this.dashboardData);
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        let dashBoardParams = {
            imeiNumber: this.selectedVehicle.imeiNumber,
            toDate: this.toDate.formatted,
            fromDate: this.fromDate.formatted
        }

        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.get_dashboard_data_for_report, dashBoardParams, "", reqOptions).then((dashboardData) => {
            // console.log("dashboarddata..............", JSON.stringify(dashboardData));
            this.isPdfNotAvailable = false;
            this.loading = false;
            if (dashboardData && dashboardData.statusCode === 200) {
                this.dashboardData = dashboardData;
                this.dashboardData.recordList.forEach(element => {
                    let dateTimeStamp = new Date(element.historyDate).getTime();
                    element.historyTimeStamp = dateTimeStamp;
                });
                this.dashboardData.recordList.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
            }
        }).catch((dashboardErr) => {
            this.loading = false;
        });
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/dailysummaryreport', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange(device) {
        // console.log('device====>', device);
        this.selectedVehicle = device;
    }

    public inputTyped = (source: string, text: string) => {
        // console.log('LorryReceiptComponent.inputTyped', source, text);
    }

    public doFocus = () => {
        // console.log('LorryReceiptComponent.doFocus');
    }

    public doBlur = () => {
        // console.log('LorryReceiptComponent.doBlur');
    }

    public doOpen = () => {
        // console.log('LorryReceiptComponent.doOpen');
    }

    public doClose = () => {
        // console.log('LorryReceiptComponent.doClose');
    }

    public doSelect = (value: any) => {
        // console.log('LorryReceiptComponent.doSelect', value);
    }

    public doRemove = (value: any) => {
        // console.log('LorryReceiptComponent.doRemove', value);
        const remove = this.removeDestinatinName(value)
        // console.log('remove===>', remove);

    }

    removeDestinatinName(destinationName) {
        // return this.selectedDestination.filter(el=> { el.placeName != destinationName });
        return this.selectedDestination.splice(this.selectedDestination.findIndex(item => item.placeName === destinationName), 1)
    }

    public doSelectOptions = (options) => {
        // console.log('LorryReceiptComponent.doSelectOptions', options);
    }


    public doSelectVehical = (value: any) => {
        this.selectedVehicalObj = this.getvehicalNoObjFilter(value)
        // console.log('!!!!!!!!!!!!!!!', this.selectedVehicalObj);
    }

    public doSelectSource = (value: any) => {
        this.selectedSource = this.getSoucreObjFilter(value)
    }
    public doSelectDestination = (value: any) => {
        this.selectedDestination = this.getDestinationFilter(value);
        this.selectedDestination.push(this.getDestinationFilter(value)[0])
        // console.log('this.selectedDestination======>', this.selectedDestination);
    }

    getSoucreObjFilter(nameProperty) {
        return this.sourceListObj.filter(name => name.placeName === nameProperty)
    }
    getDestinationFilter(destnameProperty) {
        return this.destinationListObj.filter(name => name.placeName === destnameProperty)
    }
    getvehicalNoObjFilter(vehicalObj) {
        return this.gpsDevicesArray.filter(number => number.vehicleNumber === vehicalObj)
    }

    dummy(data) {
        // console.log('#####', data);
        this.outGoingVehicle = data;
        // console.log('#####', this.outGoingVehicle);
        this.outgoingVehicle = this.outGoingVehicle.vehicleNumber;
    }

    onLRCrationSubmit() {

        if (this.lrDate === undefined || this.lrDate === null) {
            return alert("Please Date of Lorry Report Creation")
        } else {

            const date = this.lrDate.formatted;
            const dbDate = new Date(date).toISOString();
            this.lrCreationForm.get('date').setValue(dbDate);
        }
        // if (this.selectedVehicalObj === undefined) {
        //     return alert("Please Select Vehical No.")
        // } else {
        //     // const vehicalNo = this.selectedVehicalObj[0];
        //     // this.lrCreationForm.get('truckNo').setValue(vehicalNo);
        // }
        if (this.selectedSource === undefined) {
            return alert("Please Select Source Name.")
        } else {
            const source = this.selectedSource[0];
            this.lrCreationForm.get('source').setValue(source);
        }
        if (this.selectedDestination === undefined) {
            return alert("Please Select Destination Name.")
        } else {
            const detination = this.selectedDestination;
            this.lrCreationForm.get('destination').setValue(detination);

        }

        this.lrCreationForm.get('truckNo').setValue(this.getvehicalNoObjFilter(this.outGoingVehicle.vehicleNumber)[0]);
        this.lrCreationForm.get('netWeight').setValue(this.netValue); 
        // console.log('this.lrCreationForm.value');
        // console.log(this.lrCreationForm.value);

        this.createLRRecords(this.lrCreationForm.value);

        //jQuery('#lrCreationModel').modal('hide');
    }

    getAllLRLocations() {
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_all_lr_locations, '', reqOptions).then(getAllLRlocation => {
            //console.log('getAllLRlocation',JSON.stringify(getAllLRlocation));
            const allLRLocationList = getAllLRlocation.lRLocationData;
            allLRLocationList.forEach(location => {
                if (location.placeType === 0) {
                    this.sourceList.push(location.placeName);
                    this.sourceListObj.push(location);
                } else {
                    this.destinationList.push(location.placeName);
                    this.destinationListObj.push(location);
                }
            })

            // console.log( 'this.destinationList')
            // console.log(this.destinationList)

        }).catch((getAllLRlocationErr) => {
            this.loading = false;
            // console.log("getAllLRlocationErr...", JSON.stringify(getAllLRlocationErr));
        });

    }
    createLRRecords(lrCreatedObj) {
        // console.log('zzzzzzzzwwwwww', lrCreatedObj);
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_create_lr_records, lrCreatedObj, '', reqOptions).then((dashboardData) => {
            // console.log('zzzzzzzzxxxxxxcccczxxzdssd', dashboardData);
            this.getAllLRRecords();
            this.updateEntryStatus();
            jQuery('#lrCreationModel').modal('hide');
            this.getAllEntryVehicles();
        }).catch(err => {
            console.log("LR Creation Error");
            // jQuery('#lrCreationModel').modal('hide');
        });
    }

    updateEntryStatus() {
        this.outGoingVehicle;
        const reqBody = this.outGoingVehicle._id;
        const statusid = 1;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option

        this.httpService.httpGetPromise(AppConstants.update_lr_entry_status + reqBody + '/' + statusid, "", reqOptions)
            .then((updateCurrentStatus) => {
                //  console.log('updateCurrentStatus', updateCurrentStatus);
            })
    }


    getAllLRRecords() {
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_all_lr_records, '', reqOptions).then(getAlllrRecords => {
            const allLRRecordsList = getAlllrRecords.lRRecordsData;
            // console.log("getAllLRRecords",JSON.stringify(getAlllrRecords));
            allLRRecordsList.forEach(recodrd => {


                const sourceNames = recodrd.source;
                const destinationNames = recodrd.destination;
                const vehicalNos = recodrd.truckNo;

                recodrd.sourceName = sourceNames.placeName;
                recodrd.destinationName = destinationNames.placeName;
                recodrd.vehicalNo = vehicalNos.vehicleNumber;

                if (recodrd.currentStatus === 0) {
                    recodrd.statusInfo = 'Progress'
                } else if (recodrd.currentStatus === 1) {
                    recodrd.statusInfo = 'Reached'
                } else if (recodrd.currentStatus === 2) {
                    recodrd.statusInfo = 'Return'
                } else if (recodrd.currentStatus === 3) {

                    //recodrd._id !== recodrd._id;
                    recodrd.statusInfo = 'End'
                }
                if (recodrd.currentStatus != 3) {
                    this.removeEndedTripFromTable.push(recodrd)
                }

            });
            // this.tableValue = this.removeEndedTripFromTable;
            // setInterval(()=> { this.tableValue }, 1000);
            // console.log("getAllLRRecords",JSON.stringify(this.tableValue));;

            this.tableValue = allLRRecordsList.filter(function (el) { return el.statusInfo != "End"; });
            // console.log("getAllLRRecords",JSON.stringify(allLRRecordsList));

        }).catch((getAlllrRecordsError) => {
            //this.loading = false;
            console.log("getAlllrRecordsError...", getAlllrRecordsError);
        });
    }
    onLrReportStatusChange(e) {

        // console.log(e);
        if (e === 'Progess') {
            // console.log('I am Progress');
            //  this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 0
            // })
            // console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]

            this.updateCurrentStatusLRRecords(updatedStateObj._id, 0);

        } else if (e === 'Reached') {
            console.log('I am Reached');
            // this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 1
            // })
            console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]

            this.updateCurrentStatusLRRecords(updatedStateObj._id, 1);
        } else if (e === 'Return') {
            // console.log('I am Return');
            //  this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 2
            // })
            // console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]
            this.updateCurrentStatusLRRecords(updatedStateObj._id, 2);
        }
        else if (e === 'End') {
            // console.log('I am end')
            //  this.lrRecordUpdateStatus.forEach(updateStatus=>{
            //     updateStatus.currentStatus = 3
            // })
            // console.log(this.lrRecordUpdateStatus);
            const updatedStateObj = this.lrRecordUpdateStatus[0]
            this.updateCurrentStatusLRRecords(updatedStateObj._id, 3);
        }

    }
    valueChanged(data) {
        // console.log(data);
        const dataObj = []
        dataObj.push(data);
        this.lrRecordUpdateStatus = this.removeDuplicates(dataObj);
    }

    removeDuplicates(array) {
        let uniq = [];
        return array.filter(obj => !uniq[obj._id] && (uniq[obj._id] = true))
    }

    onLRRecordsEdit(data) {
        // console.log('datadata=========>', data);
        this.lrId = data._id;

        // console.log('Data',JSON.stringify(data.vehicalNo));
        // console.log('Data',JSON.stringify(data.sourceName));
        // console.log('Data',JSON.stringify(data.destinationName));
        // this.seletedTest = data.vehicalNo;
        const dateObj = { year: 0, month: 0, day: 0 }
        const dbDate = data.date;
        dateObj.year = new Date(dbDate).getFullYear();
        dateObj.month = new Date(dbDate).getMonth() + 1;
        dateObj.day = new Date(dbDate).getDate();
        // console.log('dateObj=====>', dateObj);
        this.lrDate = { date: dateObj };

        this.UpdateselectedVehical = data.vehicalNo
        this.UpdateselectedSource = data.sourceName
        this.UpdateselectedDestination = data.destinationName



        this.lrCreationForm.get('lrNo').setValue(data.lrNo);
        this.lrCreationForm.get('consignor').setValue(data.consignor);
        this.lrCreationForm.get('consignee').setValue(data.consignee);
        this.lrCreationForm.get('noOfPackages').setValue(data.noOfPackages);
        this.lrCreationForm.get('natureOfGoods').setValue(data.natureOfGoods);
        this.lrCreationForm.get('actualWeight').setValue(data.actualWeight);
        this.lrCreationForm.get('amount').setValue(data.amount);
        this.lrCreationForm.get('rate').setValue(data.rate);
        this.lrCreationForm.get('quantity').setValue(data.quantity);
        this.lrCreationForm.get('currentStatus').setValue(data.currentStatus);
        this.lrCreationForm.get('pan').setValue(data.pan);

        this.lrCreationForm.get('date').setValue(data.date);

        this.lrCreationForm.get('truckNo').setValue(data.vehicalNo);

        this.lrCreationForm.get('invoiceNo').setValue(data.invoiceNo);
        this.lrCreationForm.get('wayBillNo').setValue(data.wayBillNo);
        this.lrCreationForm.get('freight').setValue(data.freight);
        this.lrCreationForm.get('advance').setValue(data.advance);
        this.lrCreationForm.get('stationaryCharges').setValue(data.stationaryCharges);
        this.lrCreationForm.get('totalAmount').setValue(data.totalAmount);


        this.lrCreationForm.get('destination').setValue(data.actualWeight);
        this.lrCreationForm.get('source').setValue(data.actualWeight);


        this.lrCreationForm.get('lorryHandlingCharges').setValue(data.lorryHandlingCharges);
        this.lrCreationForm.get('valueDeclared').setValue(data.valueDeclared);
        jQuery('#onLRUpdateModel').modal('show');

    }
    onLRRecordsDelete(data) {
        // console.log(data);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.removeLRRecords(data._id);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
        // if (confirm(`Are you sure you do you want delete the Receipt? IR No: ${data.lrNo}`)) {
        //       console.log('Delete Data',data);
        //         this.removeLRRecords(data._id);

        // } else {
        //    console.log("NO Changes")
        // }

        // jQuery('#onLRUpdateModel').modal('show');
    }
    onLRRecordsLocationTrack(data) {
        // console.log(data);
        this.showMap(data)


        const sourceLatObj = data.source;
        const destinationLanObj = data.destination;

        jQuery('#map_model').modal('show');
        this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true, preserveViewport: true, polylineOptions: { strokeColor: "green" } });
        this.calcRoute(sourceLatObj.location, destinationLanObj.location);
    }

    onLRUpdate() {

        if (this.selectedSource === undefined) {
            const source = this.getSoucreObjFilter(this.UpdateselectedSource)
            this.lrCreationForm.get('source').setValue(source[0]);
        } else {
            const vehicalNo = this.selectedSource[0];
            this.lrCreationForm.get('source').setValue(vehicalNo);
        }
        if (this.selectedDestination === undefined) {
            const destination = this.getDestinationFilter(this.UpdateselectedDestination)
            this.lrCreationForm.get('destination').setValue(destination[0]);
        } else {
            const destination = this.selectedDestination[0];
            this.lrCreationForm.get('destination').setValue(destination);
        }
        if (this.selectedVehicalObj === undefined) {
            const vehicalObj = this.getvehicalNoObjFilter(this.UpdateselectedVehical)
            this.lrCreationForm.get('truckNo').setValue(vehicalObj[0]);
        } else {
            const vehicalNo = this.selectedVehicalObj[0];
            this.lrCreationForm.get('truckNo').setValue(vehicalNo);
        }

        this.sourceListObj

        // console.log('this.lrDate', this.lrDate);
        const updateObj = this.lrCreationForm.value;
        updateObj['_id'] = this.lrId;

        // console.log('this.lrCreationForm.value');
        // console.log(this.lrCreationForm.value);
        this.updateLRRecords(this.lrCreationForm.value)
    }
    resetLRCreationForms() {
        this.lrCreationForm.reset();
        this.UpdateselectedVehical = ''
        this.UpdateselectedSource = ''
        this.UpdateselectedDestination = ''
        // this.vehicalNo[-1];
        // this.sourceList
        // this.destinationList

    }
    updateLRRecords(updatedObj) {
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_update_lr_records, updatedObj, '', reqOptions).then((updatedObjList) => {

            this.getAllLRRecords();
            jQuery('#onLRUpdateModel').modal('hide');
            this.getAllLRRecords();
        }).catch(err => {
            console.log("Updated Creation Error");
            jQuery('#onLRUpdateModel').modal('hide');
        });
    }
    removeLRRecords(reqBody) {
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option

        this.httpService.httpGetPromise(AppConstants.get_remove_lr_records + reqBody, "", reqOptions)
            .then((getdestinaitons) => {
                this.getAllLRRecords();

            }).catch(err => {
                console.log("Deletion LR Recored  Error");
            });
    }
    changeLrPlaceType($event) {
        // console.log($event.target.value);
        // tslint:disable-next-line: no-unused-expression
        const lrplacevalue = parseInt($event.target.value);
        // console.log('this.placeTypevalue', this.lrplacevalue);

        this.lrCreationForm.get('placeType').setValue($event.target.value, {
            onlySelf: true
        });

    }
    updateCurrentStatusLRRecords(reqBody, id) {
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option

        this.httpService.httpGetPromise(AppConstants.update_current_status_lr_records + reqBody + '/' + id, "", reqOptions)
            .then((updateCurrentStatus) => {
                console.log(updateCurrentStatus);
                this.getAllLRRecords();

            }).catch(err => {
                console.log("Update Current Status of LR Recored  Error");
            });
    }
    showMap(data) {


        const location = { lat: 12.916719700000002, lng: 77.6027597 };
        var options = {
            center: location,
            zoom: 10
        }

        const sourceLatObj = data.source;
        const destinationLanObj = data.destination;

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        this.applymarkers(sourceLatObj.location, sourceLatObj.placeName, google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, 'red', '', 0)
        this.applymarkers(destinationLanObj.location, destinationLanObj.placeName, google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, 'green', '', 0)
        // this.showPolygonsOnMap();
        // this.calcRoute();
        //krishna river sand reach mid point
        // this.applymarkers({ lat: 16.493096, lng: 80.626315 }, "Krishna River Sand Reach", google.maps.SymbolPath.CIRCLE, "red", { lat: 16.493096, lng: 80.626315 }, 0)
        // //krishna river dumps
        // this.applymarkers({ lat: 16.493754, lng: 80.161505 }, "Patamata Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)

    }
    applymarkers(locObject, vehicleNumber, type, color, sandReachloc, sandReachType) {

        //google.maps.SymbolPath.FORWARD_CLOSED_ARROW

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);
        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            icon: {
                path: type,
                strokeColor: color,
                scale: 6
            },
            animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {
            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                let contentString = `<table style="width:200px">
              <tr>
              <td> <b>  ` + vehicleNumber + ` </b></td>
              </tr>
              <tr><td> <b> Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);

            }).catch((google_error) => {
                // handle errors 

            });
        });
    }
    calcRoute(startLoc, endLoc) {

        let directionsService = new google.maps.DirectionsService();
        let start = new google.maps.LatLng(startLoc.lat, startLoc.lng);
        let end = new google.maps.LatLng(endLoc.lat, endLoc.lng);
        let request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, (response, status) => {
            // console.log("response == >" + JSON.stringify(response.routes[0].legs[0].distance.text));

            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
                /*  this.map.setZoom(12);
                 this.googleBounds.extend(new google.maps.LatLng(16.493096, 80.626315));
                 this.googleBounds.extend(new google.maps.LatLng(16.493754, 80.161505));
                 this.map.fitBounds(this.googleBounds); */
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }
    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

    onKeyUpGrossWeight(event){
        this.grossWeight = event.target.value;
        if(this.grossWeight ==='' || this.grossWeight===null || this.grossWeight ===undefined){
        this.lrCreationForm.get('tareweight').setValue(''); 
        this.lrCreationForm.get('netWeight').setValue(''); 
        }

    }  
    mychange(val){
        this.lrCreationForm.get('tareweight').setValue(''); 
        this.lrCreationForm.get('netWeight').setValue(''); 
    }
    onKeyUpTareweight(event){
        this.tareweightInput = event.target.value;
        if(this.grossWeight === undefined){
            alert('Please Fill Gross Weight!');
            event.target.value = '';
        }{
        this.netValue =   this.grossWeight - this.tareweightInput;
        this.lrCreationForm.get('netWeight').setValue(this.netValue); 
        }
    }
}
