import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
import { SelectItem } from 'primeng/primeng';
import * as $AB from 'jquery';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import * as jquery from 'jquery';
import { Observable } from 'rxjs';
declare var google: any;
declare var jQuery: any;
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

declare var jquery: any;   // not required
declare var $: any;   // not required

import { Headers, RequestOptions } from '@angular/http';


@Component({
    selector: 'app-centralizedLR',
    templateUrl: './centralizedLR.component.html',
    styleUrls: ['./centralizedLR.component.scss'],


})


export class CentralizedLRComponent implements OnInit {
    rowData: any;
    lrCreationForm: FormGroup;
    statusForm: FormGroup;
    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    private value: any = {};
    dataModel;
    cols: any[];

    sourceList = []
    destinationList = []
    sourceListObj = []
    destinationListObj = []
    selectedVehicalObj
    selectedSource
    //selectedDestination;
    selectedDestination = []
    lrReportStatus
    status: SelectItem[];
    selectedStatus: any;
    lrRecordUpdateStatus: any;
    UpdateselectedVehical;
    UpdateselectedSource;
    UpdateselectedDestination;
    tableValue = [];
    removeEndedTripFromTable = [];
    seletedTest;
    lrId;
    map: any;
    @ViewChild("mapRef") mapRef: ElementRef;
    markersArray: any = [];
    directionsDisplay: any;
    grossWeight
    tareweightInput
    printLaryReceiptData
    selectedVehicleNumberForUpdate;
    selectedSourceForUpdate;
    selectedDestinationForUpdate
    dateUpdatetoDBnotSelected;
    updateDestinationList = [];
    netValue
    obj;
    LeadTimeRemaining = "red"
    testList;
    public ngxValue: any = [];
    allWell = 0;
    mediuIssues = 0;
    delyedTrips = 0;
    gpsDelyedHR = 0;
    tripCompleted = 0;
    asignedGpsDevice = [];

    expectedReachDate
    actualReachDate


    singaleVeicalArrayObj = [];
    StatusCheck = [{ value: "Proggress", selected: 0 },
    { value: "Reached", selected: 1 },
    { value: "Return", selected: 1 },];
    StatusType: any = [{ 'name': 'Please Select', 'value': -1 },
    { 'name': 'Proggress', 'value': 0 },
    { 'name': 'Reached', 'value': 1 },
    { 'name': 'Return', 'value': 2 },
    { 'name': 'End', 'value': 3 }];

    config = {
        displayKey: "description", //if objects array passed which key to be displayed defaults to description
        search: true, //true/false for the search functionlity defaults to false,
        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
        customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
        // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'No results found!',// text to be displayed when no items are found while searching
        searchPlaceholder: 'Search', // label thats displayed in search input,
        searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }

    public typed(value: any): void { let el: HTMLElement = document.getElementsByClassName("ui-select-choices dropdown-menu")[0] as HTMLElement; if (typeof el !== 'undefined') { el.style.display = 'block'; } }


    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { month: new Date().getMonth() + 1, day: new Date().getDate() + 1, year: new Date().getFullYear() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { month: new Date().getMonth() + 1, day: new Date().getDate() + 1, year: new Date().getFullYear() }
    };


    // Initialized to specific date (09.10.2018).
    public lrDate: any;
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    selectedVehicleNumber: any;
    dashboardData: any;
    vehicalNo = [];
    setForUpdateTrackNo: any;
    setForUpdateSource: any;
    setForUpdateDestination: any;
    userMobile: any
    // centralizedLRCreation Data 
    centralizedLRObject: any = []
    singaleLRReportObj = [];
    statusArray = [];


    colors = [{ status: "ONGOING", color: "red" }, { status: "End", color: "red" }, { status: "warning", color: "green" }, { status: "Ignored", color: "yellow" }]
    modelArrayData = []
    constructor(private httpService: HttpService,
        private urlFormatter: URLStringFormatter,
        private formBuilder: FormBuilder,) {

    }

    getTheColor(status) {
        if (status != undefined) {
            console.log('status', status);
            return this.colors.filter(item => item.status === status)[0].color // could be better written, but you get the idea

        }


    }


    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobile = localStorage.getItem("userMobile");
        this.getVehicleList();
        // this.getVehicalNo();
        // this.LorryReportCreationForm();
        this.getAllLRLocations();
        this.getAllLRRecords();
        this.outsdiedCaptureEvent();
        this.statusForm = this.formBuilder.group({
            lrCreationstatus: ''
        })

        this.cols = [
            { field: 'lrNo', header: 'lR No' },
            { field: 'vehicleNo', header: 'Vehical No' },
            { field: 'tripdestName', header: 'Dest-Name' },
            { field: 'lrDateAndTime', header: 'Dispatch Date' },
            { field: 'vexpectedReachDate', header: 'End Date' },
            { field: 'tripStatus', header: 'Satus' },
            { field: 'yetToReactDistance', header: 'Travel Distance Pending' }
        ];

        this.status = [
            { label: 'Select', value: 'Select' },
            { label: 'Progess', value: 'Progess' },
            { label: 'Reached', value: 'Reached' },
            { label: 'Return', value: 'Return' },
            { label: 'End', value: 'End' }
        ];
        this.statusArray = [
            { label: 'All', value: 'ONGOING' },
            { label: 'Medium', value: 'End' },
            { label: 'Delayed', value: 3 },
            { label: 'Gps Delayed', value: 4 },
            { label: 'Completed', value: 5 }
        ]

        //this.showMap()
        // this.getCentralizedLRCalculation();
        // this.getTripSheetServiceObj(); //commented by kundan
        // this.Calculation();
        this.gpsdevice();
    }


    outsdiedCaptureEvent() {
        jQuery("#onLRUpdateModel").on("hidden.bs.modal", () => {
        });
    }



    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            console.log('gpsDevicesResponse=>', gpsDevicesResponse)
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            //console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
            this.gpsDevicesArray.forEach(Element => {
                this.vehicalNo.push(Element.vehicleNumber);
            })
            this.selectedVehicleNumber = this.gpsDevicesArray[0].vehicleNumber;
        })
    }

    convert() {
        // let body = [];
        // body.push(this.dashboardData);
        // console.log(JSON.stringify(this.centralizedLRObject));

        let value = [];
        this.centralizedLRObject.forEach(tripDateObj => {
            let subValueArray = [];
            subValueArray.push(tripDateObj.lrNo);
            subValueArray.push(tripDateObj.vehicleNo);
            subValueArray.push(tripDateObj.driverContactNo + " / " + tripDateObj.ownerContactNo);
            subValueArray.push(tripDateObj.tripdestName);
            subValueArray.push(tripDateObj.lrDateAndTime);
            subValueArray.push(tripDateObj.expectedReachDate);
            if(tripDateObj.actualReachDate === undefined ){
                subValueArray.push('MM/DD/YYYY');
            } else {
                subValueArray.push(tripDateObj.reachedDate);
            }
            subValueArray.push(tripDateObj.delayedBy);
            subValueArray.push(tripDateObj.tripStatus);
            value.push(subValueArray);
        });

        let headerName = "Centralized LR Report (" + this.fromDate.formatted + " to " + this.toDate.formatted + ")";

        let body = {
            "headerName": headerName,
            "excelName": "CentralizedLRReport",
            "header": [
                "LR No",
                "Vehicle No",
                "Driver/Owner Contact",
                "Destination",
                "Origin Date",
                "End Date",
                "Close Trip Date",
                "Days Delayed",
                "Status"
            ],
            "value": value
        }

        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }



    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + 'genericxls', true);
            xhr.setRequestHeader('Content-type', 'application/json');
          //  xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://excel.hawkeyeway.com');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }


    getAllLRLocations() {
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_all_lr_locations, '', reqOptions).then(getAllLRlocation => {
            // console.log('getAllLRlocation',JSON.stringify(getAllLRlocation));
            const allLRLocationList = getAllLRlocation.lRLocationData;

            allLRLocationList.forEach(location => {
                if (location.placeType === 0) {
                    this.sourceList.push(location.placeName);
                    this.sourceListObj.push(location);
                } else {
                    this.destinationList.push(location.placeName);
                    this.destinationListObj.push(location);
                }
            })

        }).catch((getAllLRlocationErr) => {
            this.loading = false;
            console.log("getAllLRlocationErr...", JSON.stringify(getAllLRlocationErr));
        });

    }
    createLRRecords(lrCreatedObj) {
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_create_lr_records, lrCreatedObj, '', reqOptions).then((dashboardData) => {
            this.loading = false;
            this.getAllLRRecords();
            jQuery('#lrCreationModel').modal('hide');
        }).catch(err => {
            console.log("LR Creation Error");
            jQuery('#lrCreationModel').modal('hide');
        }).catch(err => {
            console.log(err);
            this.loading = false;
        });
    }
    getAllLRRecords() {
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_all_lr_records, '', reqOptions).then(getAlllrRecords => {
            const allLRRecordsList = getAlllrRecords.lRRecordsData;
            this.loading = false;
            // console.log("getAllLRRecords",JSON.stringify(getAlllrRecords));
            allLRRecordsList.forEach(recodrd => {


                const sourceNames = recodrd.source;
                const allname = []
                recodrd.destination.forEach(Element => {
                    allname.push(Element.placeName)
                })

                recodrd.destinationName = allname.join();
                const vehicalNos = recodrd.truckNo;

                recodrd.sourceName = sourceNames.placeName;
                recodrd.vehicalNo = vehicalNos.vehicleNumber;

                if (recodrd.currentStatus === 0 || recodrd.currentStatus === null) {
                    recodrd.statusInfo = 'Progress'
                } else if (recodrd.currentStatus === 1) {
                    recodrd.statusInfo = 'Reached'
                } else if (recodrd.currentStatus === 2) {
                    recodrd.statusInfo = 'Return'
                } else if (recodrd.currentStatus === 3) {

                    recodrd.statusInfo = 'End'
                }
                if (recodrd.currentStatus != 3) {
                    this.removeEndedTripFromTable.push(recodrd)
                }

            });

            this.tableValue = allLRRecordsList.filter(function (el) { return el.statusInfo != "End"; });
            // console.log("getAllLRRecords==>",JSON.stringify( this.tableValue));

        }).catch((getAlllrRecordsError) => {
            this.loading = false;
            console.log("getAlllrRecordsError...", getAlllrRecordsError);
        });
    }

    valueChanged(data) {
        console.log(data);
        const dataObj = []
        dataObj.push(data);
        this.lrRecordUpdateStatus = this.removeDuplicates(dataObj);
    }

    removeDuplicates(array) {
        let uniq = [];
        return array.filter(obj => !uniq[obj._id] && (uniq[obj._id] = true))
    }


    showMap(data) {


        const location = { lat: 12.916719700000002, lng: 77.6027597 };
        var options = {
            center: location,
            zoom: 10
        }

        const sourceLatObj = data.source;
        // const destinationLanObj = data.destination[0];

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // this.applymarkers(sourceLatObj.location, sourceLatObj.placeName, google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, 'red', '', 0);

        data.destination.forEach(destinationLanObj => {
            this.applymarkers(destinationLanObj.location, destinationLanObj.placeName, google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, 'green', '', 0)
        });
        // this.showPolygonsOnMap();
        // this.calcRoute();
        //krishna river sand reach mid point
        // this.applymarkers({ lat: 16.493096, lng: 80.626315 }, "Krishna River Sand Reach", google.maps.SymbolPath.CIRCLE, "red", { lat: 16.493096, lng: 80.626315 }, 0)
        // //krishna river dumps
        // this.applymarkers({ lat: 16.493754, lng: 80.161505 }, "Patamata Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)

    }
    applymarkers(locObject, vehicleNumber, type, color, sandReachloc, sandReachType) {

        //google.maps.SymbolPath.FORWARD_CLOSED_ARROW

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);
        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            icon: {
                path: type,
                strokeColor: color,
                scale: 6
            },
            animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {
            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                let contentString = `<table style="width:200px">
              <tr>
              <td> <b>  ` + vehicleNumber + ` </b></td>
              </tr>
              <tr><td> <b> Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);

            }).catch((google_error) => {
                // handle errors 

            });
        });
    }
    calcRoute(startLoc, curentlocation, endLoc) {

        let directionsService = new google.maps.DirectionsService();
        let start = new google.maps.LatLng(startLoc.lat, startLoc.lng);
        let curentlatlng = new google.maps.LatLng(curentlocation.lat, curentlocation.lng);
        let end = new google.maps.LatLng(endLoc.lat, endLoc.lng);
        let request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        console.log('yes one')
        directionsService.route(request, (response, status) => {
            // console.log("response == >" + JSON.stringify(response.routes[0].legs[0].distance.text));

            if (status == google.maps.DirectionsStatus.OK) {
                console.log('yes two')
                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
                this.directionsDisplay.setPosition(curentlatlng);
                /*  this.map.setZoom(12);
                 this.googleBounds.extend(new google.maps.LatLng(16.493096, 80.626315));
                 this.googleBounds.extend(new google.maps.LatLng(16.493754, 80.161505));
                 this.map.fitBounds(this.googleBounds); */
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }
    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }
    // LR Calculation
    // getCentralizedLRCalculation(){
    //     this.lrRepotObject.forEach(lrvalue =>{
    //         let latName =  lrvalue.lrReportObject.destination[lrvalue.lrReportObject.destination.length-1]
    //         lrvalue.lrReportObject.distinationCounts = lrvalue.lrReportObject.destination.length; 
    //         lrvalue.lrReportObject.distinationlastPlace =latName.destinationName; 
    //         lrvalue.lrReportObject.formateDispatchDate =new Date(lrvalue.lrReportObject.dispatchDate).toLocaleDateString(); 
    //         lrvalue.lrReportObject.formateArivalDate =new Date(lrvalue.lrReportObject.expectedArraivalDate).toLocaleDateString();
    //         let distinationPendingCount = 0;
    //         let distinationCompletedCount = 0
    //         lrvalue.lrReportObject.destination.forEach(element=>{ 
    //             if(element.status== false){
    //                  lrvalue.lrReportObject.distinationPending = ++distinationPendingCount
    //             }
    //             if(element.status== true){
    //                  lrvalue.lrReportObject.distinaionCompleate = ++distinationCompletedCount
    //             }
    //         })
    //     });
    //     //this.centralizedLRObject = this.lrRepotObject;
    //     // console.log(JSON.stringify(this.centralizedLRObject));

    // }

    distanceFrom(oldLatLng, newLatLng) {
        let EarthRadiusMeters = 6378137.0; // meters
        let lat1 = oldLatLng.lat;
        let lon1 = oldLatLng.lng;
        let lat2 = newLatLng.lat;
        let lon2 = newLatLng.lng;
        let dLat = (lat2 - lat1) * Math.PI / 180;
        let dLon = (lon2 - lon1) * Math.PI / 180;
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = EarthRadiusMeters * c;
        return d;

    }

    onLTTrack(data) {
        this.modelArrayData = []
       
       
        console.log(JSON.stringify(data.currentVehicleLoc));
        if(data.currentVehicleLoc !== undefined){
        this.getAddressFromGoogle(data.currentVehicleLoc.lat, data.currentVehicleLoc.lng).then((google_response) => {
        let geo_address = google_response.results[0].formatted_address;
        data.currentVehicleAddress = geo_address; 
        let destLocation = {
               lat :  "",
               lng : "" 
        }
             let totalDistanceinMeters = this.distanceFrom(data.currentVehicleLoc, data.tripDestLocation);
             let originalDistanceToTravell   = (totalDistanceinMeters / 1000) ;
              let yetToReachNumeric = ( originalDistanceToTravell+((originalDistanceToTravell * 15)/100));
              data.yetToReactDistance  = ( originalDistanceToTravell+((originalDistanceToTravell * 15)/100)).toFixed(2);
              data.travelledAsOfNow =  data.tripTotalTravellDistance  - data.yetToReactDistance;
        this.singaleLRReportObj = [];
        this.modelArrayData.push(data)
        this.singaleLRReportObj.push(data.sourcelocation)
        this.singaleLRReportObj.push(data.currentVehicleLoc)
        this.singaleLRReportObj.push(data.tripdestLocation)
        this.calcRoute(data.sourcelocation, data.currentVehicleLoc, data.tripdestLocation)
        console.log('source', this.singaleLRReportObj)
        this.showMapLR(this.singaleLRReportObj)

           
        }).catch((google_error) => {
            // handle errors 

        });
    }else{

        this.singaleLRReportObj = [];
        this.modelArrayData.push(data)
        this.singaleLRReportObj.push(data.sourcelocation)
        this.singaleLRReportObj.push(data.currentVehicleLoc)
        this.singaleLRReportObj.push(data.tripdestLocation)
        this.calcRoute(data.sourcelocation, data.currentVehicleLoc, data.tripdestLocation)
        console.log('source', this.singaleLRReportObj)
        this.showMapLR(this.singaleLRReportObj)

    }
        
    }
    showMapLR(data) {
        console.log('source', data)

        const location = { lat: 12.916719700000002, lng: 77.6027597 };
        var options = {
            center: location,
            zoom: 5
        }

        // const sourceLatObj = data.source;

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);

        data.forEach(destinationLanObj => {
            if (destinationLanObj.lat !== undefined || destinationLanObj.lat !== null && destinationLanObj.lng !== undefined || destinationLanObj.lng !== null) {
                this.applymarkersLR(destinationLanObj, google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, 'green', '', 0)
            }
        });
    }
    applymarkersLR(locObject, type, color, sandReachloc, sandReachType) {

        //google.maps.SymbolPath.FORWARD_CLOSED_ARROW

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);
        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            icon: {
                path: type,
                strokeColor: color,
                scale: 6
            },
            animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {
            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                let contentString = `<table style="width:200px">
              <tr><td> <b> Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);

            }).catch((google_error) => {
                // handle errors 

            });
        });
    }
    onCallingTrip() {
        // this.loading = true;
        this.getTripSheetServiceObj();
    }
    getTripSheetServiceObj() {
        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select To Date first');
            return;
        }
        this.loading = true;
        this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: false, polylineOptions: { strokeColor: "green" } });
        this.allWell = 0;
        this.mediuIssues = 0;
        this.delyedTrips = 0;
        this.gpsDelyedHR = 0;
        this.tripCompleted = 0;

        // console.log(this.fromDate)
        // console.log(this.toDate)
        // if (this.fromDate !== undefined) {
        //     this.fromDate = this.fromDate.formatted
        // }
        // if (this.toDate !== undefined) {
        //     this.toDate = this.toDate.formatted
        // }
        // console.log(this.getFormattedDate(this.fromDate.jsdate))
        // console.log(this.getFormattedDate(this.toDate.jsdate))
        this.fromDate = JSON.parse(JSON.stringify(this.fromDate));
        this.toDate = JSON.parse(JSON.stringify(this.toDate));
        // this.loading = true;
        let reqBody = {
            "fromDate": this.fromDate.formatted,
            "toDate": this.toDate.formatted
        }

        console.log("reqBody --", reqBody);

        let headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(`${AppConstants.get_trip_status_analyser_data}`, reqBody, '', reqOptions).then(tripSheetObj => {
            // console.log(JSON.stringify(tripSheetObj))
            // alert('Calling')
            this.loading = false;
            // console.log(JSON.stringify(tripSheetObj.tripStatusAnalyserData)) 
            if (tripSheetObj.length !== 0) {
                tripSheetObj.forEach(findDestinationObj => {
                    if (findDestinationObj.destIdList.length !== 0) {
                        findDestinationObj.destIdList.forEach(async detinationObj => {
                            findDestinationObj.tripdestName = await detinationObj.destName;
                            findDestinationObj.tripTotalTravellDistance = await detinationObj.totalTravellDistance;
                           

                            findDestinationObj.tripTotaltotalTravelDays = await detinationObj.totalTravelDays;
                            findDestinationObj.tripdestLocation = await detinationObj.destLocation;
                            findDestinationObj.lrDateAndTime = await new Date(findDestinationObj.lrDateAndTime).toLocaleDateString();
                            findDestinationObj.expectedReachDate = await new Date(findDestinationObj.expectedReachDate).toLocaleDateString();
                            findDestinationObj.tripDestLocation = await detinationObj.destLocation;
                            findDestinationObj.driverAndOwnerContact = await findDestinationObj.driverContactNo + `${' - '}` + findDestinationObj.ownerContactNo;

                            findDestinationObj.closeTripDate = "";
                            if (findDestinationObj.actualReachDate) {
                                // findDestinationObj.delayedBy =  await this.daysBetweenTwoDaye(findDestinationObj.actualReachDate, findDestinationObj.expectedReachDate)
                                findDestinationObj.delayedBy = await Math.round(findDestinationObj.daysDelayed)
                                findDestinationObj.reachedDate = await new Date(findDestinationObj.actualReachDate).toLocaleDateString();
                            }
                        })
                        if (parseInt(findDestinationObj.alertLevel) == 1) {
                            this.allWell = ++this.allWell;
                        }
                        if (parseInt(findDestinationObj.alertLevel) === 2) {
                            this.mediuIssues = ++this.mediuIssues;
                        }
                        if (parseInt(findDestinationObj.alertLevel) === 3) {
                            this.delyedTrips = ++this.delyedTrips;
                        }
                        if (parseInt(findDestinationObj.alertLevel) === 4) {
                            this.gpsDelyedHR = ++this.gpsDelyedHR;
                        }
                        if (parseInt(findDestinationObj.alertLevel) === 5) {
                            this.tripCompleted = ++this.tripCompleted;
                        }
                    }
                })
            }

            this.centralizedLRObject = tripSheetObj;
            this.isPdfNotAvailable = false;
            console.log('==tripSheetObj====>', JSON.stringify(tripSheetObj))
            if(tripSheetObj.length ===0){
                this.isPdfNotAvailable = true;
                Swal.fire({
                    title: 'Livepin',
                    text: 'Please Select Other Date!',
                    imageUrl: '../../assets/statusImg/no_recordFond.png',
                    imageWidth: 400,
                    imageHeight: 200,
                    imageAlt: 'Today',
                    animation: false
                  })
            }


        }).catch((tripSheetObjErr) => {
            this.loading = false;
            console.log("getAllLRlocationErr...", JSON.stringify(tripSheetObjErr));
        });
    }


    filterStatus(event) {
        console.log(event)
        let data = this.centralizedLRObject;
        // this.centralizedLRObject = data.filter(item => {
        //   if(item.alertLevel !==null){
        //     console.log("YEs");
        //    return item.alertLevel == event 
        //   } 
        // })
        this.centralizedLRObject = data.filter(item => parseInt(item.alertLevel) === parseInt(event))
        this.centralizedLRObject.forEach(element => {
            if (parseInt(element.alertLevel) === parseInt(event)) {
                console.log(element)
            }
        })
        console.log(this.centralizedLRObject)
    }
    gpsdevice() {
        let path = `${AppConstants.get_gpsdevice}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then(device => {
            // console.log('gpsdevice', JSON.stringify(device))
            device.gpsDevices.forEach(vehical => {
                this.asignedGpsDevice.push(vehical.vehicleNumber)
            })

        }).catch((gpsDeviceerror) => {
            console.log("get gpsdevice Error...", JSON.stringify(gpsDeviceerror));
        });
    }
    doSelectOptions(event) {
        // console.log('doSelectOptions',event)
    }
    doRemove(event) {
        // console.log('doRemove',event)
    }
    doSelectVehical(event) {
        // console.log('doSelectVehical',event)
        this.getTripStatusForVehicleNumber(event)
    }
    getTripStatusForVehicleNumber(vehicalNo) {
        // let headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        // let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_trip_status_for_vehicle_number + vehicalNo, '', '').then(singaleVeicalObj => {
            console.log('singaleVeicalObj', JSON.stringify(singaleVeicalObj))
            this.singaleVeicalArrayObj = singaleVeicalObj;
        }).catch((singaleVeicalObjErr) => {
            console.log("getsingaleVeicalObjErr...", JSON.stringify(singaleVeicalObjErr));
        });
    }
    getFormattedDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;
    }
    onClickCompletTrip(data) {
        if (data.closeTripDate.length === 0) {
            alert("Please Select Trip Compleation Date!")
            return;
        }
        console.log("calling");
        jQuery('#completeTripModal').modal('hide');
        this.updateTripStatusAnalyserData(data.closeTripDate.jsdate, data._id)
        // console.log('data.closeTripDate',JSON.stringify(data));
    }
    updateTripStatusAnalyserData(reachDate, tripStatusId) {
        this.loading = true;
        let reqBody = {
            "actualReachDate": reachDate,
            "_id": tripStatusId
        }
        console.log("reqBody for updateTripStatusAnalyserData --", reqBody);

        let headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(`${AppConstants.update_trip_status_analyser_data}`, reqBody, '', reqOptions).then(updateTripStatus => {
            this.loading = false;
            console.log('updateTripStatus', updateTripStatus);
            this.getTripSheetServiceObj();
        }).catch((tripSheetObjErr) => {
            this.loading = false;
            console.log("updateTripStatusAnalyserData...", JSON.stringify(tripSheetObjErr));
        });
    }
    daysBetweenTwoDaye(date1, date2) {

        // The number of milliseconds in one day
        const ONE_DAY = 1000 * 60 * 60 * 24;

        // Calculate the difference in milliseconds
        this.expectedReachDate = new Date(date1)
        this.actualReachDate = new Date(date2)
        var differenceMs = Math.abs(this.actualReachDate - this.expectedReachDate);

        //  console.log(this.expectedReachDate)
        //  console.log(this.actualReachDate)
        //  console.log(differenceMs)
        //  console.log(Math.round(differenceMs / ONE_DAY))

        // Convert back to days and return
        return Math.round(differenceMs / ONE_DAY);

    }
    openCompleteTripModal(data) {
        this.rowData = data;
        jQuery('#completeTripModal').modal('show');
        console.log(data, jQuery('#completeTripModal'))
    }
    onClearClick() {
        this.fromDate = null;
        this.toDate = null;
        this.isPdfNotAvailable = true;
        this.centralizedLRObject = [];
    }
}
