
import { Component, OnInit, ElementRef, DoCheck } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import $ from 'jquery'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    togaleButtonHide = true;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private hidebutton: boolean = true;
    login: any;
    landing: any;

    constructor(public location: Location, private router: Router, private element: ElementRef, private route: ActivatedRoute) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        let params: any = this.router.url
        let url = window.location.href;
        // console.log('URL:' + window.location.href);
        // console.log('Path:' + window.location.pathname);
        // console.log('Host:' + window.location.host);
        // console.log('Hostname:' + window.location.hostname);
        // console.log('Origin:' + window.location.origin);
        // console.log('Port:' + window.location.port);
        // console.log('Search String:' + window.location.search);

        // console.log(this.route.snapshot.params['login']); // works first time only
        /** For later use, updates everytime you change route*/
        // this.route.params.subscribe((parmas) => { console.log(params['login']) });


        // let lastPart = url.split("/").pop();
        // if(lastPart == 'login'){
        //     alert("hi am login");
        //     this.hidebutton= false;
        // }else if(lastPart == 'landing'){
        //     alert("hi am landing");
        //     this.hidebutton= false;
        // }  else {
        //     alert("i m sidebar")
        //     this.hidebutton= true;
        // }

        //console.log(lastPart)

        if (localStorage.getItem("userId")) {
            // console.log("hiiiiiiiiiiiiii ============");

        }
    }

    ngDoCheck() {
    const url = this.router.url;
    const spliting = url.lastIndexOf('/');
    const result = url.substring(spliting + 1);
    // console.log(' URL results');
    // console.log(result);
    if (result === 'landing') {
      return this.togaleButtonHide = false;
    } if (result === 'login') {
      return this.togaleButtonHide = false;
    } else{
        return this.togaleButtonHide = true;
    }

  }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === '/home') {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '/documentation') {
            return true;
        }
        else {
            return false;
        }
    }

    onNavBarClick(index) {

        this.sidebarClose();
        switch (index) {
            case 0:
                //home
                this.router.navigate(['/landing']);
                $('html, body').animate({ scrollTop: '0px' }, 300);
                break;
            case 1:
                //products
                this.router.navigate(['/landing']);
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#product").offset().top
                    }, 1000);
                }, 100)
                break;
            case 2:
                //aboutus
                this.router.navigate(['/landing']);
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#aboutus").offset().top
                    }, 1000);
                }, 100)
                break;
            case 3:
                //contact
                this.router.navigate(['/landing']);
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#contactus").offset().top
                    }, 1000);
                }, 100)
                break;
            case 4:
                //login
                this.router.navigate(['/login']);
                break;
        }
    }
}
