import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';


@Component({
    selector: 'app-fuelSummaryReports',
    templateUrl: './fuelSummaryReports.component.html',
    styleUrls: ['./fuelSummaryReports.component.scss']
})

export class FuelSummaryReports implements OnInit {

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    dashboardData: any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getVehicleList();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
        })
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {
        //console.log('this.dashboard22222',this.dashboardData );
        // let body = [];
        // body.push(this.dashboardData);
        // console.log("this.dashboardData ===>>>" + JSON.stringify(this.dashboardData));
        let value = [];

            this.dashboardData.recordList.forEach(fuel => {
                let subValueArray = [];
                subValueArray.push(fuel.historyDate);
                subValueArray.push(fuel.distanceTravelled);
                subValueArray.push(fuel.runTime);
                subValueArray.push(fuel.startingFuelContent.toFixed(2));
                subValueArray.push(fuel.fuelRefulled.toFixed(2));
                subValueArray.push(fuel.fuelConsumed.toFixed(2));
                subValueArray.push(fuel.presentFuelContent.toFixed(2));
                value.push(subValueArray);
            });

        let headerName = "Fuel Summary Report (" + this.fromDate.formatted + " to " + this.toDate.formatted + ")";
        let body = {
            "headerName": headerName,
            "excelName": "fuelSummaryReport",
            "header": [
                "Date (M/D/Y)",
                "DistanceTravelled",
                "RUNTIME",
                "DieselAtStart",
                "DieselRefueled",
                "DieselConsumed",
                "DieselRemained"
            ],
            "value": value
        }
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        let dashBoardParams = {
            imeiNumber: this.selectedVehicle.imeiNumber,
            toDate: this.toDate.formatted,
            fromDate: this.fromDate.formatted
        }

        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.get_diesel_data_for_report, dashBoardParams, "", reqOptions).then((dashboardData) => {
            // console.log("dashboarddata..............", JSON.stringify(dashboardData));
            this.isPdfNotAvailable = false;
            this.loading = false;
            if (dashboardData && dashboardData.statusCode === 200) {
                this.dashboardData = dashboardData;
                this.dashboardData.recordList.forEach(element => {
                    let dateTimeStamp = new Date(element.historyDate).getTime();
                    element.historyTimeStamp = dateTimeStamp;
                });
                this.dashboardData.recordList.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
            }
        }).catch((dashboardErr) => {
            this.loading = false;
            // console.log("dashboardErr...", JSON.stringify(dashboardErr));
        });
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/genericxls', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange(device) {
        this.selectedVehicle = device;
        this.getTableData();
    }

}
