import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../base/http.service';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { RegexValidations } from '../base/regexvalidations';
import { AppConstants } from '../base/appconstants';
import jwt from 'jsonwebtoken';
import { Router } from '@angular/router';
import { Observable } from '../../../node_modules/rxjs';
import {SidebarComponent} from '../shared/sidebar/sidebar.component'

declare var window: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  showLogin: boolean = false;
  credentials = {};
  authValidationMsg: string;
  showAuthValidationMsg: boolean = false;
  loginForm: FormGroup;
  showErrorMessageOfForm: boolean = false;
  mandatoryFieldsMsg: string;
  deviceRegistrationId: any;
  loading = false;
  usernamePassError = false;


  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private regexValidator: RegexValidations, private router: Router,
    private calSideBar: SidebarComponent) {

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      mobileNumber: ['', Validators.compose([
        Validators.required, this.regexValidator.validateMobileNumber])],
      password: ['', Validators.compose([
        Validators.required])]
    });

    this.loginForm.valueChanges.subscribe(data => this.onValueChanged(data));
  }

  loginFormSubmit(loginFormValue: any) {
    this.usernamePassError = false;
    let mobileControl = this.loginForm.get('mobileNumber');
    if (!mobileControl.valid) {
      this.isMobileNumberValidOnSubmit(mobileControl);
    } else {
      let reqBody = {
        mobileNumber: loginFormValue.mobileNumber,
        password: loginFormValue.password,
        deviceRegistrationId: this.deviceRegistrationId
      }

      this.loading = true;
      this.httpService.httpPostPromise(AppConstants.passport_public_login, reqBody, "")
        .then(loginSuccessResponse => {
          this.loading = false;
          // console.log("----->>>x" + JSON.stringify(loginSuccessResponse));
          if (loginSuccessResponse.statusCode == 200) {
            localStorage.setItem('assignedRole', JSON.stringify(loginSuccessResponse.assignedRole));
            localStorage.setItem('userToken', loginSuccessResponse.jwtToken);
            localStorage.setItem('userId', loginSuccessResponse._id);
            localStorage.setItem('userMobile', loginSuccessResponse.mobileNumber);
            localStorage.setItem('userLocation', JSON.stringify(loginSuccessResponse.userLocation));
            let userToken = loginSuccessResponse.jwtToken;
            let decodedUserData = jwt.decode(userToken, { complete: true });
            let userData = decodedUserData.payload;
            // console.log("ussser Data" + JSON.stringify(userData));
            delete userData.iat;
            delete loginSuccessResponse.jwtToken;
            let firstIndexOfRoutingNavigation = loginSuccessResponse.assignedRole.reports[0];
            // console.log('firstIndexOfRoutingNavigation', firstIndexOfRoutingNavigation);
             this.router.navigate([`/${firstIndexOfRoutingNavigation}`]);
             this.calSideBar.ngOnInit();
            // this.router.navigate(['/sideBarHome']);
          }

        }).catch(loginError => {
          this.loading = false;
          this.usernamePassError = true;
          let toastMessage;
          let localLoginError = JSON.parse(loginError._body);
          if (localLoginError.statusMessage.code == 1001) {
            toastMessage = localLoginError.statusMessage.message;
          }
          else if (localLoginError.statusMessage.code == 1000) {
            toastMessage = localLoginError.statusMessage.message;
          }
          else if (localLoginError.statusMessage.code == 1011) {
            toastMessage = localLoginError.statusMessage.message;
          }
          else {
            toastMessage = "Client Side Error";
          }
          /*  let toast = this.toastCtrl.create({
             message: toastMessage,
             duration: 2000
           });
           toast.present(); */

          //////////////////>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>///////////////////////////
        });

    }

  }

  onValueChanged(data?: any) {
    if (!this.loginForm) { return; }
    const form = this.loginForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)


      this.formErrors[field] = new Array();
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {

        const messages = this.validationMessages[field];
        for (const key in control.errors) {

          if (/*control.value == '' && */key == 'invalidMobileNumber') {
            // don't add message

            // //   this.formErrors[field] += messages[key] + ' ';
            // this.formErrors[field].push(messages[key]);
          }
          else {
            //   this.formErrors[field] += messages[key] + ' ';
            this.formErrors[field].push(messages[key]);
          }
        }
      }
    }
  }

  isMobileNumberValidOnSubmit(control: AbstractControl) {
    for (const key in control.errors) {
      if (this.formErrors.mobileNumber.length == 0 && key == 'invalidMobileNumber') {
        this.formErrors.mobileNumber.push(this.validationMessages.mobileNumber.invalidMobileNumber);
      }
    }
  }

  formErrors = {
    'mobileNumber': new Array(),
    'password': new Array()
  };

  validationMessages = {
    'mobileNumber': {
      'required': 'Mobile Number is required.',
      'invalidMobileNumber': 'Please enter valid Mobile Number'
    },
    'password': {
      'required': 'Password is required.'
    }
  };

  ngAfterViewInit() {
  }

  toggleLoginView() {
    this.showLogin = !this.showLogin;
  }

}
