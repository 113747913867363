import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})

export class Contacts implements OnInit {

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;

    cols: any[];
    sortF: any;

    contacts = [
        {
            'name': 'HarishKumarReddy',
            'contact': '9505276485',
            'designition': 'DM&G',
            'locationType': 'sandDump',
            'location': 'Gudivada',
        },
        {
            'name': 'KundanKumar',
            'contact': '8789946785',
            'designition': 'Head of Dept',
            'locationType': 'sandReach',
            'location': 'Srikakulam',
        },
        {
            'name': 'VinothKumar',
            'contact': '9738283334',
            'designition': 'Asst.Head of Dept',
            'locationType': 'sandReach',
            'location': 'Muggala Village, East Godavari(Dist)',
        },
        {
            'name': 'Venkat',
            'contact': '9505276485',
            'designition': 'SuperVisor',
            'locationType': 'sandReach',
            'location': 'Pedapulipaka Village, Krishna(dist)',
        },
        {
            'name': 'Mukesh',
            'contact': '9505276485',
            'designition': 'SuperVisor',
            'locationType': 'sandDump',
            'location': 'Guntur',
        },
        {
            'name': 'Srinvas GD',
            'contact': '9505276485',
            'designition': 'PO',
            'locationType': 'sandDump',
            'location': 'Vijayawada',
        }
        ,
        {
            'name': 'Rajesh MA',
            'contact': '9505276485',
            'designition': 'PO',
            'locationType': 'sandDump',
            'location': 'Tirupati',
        },
        {
            'name': 'AnilKumar',
            'contact': '9505276485',
            'designition': 'Admin',
            'locationType': 'sandDump',
            'location': 'Kadapa',
        },
        {
            'name': 'SriknathReddy',
            'contact': '9505276485',
            'designition': 'Admin',
            'locationType': 'sandReach',
            'location': 'Nellore',
        },
        {
            'name': 'AvinashReddy',
            'contact': '9505276485',
            'designition': 'Admin',
            'locationType': 'sandDump',
            'location': 'Gudivada',
        }
    ]

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    dashboardData: any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {
        this.cols = [
            { field: 'name', header: 'PersonName' },
            { field: 'designition', header: 'Designtion' },
            { field: 'contact', header: 'ContactNumber' },
            { field: 'locationType', header: 'LocationType' },
            { field: 'location', header: 'Location' },
        ];
        // this.userToken = localStorage.getItem("userToken");
        // this.userId = localStorage.getItem("userId");
        // this.getVehicleList();
    }

    // getVehicleList() {
    //     let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
    //     let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    //     let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    //     this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
    //         this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
    //         this.selectedVehicle = this.gpsDevicesArray[0];
    //         // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
    //     })
    // }

    // onDateChanged(event: IMyDateModel) {


    //     if (event && event.jsdate) {
    //         let d: Date = new Date(event.jsdate.getTime());
    //         d.setDate(d.getDate() - 1);
    //         let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
    //         copy.disableUntil = {
    //             year: d.getFullYear(),
    //             month: d.getMonth() + 1,
    //             day: d.getDate()
    //         };
    //         this.myDatePickerOptions2 = copy;
    //     }
    // }

    // getCopyOfEndDateOptions(): IMyDpOptions {
    //     return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    // }


    // convert() {
    //     let body = [];
    //     body.push(this.dashboardData);
    //     this.downloadExcelSheet(body).subscribe(blob => {
    //         // Doing it this way allows you to name the file
    //         var link = document.createElement('a');
    //         link.href = window.URL.createObjectURL(blob);
    //         link.download = "Report.xlsx";
    //         link.click();
    //     }, error => console.log("Error downloading the file."),
    //         () => console.log('Completed file download.'));
    // }

    // onClearClick() {
    //     this.isPdfNotAvailable = true;
    //     this.fromDate = null;
    //     this.toDate = null;
    //     this.isSubmitClick = false;
    //     this.vehicleDetails = null;
    // }

    // todaysDate() {
    //     var d = new Date(),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;

    //     return [year, month, day].join('-');
    // }

    // getTableData() {

    //     if (!(this.fromDate && this.fromDate.formatted)) {
    //         alert('Please select From Date first');
    //         return;
    //     }

    //     if (!(this.toDate && this.toDate.formatted)) {
    //         alert('Please select From Date first');
    //         return;
    //     }

    //     this.isSubmitClick = true;
    //     this.isPdfNotAvailable = true;
    //     this.loading = true;

    //     let dashBoardParams = {
    //         imeiNumber: this.selectedVehicle.imeiNumber,
    //         toDate: this.toDate.formatted,
    //         fromDate: this.fromDate.formatted
    //     }

    //     let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    //     let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    //     this.httpService.httpPostPromise(AppConstants.get_dashboard_data_for_report, dashBoardParams, "", reqOptions).then((dashboardData) => {
    //         // console.log("dashboarddata..............", JSON.stringify(dashboardData));
    //         this.isPdfNotAvailable = false;
    //         this.loading = false;
    //         if (dashboardData && dashboardData.statusCode === 200) {
    //             this.dashboardData = dashboardData;
    //             this.dashboardData.recordList.forEach(element => {
    //                 let dateTimeStamp = new Date(element.historyDate).getTime();
    //                 element.historyTimeStamp = dateTimeStamp;
    //             });
    //             this.dashboardData.recordList.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
    //         }
    //     }).catch((dashboardErr) => {
    //         this.loading = false;
    //         // console.log("dashboardErr...", JSON.stringify(dashboardErr));
    //     });
    // }

    // downloadExcelSheet(reqBody): Observable<Object[]> {
    //     this.loading = true;
    //     return Observable.create(observer => {
    //         let xhr = new XMLHttpRequest();
    //         xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/dailysummaryreport', true);
    //         xhr.setRequestHeader('Content-type', 'application/json');
    //         xhr.responseType = 'blob';

    //         xhr.onreadystatechange = () => {
    //             this.loading = false;
    //             if (xhr.readyState === 4) {
    //                 if (xhr.status === 200) {

    //                     var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    //                     var blob = new Blob([xhr.response], { type: contentType });
    //                     observer.next(blob);
    //                     observer.complete();
    //                 } else {
    //                     observer.error(xhr.response);
    //                 }
    //             }
    //         }
    //         xhr.send(JSON.stringify(reqBody));

    //     });
    // }

    // onVehicleNumberChange(device) {
    //     this.selectedVehicle = device;
    //     this.getTableData();
    // }

}
