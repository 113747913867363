import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
import { SelectItem } from 'primeng/primeng';

import * as $AB from 'jquery';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import * as jquery from 'jquery';
import { Observable } from 'rxjs';
declare var google: any;
declare var jQuery:any;
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

declare var jquery: any;   // not required
declare var $: any;   // not required

import { Headers, RequestOptions } from '@angular/http';


@Component({
    selector: 'app-lorryReceiptCreation',
    templateUrl: './lorryReceiptCreation.component.html',
    styleUrls: ['./lorryReceiptCreation.component.scss'],


})


export class LorryReceiptCreation implements OnInit {
    lrCreationForm: FormGroup;
    statusForm: FormGroup;
    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    private value:any = {};
    dataModel;
    cols: any[];

    sourceList = []
    destinationList = []
    sourceListObj = []
    destinationListObj = []
    selectedVehicalObj
    selectedSource
    //selectedDestination;
    selectedDestination = []
    lrReportStatus
    status : SelectItem[];
    selectedStatus: any;
    lrRecordUpdateStatus:any;
    UpdateselectedVehical;
    UpdateselectedSource;
    UpdateselectedDestination ;
    tableValue = [];
    removeEndedTripFromTable = [];
    seletedTest;
    lrId;
    map: any;
    @ViewChild("mapRef") mapRef: ElementRef;
    markersArray: any = [];
    directionsDisplay: any;
    grossWeight
    tareweightInput
    printLaryReceiptData
    selectedVehicleNumberForUpdate;
    selectedSourceForUpdate;
    selectedDestinationForUpdate
    dateUpdatetoDBnotSelected;
    updateDestinationList= [];
    netValue;


    obj;
    LeadTimeRemaining = "red"

    testList;
    public ngxValue: any = [];




    StatusCheck = [{value:"Proggress", selected:0},
    {value:"Reached", selected:1},
    {value:"Return", selected:1},];
    StatusType: any = [{ 'name': 'Please Select', 'value': -1 },
    { 'name': 'Proggress', 'value': 0 },
    { 'name': 'Reached', 'value': 1 },
    { 'name': 'Return', 'value': 2 },
    { 'name': 'End', 'value': 3 }];

    public typed(value:any) : void { let el:HTMLElement = document.getElementsByClassName("ui-select-choices dropdown-menu")[0] as HTMLElement; if (typeof el !== 'undefined') { el.style.display = 'block'; } }

    config = {
        displayKey:"description", //if objects array passed which key to be displayed defaults to description
        search:true, //true/false for the search functionlity defaults to false,
        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
        customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
        // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'No results found!' ,// text to be displayed when no items are found while searching
        searchPlaceholder:'Search', // label thats displayed in search input,
        searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public lrDate: any;
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    selectedVehicleNumber: any;
    dashboardData: any;
    vehicalNo = [];
    setForUpdateTrackNo: any;
    setForUpdateSource:any;
    setForUpdateDestination:any;
    userMobile: any

    constructor(private httpService: HttpService,
        private urlFormatter: URLStringFormatter,
        private formBuilder: FormBuilder,) {

    }

    LorryReportCreationForm() {
        this.lrCreationForm = this.formBuilder.group({
            lrNo: [''],
            consignor: [''],
            consignee: [''],
            noOfPackages: [''],
            natureOfGoods: [''],
            amount: [''],
            rate: [' '],
            quantity: [' '],
            currentStatus: 0,
            pan: [''],
            date: [''],
            truckNo: [''],
            invoiceNo: [''],
            wayBillNo: [''],
            freight: [''],
            advance: [''],
            stationaryCharges: [''],
            totalAmount: [''],
            destination: [''],
            source: [''],
            lorryHandlingCharges: [''],
            valueDeclared: [''],
            grossWeight:[''],
            tareweight:[''],
            netWeight:[''],
            bankName:[''],
            accountName:[''],
            accountNumber:[''],
            ifsCode:[''],
        });
    }


    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobile = localStorage.getItem("userMobile");
        this.getVehicleList();
        this.getVehicalNo();
        this.LorryReportCreationForm();
        this.getAllLRLocations();
        this.getAllLRRecords();
        this.outsdiedCaptureEvent();
        this.statusForm = this.formBuilder.group({
            lrCreationstatus:''
        })

        this.cols = [
            {field: 'lrNo', header: 'lR No'},
            {field: 'vehicalNo', header: 'Vehical No'},
            {field: 'sourceName', header: 'Source'},
            {field: 'destinationName', header: 'Destination'},
            {field: 'statusInfo', header: 'Info'}
        ];

        this.status = [
            { label: 'Select', value: 'Select' },
            { label: 'Progess', value: 'Progess' },
            { label: 'Reached', value: 'Reached'},
            { label: 'Return', value: 'Return'},
            { label: 'End', value: 'End' }
        ];
        //this.showMap()
    }


    outsdiedCaptureEvent(){
        jQuery("#onLRUpdateModel").on("hidden.bs.modal", () =>{
            this.resetLRCreationForms();
        });
    }



    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            //console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
            this.gpsDevicesArray.forEach(Element=>{
                this.vehicalNo.push(Element.vehicleNumber);
            })
            this.selectedVehicleNumber = this.gpsDevicesArray[0].vehicleNumber;
        })
    }
    getVehicalNo(){
        // alert();
        //  this.gpsDevicesArray.forEach(Element=>{
        //     console.log("yes");
        //     console.log(JSON.stringify(Element));
        // });
    }
    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {
        let body = [];
        body.push(this.dashboardData);
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }
        const fromDate = new Date( this.fromDate.formatted).toISOString(); 
        const toDate = new Date( this.toDate.formatted).toISOString(); 
        const newTableValue  =  this.tableValue.sort(function(a,b){return a.date() - b.getTime()});
        console.log( fromDate , toDate)

        console.log( JSON.stringify(newTableValue));

        // this.isSubmitClick = true;
        // this.isPdfNotAvailable = true;
        // this.loading = true;

        // let dashBoardParams = {
        //     imeiNumber: this.selectedVehicle.imeiNumber,
        //     toDate: this.toDate.formatted,
        //     fromDate: this.fromDate.formatted
        // }

        // let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        // let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        // this.httpService.httpPostPromise(AppConstants.get_dashboard_data_for_report, dashBoardParams, "", reqOptions).then((dashboardData) => {
        //      // console.log("dashboarddata..............", JSON.stringify(dashboardData));
        //     this.isPdfNotAvailable = false;
        //     this.loading = false;
        //     if (dashboardData && dashboardData.statusCode === 200) {
        //         this.dashboardData = dashboardData;
        //         this.dashboardData.recordList.forEach(element => {
        //             let dateTimeStamp = new Date(element.historyDate).getTime();
        //             element.historyTimeStamp = dateTimeStamp;
        //         });
        //         this.dashboardData.recordList.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
        //     }
        // }).catch((dashboardErr) => {
        //     this.loading = false;
        // });
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/dailysummaryreport', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange(device) {
        console.log('device====>',device);
        this.selectedVehicle = device;
    }

    public inputTyped = (source: string, text: string) =>{
         // console.log('LorryReceiptComponent.inputTyped', source, text);
    }

    public doFocus = () => {
        // console.log('LorryReceiptComponent.doFocus');
    }

    public doBlur = () => {
        // console.log('LorryReceiptComponent.doBlur');
    }

    public doOpen = () => {
        // console.log('LorryReceiptComponent.doOpen');
    }

    public doClose = () => {
        // console.log('LorryReceiptComponent.doClose');
    }

    public doSelect = (value: any) => {
        // console.log('LorryReceiptComponent.doSelect', value);
    }

    public doRemove = (value: any) =>{
        console.log('LorryReceiptComponent.doRemove', value);
        const remove = this.removeDestinatinName(value)
        console.log('remove===>', remove);

   }
   public doRemoveUpdateDetination = (value: any)=>{
       console.log('UpdateDetinoation .doRemove  ', value);
        console.log( 'Before',this.UpdateselectedDestination);
        const remove = this.removeUpdateDestinatinName(value)
        console.log('remove UpdateDetinoation===>', remove);
        console.log( 'After' ,this.UpdateselectedDestination);
   }

    public doSelectOptions = (options) => {
        // console.log('LorryReceiptComponent.doSelectOptions', options);
    }


    public doSelectVehical = (value: any) => {
        this.selectedVehicalObj = this.getvehicalNoObjFilter(value)
    }

    public doSelectSource = (value: any) => {
        this.selectedSource = this.getSoucreObjFilter(value)

    }
    public doSelectDestination = (value: any) => {

        //this.selectedDestination = this.getDestinationFilter(value)
        this.selectedDestination.push( this.getDestinationFilter(value)[0])
        console.log('this.selectedDestination======>',this.selectedDestination);
     }

    getSoucreObjFilter(nameProperty) {
        return this.sourceListObj.filter(name => name.placeName === nameProperty)
    }
    getDestinationFilter(destnameProperty) {
        return this.destinationListObj.filter(name => name.placeName === destnameProperty)
    }
    getvehicalNoObjFilter(vehicalObj) {
        return this.gpsDevicesArray.filter(number => number.vehicleNumber === vehicalObj)
    }

    removeDestinatinName(destinationName){
        // return this.selectedDestination.filter(el=> { el.placeName != destinationName });
       return   this.selectedDestination.splice( this.selectedDestination.findIndex(item => item.placeName === destinationName), 1)
    } 
    removeUpdateDestinatinName(destinationName){
       // return this.UpdateselectedDestination.filter(e => e !== destinationName);
        // return this.selectedDestination.filter(el=> { el.placeName != destinationName });
       return  this.UpdateselectedDestination.splice(this.UpdateselectedDestination.findIndex(item => item === destinationName), 1)
    }

    onLRCrationSubmit(){
        console.log('this.selectedVehicalObj ==', this.selectedVehicalObj)
        console.log('this.this.selectedSource ==' ,this.selectedSource)
        console.log('this.selectedDestination ==' ,this.selectedDestination)
        if(this.lrDate === undefined || this.lrDate === null ){
            return alert("Please Date of Lorry Report Creation")
        } else{

            const date =this.lrDate.formatted;
            const dbDate = new Date(date).toISOString();
            console.log('dbDatedbDatedbDatedbDatedbDatedbDate',dbDate)
            this.lrCreationForm.get('date').setValue(dbDate);
        }
        if(this.selectedVehicalObj === undefined){
            return alert("Please Select Vehical No.")
        } else {
            const vehicalNo = this.selectedVehicalObj[0];
            this.lrCreationForm.get('truckNo').setValue(vehicalNo);
        }
        if(this.selectedSource === undefined){
            return alert("Please Select Source Name.")
        } else {
            const source = this.selectedSource[0];
            this.lrCreationForm.get('source').setValue(source);
        }
        if(this.selectedDestination === undefined){
            return alert("Please Select Destination Name.")
        } else{
            const detination = this.selectedDestination;
            this.lrCreationForm.get('destination').setValue(detination);

        }

        this.lrCreationForm.get('netWeight').setValue(this.netValue);
        console.log('this.lrCreationForm.value');
        console.log(this.lrCreationForm.value);

        this.createLRRecords(this.lrCreationForm.value)
        //jQuery('#lrCreationModel').modal('hide');
    }

    getAllLRLocations(){
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_all_lr_locations, '', reqOptions).then(getAllLRlocation =>{
            // console.log('getAllLRlocation',JSON.stringify(getAllLRlocation));
            const allLRLocationList = getAllLRlocation.lRLocationData;

            allLRLocationList.forEach(location=>{
                if(location.placeType === 0){
                    this.sourceList.push(location.placeName);
                    this.sourceListObj.push(location);
                }else{
                    this.destinationList.push(location.placeName);
                    this.destinationListObj.push(location);
                }
            })

        }) .catch((getAllLRlocationErr) => {
            this.loading = false;
            console.log("getAllLRlocationErr...", JSON.stringify(getAllLRlocationErr));
        });

    }
    createLRRecords(lrCreatedObj){
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_create_lr_records, lrCreatedObj, '', reqOptions).then((dashboardData) => {
            this.loading = false;
            this.getAllLRRecords();
            jQuery('#lrCreationModel').modal('hide');
        }).catch(err=>{
            console.log("LR Creation Error");
            jQuery('#lrCreationModel').modal('hide');
        }).catch(err=>{
            console.log(err);
            this.loading = false;
        });
    }
    getAllLRRecords(){
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_all_lr_records, '', reqOptions).then(getAlllrRecords =>{
            const allLRRecordsList = getAlllrRecords.lRRecordsData;
            this.loading = false;
            // console.log("getAllLRRecords",JSON.stringify(getAlllrRecords));
            allLRRecordsList.forEach(recodrd=>{


                const sourceNames = recodrd.source;
                const allname = []
                recodrd.destination.forEach(Element=>{
                    allname.push(Element.placeName)
                })

                recodrd.destinationName = allname.join();
                const vehicalNos = recodrd.truckNo;

                recodrd.sourceName = sourceNames.placeName;
                recodrd.vehicalNo = vehicalNos.vehicleNumber;

                if(recodrd.currentStatus===0 || recodrd.currentStatus === null){
                    recodrd.statusInfo = 'Progress'
                }else if(recodrd.currentStatus===1){
                    recodrd.statusInfo = 'Reached'
                }else if(recodrd.currentStatus===2){
                    recodrd.statusInfo = 'Return'
                }else if(recodrd.currentStatus===3){

                     recodrd.statusInfo = 'End'
                }
                if(recodrd.currentStatus !=3){
                    this.removeEndedTripFromTable.push(recodrd)
                }

            });

            this.tableValue = allLRRecordsList.filter(function(el) { return el.statusInfo != "End"; });
              // console.log("getAllLRRecords==>",JSON.stringify( this.tableValue));

        }) .catch((getAlllrRecordsError) => {
            this.loading = false;
            console.log("getAlllrRecordsError...", getAlllrRecordsError);
        });
    }
   onLrReportStatusChange(e){

    console.log(e);
    if(e === 'Progess'){
        const updatedStateObj = this.lrRecordUpdateStatus[0]

        this.updateCurrentStatusLRRecords(updatedStateObj._id, 0);

    }else  if(e === 'Reached'){
        const updatedStateObj = this.lrRecordUpdateStatus[0]

        this.updateCurrentStatusLRRecords(updatedStateObj._id, 1);
    }else  if(e === 'Return'){
        console.log(this.lrRecordUpdateStatus);
        const updatedStateObj = this.lrRecordUpdateStatus[0]
        this.updateCurrentStatusLRRecords(updatedStateObj._id, 2);
    }
    else  if(e === 'End'){
        const updatedStateObj = this.lrRecordUpdateStatus[0]
        this.updateCurrentStatusLRRecords(updatedStateObj._id, 3);
    }

    }
    valueChanged(data){
        console.log(data);
        const dataObj = []
        dataObj.push(data);
        this.lrRecordUpdateStatus = this.removeDuplicates(dataObj);
    }

    removeDuplicates(array) {
        let uniq = [];
        return array.filter(obj => !uniq[obj._id] && (uniq[obj._id] = true))
    }

    onLRRecordsEdit(data){
        console.log('datadata=========>',data);
       this.lrId = data._id;
       this.dateUpdatetoDBnotSelected = data.date;
        const dateObj = {year: 0, month: 0, day: 0}
        const dbDate = data.date;
        dateObj.year = new Date(dbDate).getFullYear();
        dateObj.month = new Date(dbDate).getMonth()+1;
        dateObj.day = new Date(dbDate).getDate();
        // console.log('dateObj=====>',dateObj);
        this.lrDate = {date:dateObj};
       this.selectedVehicleNumberForUpdate = data.vehicalNo
       this.selectedSourceForUpdate = data.sourceName
       this.selectedDestinationForUpdate =  data.destinationName.split(',');
       this.UpdateselectedVehical = data.vehicalNo
       this.UpdateselectedSource = data.sourceName
       this.UpdateselectedDestination = data.destinationName.split(',');



        this.lrCreationForm.get('lrNo').setValue(data.lrNo);
        this.lrCreationForm.get('consignor').setValue(data.consignor);
        this.lrCreationForm.get('consignee').setValue(data.consignee);
        this.lrCreationForm.get('noOfPackages').setValue(data.noOfPackages);
        this.lrCreationForm.get('natureOfGoods').setValue(data.natureOfGoods);
        this.lrCreationForm.get('amount').setValue(data.amount);
        this.lrCreationForm.get('rate').setValue(data.rate);
        this.lrCreationForm.get('quantity').setValue(data.quantity);
        this.lrCreationForm.get('currentStatus').setValue(data.currentStatus);
        this.lrCreationForm.get('pan').setValue(data.pan);

        this.lrCreationForm.get('date').setValue(data.date);

        this.lrCreationForm.get('truckNo').setValue(data.vehicalNo);

        this.lrCreationForm.get('invoiceNo').setValue(data.invoiceNo);
        this.lrCreationForm.get('wayBillNo').setValue(data.wayBillNo);
        this.lrCreationForm.get('freight').setValue(data.freight);
        this.lrCreationForm.get('advance').setValue(data.advance);
        this.lrCreationForm.get('stationaryCharges').setValue(data.stationaryCharges);
        this.lrCreationForm.get('totalAmount').setValue(data.totalAmount);


        this.lrCreationForm.get('grossWeight').setValue(data.grossWeight);
        // this.lrCreationForm.get('tareWeight').setValue(data.tareWeight);
        // this.lrCreationForm.get('netWeight').setValue(data.netWeight);
        this.lrCreationForm.get('bankName').setValue(data.bankName);
        this.lrCreationForm.get('accountName').setValue(data.accountName);
        this.lrCreationForm.get('accountNumber').setValue(data.accountNumber);
        this.lrCreationForm.get('ifsCode').setValue(data.ifsCode);


        this.lrCreationForm.get('lorryHandlingCharges').setValue(data.lorryHandlingCharges);
        this.lrCreationForm.get('valueDeclared').setValue(data.valueDeclared);
        jQuery('#onLRUpdateModel').modal('show');

    }
    public doNgxDefault(): any {
        //console.log('this.selectedDestinationForUpdate&&&&&&&&&&&&&&&&&&&', this.selectedDestinationForUpdate);
        return this.selectedDestinationForUpdate
    }
    onLRRecordsDelete(data){
        console.log(data);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.removeLRRecords(data._id);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
        // if (confirm(`Are you sure you do you want delete the Receipt? IR No: ${data.lrNo}`)) {
        //       console.log('Delete Data',data);
        //         this.removeLRRecords(data._id);

        // } else {
        //    console.log("NO Changes")
        // }

        // jQuery('#onLRUpdateModel').modal('show');
    }
    onLRRecordsLocationTrack(data){
        // console.log(JSON.stringify(data));

        // this.googleBounds.extend(new google.maps.LatLng(locObj.lat, locObj.lng));
        // this.map.fitBounds(this.googleBounds);

        const sourceLatObj = data.source;
        this.getLatFewMinutesLocationForDevice(data, sourceLatObj);
    }

    getLatFewMinutesLocationForDevice(data, sourceLatObj) {

        let reqBody = {
            "userId": this.userMobile,
            "timeInterval": 10
        }
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.get_history_for_one_vehicle_last_few_minutes, reqBody, '', reqOptions).then((updatedObjList) => {
            this.loading = false;
            // this.clearOverlays();
            // console.log("---" + JSON.stringify(updatedObjList));
            this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: false, polylineOptions: { strokeColor: "green" } });
            this.showMap(data)
            updatedObjList.dataList.forEach(vehicle => {
                if (vehicle.vehicleNumber == this.selectedVehicleNumber) {
                    let locationObj = vehicle.locationDetails[vehicle.locationDetails.length - 1];
                    // jQuery('#map_model').modal('show');
                    this.calcRoute(sourceLatObj.location, { lat: locationObj.lat, lng: locationObj.lng });
                }
            });

        }).catch(err => {
            this.loading = false;
            console.log("Updated Creation Error");
        });
    }

    onLRUpdate(){

        if(this.lrDate === undefined || this.lrDate === null ){
          return alert("Please Date of Lorry Report Creation")
       } 
       if(this.lrDate.hasOwnProperty('formatted')) {

           const date =this.lrDate.formatted;
           const dbDate = new Date(date).toISOString();
           console.log('dbDatedbDatedbDatedbDatedbDatedbDate',dbDate)
           this.lrCreationForm.get('date').setValue(dbDate);
           } else{
                   this.lrCreationForm.get('date').setValue(this.dateUpdatetoDBnotSelected);
           }

        if(this.selectedSource === undefined){
            const source = this.getSoucreObjFilter(this.UpdateselectedSource)
            this.lrCreationForm.get('source').setValue(source[0]);
        } else{
            const vehicalNo = this.selectedSource[0];
            this.lrCreationForm.get('source').setValue(vehicalNo);
        }

        if(this.selectedDestination === undefined){

            const destination = this.selectedDestination;
            this.lrCreationForm.get('destination').setValue(destination);
        } else{

            this.updateDestinationList = [];
            this.UpdateselectedDestination.forEach(destnationName=>{
                this.updateDestinationList.push(this.getDestinationFilter(destnationName)[0]);
            })

            //const destination =this.getDestinationFilter('Sri Ganganagar Steel Godown');
            //const mergeDestination = Array.prototype.push.apply(this.updateDestinationList,this.selectedDestination); 
            const mergeDestination = [...this.updateDestinationList, ...this.selectedDestination];
            this.lrCreationForm.get('destination').setValue(this.removeDuplicates(mergeDestination));
        }

        if(this.selectedVehicalObj === undefined){
            const vehicalObj = this.getvehicalNoObjFilter(this.UpdateselectedVehical);
            this.lrCreationForm.get('truckNo').setValue(vehicalObj[0]);
        } else{
            const vehicalNo = this.selectedVehicalObj[0];
            this.lrCreationForm.get('truckNo').setValue(vehicalNo);
        }

        const updateObj = this.lrCreationForm.value;
        updateObj['_id'] = this.lrId;

        // console.log('this.lrCreationForm.value');
        // console.log(this.lrCreationForm.value);
        //console.log(this.selectedDestination);

        this.updateLRRecords(this.lrCreationForm.value)
    }
    resetLRCreationForms(){
        this.lrCreationForm.reset();
        this.UpdateselectedVehical = ''
        this.UpdateselectedSource = ''
        this.UpdateselectedDestination = ''
        this.lrDate = '';
    }
    updateLRRecords(updatedObj){
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_update_lr_records, updatedObj, '', reqOptions).then((updatedObjList) => {
            this.loading = false;
            this.getAllLRRecords();
            jQuery('#onLRUpdateModel').modal('hide');
            this.getAllLRRecords();
        }).catch(err=>{
            this.loading = false;
            console.log("Updated Creation Error");
            jQuery('#onLRUpdateModel').modal('hide');
        });
    }
    removeLRRecords(reqBody){
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option

        this.httpService.httpGetPromise(AppConstants.get_remove_lr_records + reqBody, "", reqOptions)
            .then((getdestinaitons) => {
                this.loading = false;
                this.getAllLRRecords();

            }).catch(err=>{
                this.loading = false;
                console.log("Deletion LR Recored  Error");
            });
    }
    changeLrPlaceType($event) {
        console.log($event.target.value);
        // tslint:disable-next-line: no-unused-expression
        const lrplacevalue = parseInt($event.target.value);
        // console.log('this.placeTypevalue', this.lrplacevalue);

        this.lrCreationForm.get('placeType').setValue($event.target.value, {
            onlySelf: true
        });

    }
    updateCurrentStatusLRRecords(reqBody, id){
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option

        this.httpService.httpGetPromise(AppConstants.update_current_status_lr_records + reqBody +'/'+ id, "", reqOptions)
        .then((updateCurrentStatus) => {
                this.loading = false;
                console.log(updateCurrentStatus);
                this.getAllLRRecords();

            }).catch(err=>{
                this.loading = false;
                console.log("Update Current Status of LR Recored  Error");
            });
    }
    showMap(data) {


        const location = { lat: 12.916719700000002, lng: 77.6027597 };
        var options = {
            center: location,
            zoom: 10
        }

        const sourceLatObj = data.source;
        // const destinationLanObj = data.destination[0];

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // this.applymarkers(sourceLatObj.location, sourceLatObj.placeName, google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, 'red', '', 0);

        data.destination.forEach(destinationLanObj => {
            this.applymarkers(destinationLanObj.location, destinationLanObj.placeName, google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, 'green', '', 0)
        });
        // this.showPolygonsOnMap();
        // this.calcRoute();
        //krishna river sand reach mid point
        // this.applymarkers({ lat: 16.493096, lng: 80.626315 }, "Krishna River Sand Reach", google.maps.SymbolPath.CIRCLE, "red", { lat: 16.493096, lng: 80.626315 }, 0)
        // //krishna river dumps
        // this.applymarkers({ lat: 16.493754, lng: 80.161505 }, "Patamata Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)

    }
    applymarkers(locObject, vehicleNumber, type, color, sandReachloc, sandReachType) {

        //google.maps.SymbolPath.FORWARD_CLOSED_ARROW

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);
        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            icon: {
                path: type,
                strokeColor: color,
                scale: 6
            },
            animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {
            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                let contentString = `<table style="width:200px">
              <tr>
              <td> <b>  ` + vehicleNumber + ` </b></td>
              </tr>
              <tr><td> <b> Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);

            }).catch((google_error) => {
                // handle errors 

            });
        });
    }
    calcRoute(startLoc, endLoc) {

        let directionsService = new google.maps.DirectionsService();
        let start = new google.maps.LatLng(startLoc.lat, startLoc.lng);
        let end = new google.maps.LatLng(endLoc.lat, endLoc.lng);
        let request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, (response, status) => {
            // console.log("response == >" + JSON.stringify(response.routes[0].legs[0].distance.text));

            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
                /*  this.map.setZoom(12);
                 this.googleBounds.extend(new google.maps.LatLng(16.493096, 80.626315));
                 this.googleBounds.extend(new google.maps.LatLng(16.493754, 80.161505));
                 this.map.fitBounds(this.googleBounds); */
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }
    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }
    printDataBasedOnDate():void{
        let printContents, popupWin;
        popupWin = window.open('', '_blank');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
           <style>
                table.borderStyle{
                border: 1px solid black;
            }
            table.borderStyle thead tr th{
              border-bottom: 1px solid black;
                 font-size: 12px;
            }
            table.borderStyle thead tr th{
              border-right: 1px solid black;
            }

            table.borderStyle tbody tr td{
              border-right: 1px solid black;
            }

             table.borderStyle tbody tr td{
                 font-size: 11px;
                 padding:4px;
            }
            table.borderStyle tbody tr td{
              border-bottom: 1px solid black;
            }
            table.borderStyle thead tr th{
              border-right: 1px solid black;
            }
            table {
              counter-reset: section;
            }

            .count:before {
              counter-increment: section;
              content: counter(section);
            }

             body{
             width:100%;
           }
           tr td h3{
               text-align:center;
               padding-bottom:20px;
           }
            @media print {
                body, page[size="A4"] {
                margin: 0;
                box-shadow: 0;
            }
            @media print{@page {size: landscape}}
            @page {
                size: A4 landscape;
            }

           </style>

          </head>

        <body onload="window.print()">
             <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%" style="border: 0;">
                <tr>
                    <td style="border-right:0px;border-left:0px">
                        <span style="font-weight:bold">TLOA(</span>
                        <span>Transin logistics pvt ltd<span>
                        <span  style="font-weight:bold">)Daily Report</span>
                    </td>
                </tr>
            </table>
            <table width="100%" id="print-section" cellspacing="0" cellpadding="0" class="borderStyle" style="border-bottom:0px;">
                <thead class="borderStyle">
                    <tr>

                        <th>
                            S.No
                        </th>
                        <th>
                            Date
                        </th>
                        <th width="5%">
                            LRN No
                        </th>
                        <th width="5%">
                            Vehical No
                        </th>
                        <th width="15%">
                           Destination
                        </th>
                        <th width="5%">
                           Lr Qty
                        </th>
                        <th width="5%">
                           Rate
                        </th>
                        <th width="5%">
                           Total Amount
                        </th>
                        <th width="5%">
                           Advance
                        </th>
                        <th width="15%">
                           Transporter / Owner Name
                        </th>
                        <th width="10%">
                           Account No
                        </th>
                        <th width="10%">
                           Bank Name / Branch
                        </th>
                        <th width="5%">
                           IFSC Code
                        </th>
                        <th width="10%">
                           Pan No
                        </th>
                    <tr>
                </thead>
                <tbody>
                   ${this.tableValue.map(vehical =>` 
                      <tr>
                          <td class="count"></td>
                         <td>
                             ${new Date(vehical.creationDateAndTime).toLocaleDateString()}
                         </td>
                        <td width="5%">
                           ${vehical.lrNo}
                        </td>
                        <td width="5%">
                            ${vehical.vehicalNo}
                        </td>
                        <td width="15%">
                            ${vehical.destinationName}
                        </td>
                        <td>
                            ${vehical.quantity}
                        </td>
                        <td width="5%">
                          ${vehical.rate}
                        </td>
                        <td width="5%">
                            ${vehical.amount}
                        </td>
                        <td width="5%">
                            ${vehical.advance}
                        </td>
                        <td width="15%">
                            ${vehical.consignor}
                        </td>
                        <td width="10%">
                            ${vehical.accountNumber}
                        </td>
                        <td width="10%">
                            ${vehical.bankName}
                        </td>
                        <td width="5%">
                            ${vehical.ifsCode}
                        </td>
                        <td width="10%">
                            ${vehical.pan}
                        </td>
                      </tr>
                      `).join('')}
                </tbody>
            </table>
            <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%"  style="border-top:0px;" >
                <tr>
                  <td  style="padding-top:;text-align:center;" width="100%" >
                      ${new Date().toString()}
                  </td>
              </tr>
            </table>
        </html>`
        );
        popupWin.document.close();

    }
    printDataBasedLorryReport(data):void{
        // console.log(data);
        this.printLaryReceiptData = data;
        let printContents, popupWin;
        popupWin = window.open('', '_blank');
        //popupWin = window.open('', '_top');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
           <style>
                table.borderStyle{
                border: 1px solid black;
            }
             body{
             width:100%;
           }
           tr td h3{
               text-align:center;
               padding-bottom:20px;
           }
           @media print {
              body, page[size="A4"] {
                margin: 0;
                box-shadow: 0;
              }
              @media print{@page {size: landscape}}
              @page {
                    size: A4 landscape;
                }
           </style>

          </head>

        <body onload="window.print()">
         <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%" style="border: 0;">
                <tr>
                    <td   width="40%">
                        <h3  style="margin: 0px;">GOODS CONSIGNMENT NOTE</h3>
                    </td>
                    <td   width="60%"  style="float:right">
                        <h3 style="margin: 0px;text-nowrap">GOODS CONSIGNMENT NOTE</h3>
                    </td>
                </tr>
            </table>
            <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%" style=" border-bottom:0px;"  style="padding:10px;">
                <tr>
                    <td   style="padding-bottom:20px;text-align:center; width="100%" >
                        <h2  style="margin: 2px;">THE TADIPATRI LORRY OWNER'S ASSOCIATION</h2>
                        <h6 style="font-weight: normal;margin: 2px;">Address: 12/524, C.B Road, Tadipatri - 515 411</h6>
                        <h6 style="font-weight: normal;margin: 2px;">Phone: +91 94401 06826, E-mail: support@transin.in</h6>
                        <h6 style="font-weight: normal;margin: 2px;">REGIST No: 49/71 PAN: AADAT4087A</h6>
                    </td>
                </tr>
            </table>
            <table width="100%" id="print-section" cellspacing="0" cellpadding="0" class="borderStyle"  style="padding:10px;">
                <tbody>
                     <tr>
                         <td width="25%">
                             <span style="font-weight: bold;">LR NO:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.lrNo}</span>
                         </td>
                         <td width="25%">
                             <span style="font-weight: bold;">TRUCK No:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.vehicalNo}</span>
                         </td>
                         <td width="25%">
                             <span style="bold-text">DC NO:</span>
                         </td>
                         <td width="25%">
                             <span style="bold-text">GROSS WT:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.grossWeight}</span>
                         </td>
                     </tr>
                     <tr>
                         <td width="25%">
                             <span style="bold-text">DATE:</span><span>&nbsp;&nbsp;${new Date(this.printLaryReceiptData.date).toLocaleDateString()}</span>
                         </td>
                         <td width="25%">
                             <span style="bold-text">G.P.NO:</span>
                         </td>
                         <td width="25%">
                             <span style="bold-text">WAY BILL NO:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.wayBillNo}</span>
                         </td>
                         <td width="25%">
                             <span style="bold-text">TARE WT:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.tareweight}</span>
                         </td>
                     </tr>
                     <tr>
                         <td colspan="2">
                             <span style="bold-text">FROM:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.sourceName}</span>
                         </td>
                         <td>
                             <span style="bold-text">INVOICE NO:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.invoiceNo}</span>
                         </td>
                         <td>
                             <span style="bold-text">NET WT:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.netWeight}</span>
                         </td>
                     </tr>
                     <tr>
                         <td colspan="2">
                             <span style="bold-text">TO</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.destinationName}</span>
                         </td>
                         <td  colspan="2">
                             <span style="font-weight: bold;">ADVANCE:</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.advance}</span>
                         </td>
                     </tr>
                </tbody>
            </table>
            <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%"  style="padding:10px;border-top: 0px;">
                <tr>
                  <td width="50%" >
                      <span style="font-weight: bold;">CONSIGNOR</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.consignor}</span>
                  </td>
                  <td width="50%" >
                      <span style="font-weight: bold;">CONSIGNEE</span><span>&nbsp;&nbsp;${this.printLaryReceiptData.consignee}</span>
                  </td>
              </tr>
                <tr>
                  <td width="50%" style="padding-top:10px" >
                     <span style="bold-text">COMPANY</span>
                  </td>
                  <td width="50%"  style="padding-top:10px">
                     <span style="bold-text">COMPANY</span>
                  </td>
              </tr>
                <tr>
                  <td width="50%" style="padding-top:10px">
                     <span style="bold-text">LOCATION</span>
                  </td>
                  <td width="50%" style="padding-top:10px">
                     <span style="bold-text">LOCATION</span>
                  </td>
              </tr>
            </table>
            <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%"  style="padding:10px;border-top: 0px;">
                <tr>
                  <td colspan="2">
                     <span  style="font-weight: bold;" >GOODS DESCRIPTION</span>
                  </td> 
                </tr>
                <tr>
                  <td width="50%"  style="padding-top:20px">
                     <span style="bold-text">&nbsp</span>
                  </td>
                  <td width="50%"  style="padding-top:20px">
                     <span style="bold-text">SEAL NO:</span>
                  </td>
              </tr>
            </table> 
            <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%"  style="padding:10px;border-top: 0px;" >
                <tr>
                  <td width="31%"  rowspan="2">
                     <span style="font-size: 12px;">Note: The consignor herby expressly declared that above particulars are correct, and that he is aware of the conditions of carriers printed overieaf.</span>
                  </td>
                  <td width="2.34%">&nbsp;</td>
                  <td width="31%">
                     <span  style="font-weight: bold;">GOODD WILL BE DELIVERED</span>
                  </td>
                  <td width="2.34%">&nbsp;</td>
                  <td>
                     <span style="font-weight: bold;">FOR THE TADIPATRI LORRY OWNER'S ASSOCIATION</span>
                  </td>
              </tr>
             <tr>
                 <td style="padding-top:10px;">&nbsp;</td>
                 <td style="padding-top:10px;">
                     <span>at</spam>
                 </td>
                 <td style="padding-top:10px;">&nbsp;</td>
                 <td style="padding-top:10px;">
                     <span>&nbsp;</spam>
                 </td>
             </tr>
             <tr>
                 <td style="padding-top:20px;" colspan="4">
                     <span>Signature of the Sender or his agent.</span>
                 </td>    
                 <td style="padding-top:20px; text-align:center">
                     <span>GENERATED BY</span>
                 </td>  
             </tr>
            </table>
            <table  cellspacing="0" cellpadding="0" class="borderStyle"  width="100%" style="padding:10px; border-top: 0px;">
               <tr>
                   <td width="50%" >
                        <span style="font-size: 12px;"> NOTE: Fright Charges and Service Tax to be paid by consignee. I No credit is taken on the inputs and capital goods used for providing goods transport service</span>
                   </td>
                   <td width="50%" style="float:right">
                        <span><sup>*</sup>Supject to Tadipatri Jurisdiction</span>
                   </td>
               </tr>
            </table>
        </html>`
        );
        popupWin.document.close();

    }
    filterTableDataBasedOnDate(){

    }
    onKeyUpGrossWeight(event){
        this.grossWeight = event.target.value;
        if(this.grossWeight ==='' || this.grossWeight===null || this.grossWeight ===undefined){
        this.lrCreationForm.get('tareweight').setValue(''); 
        this.lrCreationForm.get('netWeight').setValue(''); 
        }

    } 
    mychange(val){
        this.lrCreationForm.get('tareweight').setValue(''); 
        this.lrCreationForm.get('netWeight').setValue(''); 
    }
    onKeyUpTareweight(event){
        this.tareweightInput = event.target.value;
        if(this.grossWeight === undefined){
            alert('Please Fill Gross Weight!');
            event.target.value = '';
        }{
        this.netValue =   this.grossWeight - this.tareweightInput 
        this.lrCreationForm.get('netWeight').setValue(this.netValue); 
        }
    }

    clearOverlays() {
        for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
        }
        this.markersArray.length = 0;
        if (this.directionsDisplay)
            this.directionsDisplay.setMap(null)
    }
}
