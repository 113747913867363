import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
import * as $AB from 'jquery';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import * as jquery from 'jquery';
import { Observable } from 'rxjs';
declare var google: any;
declare var jQuery: any;

declare var jquery: any;   // not required
declare var $: any;   // not required
import { Headers, RequestOptions } from '@angular/http';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { async } from '@angular/core/testing';


@Component({
    selector: 'app-destinationReports',
    templateUrl: './destinationReports.component.html',
    styleUrls: ['./destinationReports.component.scss']
})

export class DestinationReports implements OnInit {

    PlaceType1: any = [{ 'name': 'Source', 'value': 0 }, { 'name': 'Destination', 'value': 1 }];
    cols: any[];
    sortF: any;
    lrCreationForm: FormGroup

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;


    // public myDatePickerOptions: IMyDpOptions = {
    //     // other options...
    //     dateFormat: 'yyyy-mm-dd',
    //     disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    // };

    // public myDatePickerOptions2: IMyDpOptions = {
    //     // other options...
    //     dateFormat: 'yyyy-mm-dd',
    //     disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    // };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    dashboardData: any;
    placetypevalue: any;
    lrplacevalue: any;
    ExistingLRDestinations: any = [];
    updatedDestination: boolean = false;
    newDestination: boolean = true;
    existDestinationObject: any;
    placeTypeId: any;
    locationId: any;
    vexist: any;
    createDateTime: any;
    assignPlaceType: any;
    placeType: any;
    

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter,
        private formBuilder: FormBuilder,
        private modalService: NgbModal) {

    }

    ngOnInit() {

        this.cols = [
            { field: 'placeName', header: 'PlaceName' },
            { field: 'district', header: 'District' },
            { field: 'city', header: 'City' },
            { field: 'state', header: 'State' },
            { field: 'pinCode', header: 'PinCode' },
            { field: 'assignPlaceType', header: 'Placetype' }
        ];

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        // this.getVehicleList();
        this.getAllLrDestions();
        this.createLrDestination();
        this.outsdiedCaptureEvent();

    }

    createLrDestination() {
        this.lrCreationForm = this.formBuilder.group({
            placeName: '',
            district: '',
            city: '',
            state: '',
            pinCode: '',
            location: this.formBuilder.group({
                lat: 0,
                lng: 0,
            }),
            placeType: 0,
        })
    }

    changeLrPlaceType($event) {
        // console.log($event.target.value);
        // tslint:disable-next-line: no-unused-expression
        this.lrplacevalue = parseInt($event.target.value);
        // console.log('this.placeTypevalue', this.lrplacevalue);

        this.lrCreationForm.get('placeType').setValue($event.target.value, {
            onlySelf: true
        });

    }
    outsdiedCaptureEvent() {
        jQuery('#lrdestinationModal').on('hidden.bs.modal', () => {
            this.lrCreationForm.reset();
        });

    }

    onSubmit() {
        // console.log('this.lrCreationForm.value@@@@#####', this.lrCreationForm.value);

        // console.log('this.lrCreationForm.value@@@@#####', this.lrCreationForm.value);
        // console.log("this.lrCreationForm.value@@@@#####", this.lrCreationForm.value.location.lat)
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_public_createLRLocation, this.lrCreationForm.value, "", reqOptions)
            .then((savedestinaitons) => {
                // console.log("savedashboarddata..............", (savedestinaitons));
                this.loading = false;
                this.getAllLrDestions();
                // console.log("dashboarddata..............", JSON.stringify(destinaitons));
            }).catch(err => {
                console.log("Error at Submit");
                this.loading = false;
                // jQuery('#onLRUpdateModel').modal('hide');
            });


        jQuery('#lrdestinationModal').modal('hide');
        this.lrCreationForm.reset();
    }

   
    getAllLrDestions() {
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_public_getAllLRLocations, "", reqOptions)
            .then((getdestinaitons) => {
                // console.log("getdashboarddata..............", JSON.stringify(getdestinaitons.lRLocationData));
                this.ExistingLRDestinations = getdestinaitons.lRLocationData;

                this.ExistingLRDestinations.forEach(forPlaceType => {
                    if (forPlaceType.placeType === 0) {
                        forPlaceType.assignPlaceType = 'Source';
                        //    console.log('zxxzxz', this.assignPlaceType);
                    } else if (forPlaceType.placeType === 1) {
                        forPlaceType.assignPlaceType = 'Destination';
                        // console.log('yyyyyyy', this.assignPlaceType);
                    }
                    this.loading = false;
                })
            }).catch(err => {
                this.loading = false;
                console.log(" Error at getAllLrDestions");
                // jQuery('#onLRUpdateModel').modal('hide');
            });
    }
    onDestinationEdit(data) {
        // console.log('@@@@@@@', JSON.stringify(data));
       
        localStorage.setItem('placeTypeid', JSON.stringify(data._id));
        localStorage.setItem('locationId', JSON.stringify(data.location._id));
        localStorage.setItem('__v', JSON.stringify(data.__v));
        localStorage.setItem('creationTimeDate', JSON.stringify(data.creationDateAndTime));
        this.existDestinationObject = data;
        this.lrCreationForm.patchValue(data);
        this.newDestination = false;
        this.updatedDestination = true;

        jQuery('#lrdestinationModal').modal('show');
    }
    onUpdateDestination(updateValue) {
         this.loading = true;
        // console.log('********', JSON.parse(localStorage.getItem('placeTypeid')));
        // console.log('********&&&&&', JSON.parse(localStorage.getItem('locationId')));
        // console.log('********&&&&&***', JSON.parse(localStorage.getItem('__v')));
        this.placeTypeId = JSON.parse(localStorage.getItem('placeTypeid'));
        this.locationId = JSON.parse(localStorage.getItem('locationId'));
        this.vexist = JSON.parse(localStorage.getItem('__v'));
        this.createDateTime = JSON.parse(localStorage.getItem('creationTimeDate'));

        // console.log('this.existDestinationObject = data;', JSON.stringify(this.existDestinationObject));
        // console.log('updateValue&&&&&&', JSON.stringify(updateValue));

        const body = {
            _id: this.placeTypeId,
            placeName: updateValue.placeName,
            district: updateValue.district,
            city: updateValue.city,
            state: updateValue.state,
            pinCode: updateValue.pinCode,
            location: {
                _id: this.locationId,
                lat: updateValue.location.lat,
                lng: updateValue.lng,
            },
            placeType: updateValue.placeType,
            creationDateAndTime: this.createDateTime,
            __v: this.vexist,
        };
        // console.log('xxxxxxxxxxeee', body);
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_public_updateLRLocations, body, "", reqOptions)
            .then((Updatedestinaitons) => {
                // console.log("Updatedestinaitonsdata..............", JSON.stringify(Updatedestinaitons));
                this.loading = false;
                this.getAllLrDestions();
            }).catch(err => {
                console.log("Error at UpdateDestion()");
                this.loading = false;
                // jQuery('#onLRUpdateModel').modal('hide');
            });
        jQuery('#lrdestinationModal').modal('hide');
        this.lrCreationForm.reset();
    }

    onDestinationRemove(data) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.value) {
             Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
              this.onDestinationRemove1(data);
            }
          })
    }

    onDestinationRemove1(data) {
        // console.log('%%%%%%%%', data);
        // this.loading = true;
        const reqBody = data._id;
        // console.log('!!!!!!', reqBody);
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_public_removeLRLocation + reqBody, "", reqOptions)
            .then((getdestinaitons) => {
                // console.log("getdashboarddata..............", JSON.stringify(getdestinaitons));
                // this.loading = false;
                this.getAllLrDestions();
                // this.ExistingLRDestinations = getdestinaitons.lRLocationData;

            }).catch(err => {
                // this.loading = false;
                console.log(" Error at on DestinationRemove1(data)");
                // jQuery('#onLRUpdateModel').modal('hide');
            });
    }

    // getVehicleList() {
    //     let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
    //     let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    //     let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    //     this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
    //         this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
    //         this.selectedVehicle = this.gpsDevicesArray[0];
    //         // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
    //     })
    // }

    // onDateChanged(event: IMyDateModel) {


    //     if (event && event.jsdate) {
    //         let d: Date = new Date(event.jsdate.getTime());
    //         d.setDate(d.getDate() - 1);
    //         let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
    //         copy.disableUntil = {
    //             year: d.getFullYear(),
    //             month: d.getMonth() + 1,
    //             day: d.getDate()
    //         };
    //         this.myDatePickerOptions2 = copy;
    //     }
    // }

    // getCopyOfEndDateOptions(): IMyDpOptions {
    //     return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    // }


    // convert() {
    //     let body = [];
    //     body.push(this.dashboardData);
    //     this.downloadExcelSheet(body).subscribe(blob => {
    //         // Doing it this way allows you to name the file
    //         var link = document.createElement('a');
    //         link.href = window.URL.createObjectURL(blob);
    //         link.download = "Report.xlsx";
    //         link.click();
    //     }, error => console.log("Error downloading the file."),
    //         () => console.log('Completed file download.'));
    // }

    // onClearClick() {
    //     this.isPdfNotAvailable = true;
    //     this.fromDate = null;
    //     this.toDate = null;
    //     this.isSubmitClick = false;
    //     this.vehicleDetails = null;
    // }

    // todaysDate() {
    //     var d = new Date(),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;

    //     return [year, month, day].join('-');
    // }

    // getTableData() {

    //     if (!(this.fromDate && this.fromDate.formatted)) {
    //         alert('Please select From Date first');
    //         return;
    //     }

    //     if (!(this.toDate && this.toDate.formatted)) {
    //         alert('Please select From Date first');
    //         return;
    //     }

    //     this.isSubmitClick = true;
    //     this.isPdfNotAvailable = true;
    //     this.loading = true;

    //     let dashBoardParams = {
    //         imeiNumber: this.selectedVehicle.imeiNumber,
    //         toDate: this.toDate.formatted,
    //         fromDate: this.fromDate.formatted
    //     }

    //     let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    //     let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    //     this.httpService.httpPostPromise(AppConstants.get_dashboard_data_for_report, dashBoardParams, "", reqOptions).then((dashboardData) => {
    //         // console.log("dashboarddata..............", JSON.stringify(dashboardData));
    //         this.isPdfNotAvailable = false;
    //         this.loading = false;
    //         if (dashboardData && dashboardData.statusCode === 200) {
    //             this.dashboardData = dashboardData;
    //             this.dashboardData.recordList.forEach(element => {
    //                 let dateTimeStamp = new Date(element.historyDate).getTime();
    //                 element.historyTimeStamp = dateTimeStamp;
    //             });
    //             this.dashboardData.recordList.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
    //         }
    //     }).catch((dashboardErr) => {
    //         this.loading = false;
    //         // console.log("dashboardErr...", JSON.stringify(dashboardErr));
    //     });
    // }

    // downloadExcelSheet(reqBody): Observable<Object[]> {
    //     this.loading = true;
    //     return Observable.create(observer => {
    //         let xhr = new XMLHttpRequest();
    //         xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/dailysummaryreport', true);
    //         xhr.setRequestHeader('Content-type', 'application/json');
    //         xhr.responseType = 'blob';

    //         xhr.onreadystatechange = () => {
    //             this.loading = false;
    //             if (xhr.readyState === 4) {
    //                 if (xhr.status === 200) {

    //                     var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    //                     var blob = new Blob([xhr.response], { type: contentType });
    //                     observer.next(blob);
    //                     observer.complete();
    //                 } else {
    //                     observer.error(xhr.response);
    //                 }
    //             }
    //         }
    //         xhr.send(JSON.stringify(reqBody));

    //     });
    // }

    // onVehicleNumberChange(device) {
    //     this.selectedVehicle = device;
    //     this.getTableData();
    // }




    // changePaymentType($event) {
    //     console.log($event.target.value);
    //     this.placetypevalue = parseInt($event.target.value);
    //     console.log('this.paymentmodevalue', this.placetypevalue);
    //     this.patientForm.get('registrationPaymentMode').setValue($event.target.value, {
    //         onlySelf: true
    //     });
    // }
}
