import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';
declare var jQuery: any;
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-tripDetails',
    templateUrl: './tripDetails.component.html',
    styleUrls: ['./tripDetails.component.scss']
})

export class TripDetails implements OnInit {

    cols: any[];
    sortF: any;
    sandData = [
        {
            'tripStartTime': '6:30:05',
            'tripEndTime': '8:30:05',
            'loadingLocation': 'Vijayawada',
            'unloadingLocation': 'Gudivada',
            'weight': '2',
            'tripStatus': 'Inprogress'
        },
        {
            'tripStartTime': '6:42:40',
            'tripEndTime': '7:42:40',
            'loadingLocation': 'Guntur',
            'unloadingLocation': 'Guntur Urban',
            'weight': '1.8',
            'tripStatus': 'Inprogress'
        },
        {
            'tripStartTime': '14:42:40',
            'tripEndTime': '16:38:42',
            'loadingLocation': 'Kadapa',
            'unloadingLocation': 'Pulivendula',
            'weight': '3',
            'tripStatus': 'Inprogress'
        },
        {
            'tripStartTime': '16:02:40',
            'tripEndTime': '17:30:40',
            'loadingLocation': 'Guntur',
            'unloadingLocation': 'Chilakluripeta',
            'weight': '3',
            'tripStatus': 'Inprogress'
        },
        {
            'tripStartTime': '20:00:40',
            'tripEndTime': '20:30:52',
            'loadingLocation': 'Vijayawada',
            'unloadingLocation': 'Tadigadapa',
            'weight': '3',
            'tripStatus': 'Reached'
        },
        {
            'tripStartTime': '21:10:32',
            'tripEndTime': '22:00:40',
            'loadingLocation': 'Eastgodavari',
            'unloadingLocation': 'MCpalli',
            'weight': '3',
            'tripStatus': 'Reached'
        },
        {
            'tripStartTime': '23:10:00',
            'tripEndTime': '01:30:32',
            'loadingLocation': 'Nellore',
            'unloadingLocation': 'Guduru',
            'weight': '3',
            'tripStatus': 'Reached'
        }
    ]
    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    dashboardData: any;
    map: any;
    @ViewChild("mapRef") mapRef: ElementRef;
    directionsDisplay: any;
    markersArray: any = [];
    polyLinesArray: any = [];
    googleBounds: any = new google.maps.LatLngBounds();

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.cols = [
            { field: 'tripStartTime', header: 'Trip Start Time' },
            { field: 'tripEndTime', header: 'Trip End Time' },
            { field: 'loadingLocation', header: 'Loading Location' },
            { field: 'unloadingLocation', header: 'UnLoading Location' },
            { field: 'weight', header: 'Weight at (approx)' },
            { field: 'tripStatus', header: 'Trip Status' },
        ];


        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getVehicleList();
        this.showMap();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
        })
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {
        let body = [];
        body.push(this.dashboardData);
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    // getTableData() {

    //     if (!(this.fromDate && this.fromDate.formatted)) {
    //         alert('Please select From Date first');
    //         return;
    //     }

    //     if (!(this.toDate && this.toDate.formatted)) {
    //         alert('Please select From Date first');
    //         return;
    //     }

    //     this.isSubmitClick = true;
    //     this.isPdfNotAvailable = true;
    //     this.loading = true;

    //     let dashBoardParams = {
    //         imeiNumber: this.selectedVehicle.imeiNumber,
    //         toDate: this.toDate.formatted,
    //         fromDate: this.fromDate.formatted
    //     }

    //     let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    //     let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    //     this.httpService.httpPostPromise(AppConstants.get_dashboard_data_for_report, dashBoardParams, "", reqOptions).then((dashboardData) => {
    //         // console.log("dashboarddata..............", JSON.stringify(dashboardData));
    //         this.isPdfNotAvailable = false;
    //         this.loading = false;
    //         if (dashboardData && dashboardData.statusCode === 200) {
    //             this.dashboardData = dashboardData;
    //             this.dashboardData.recordList.forEach(element => {
    //                 let dateTimeStamp = new Date(element.historyDate).getTime();
    //                 element.historyTimeStamp = dateTimeStamp;
    //             });
    //             this.dashboardData.recordList.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
    //         }
    //     }).catch((dashboardErr) => {
    //         this.loading = false;
    //         // console.log("dashboardErr...", JSON.stringify(dashboardErr));
    //     });
    // }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/dailysummaryreport', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange(device) {
        this.selectedVehicle = device;
        // this.getTableData();
    }

    outsdiedCaptureEvent() {
        jQuery('#lrdestinationModal').on('hidden.bs.modal', () => {
            // this.lrCreationForm.reset();
        });

    }

    onShowOnMapClick() {
        jQuery('#lrdestinationModal').modal('show');
        this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true, preserveViewport: true, polylineOptions: { strokeColor: "green" } });
        this.calcRoute({ lat: 16.493096, lng: 80.626315 }, { lat: 16.493754, lng: 80.161505 });
    }

    showMap() {

        const location = { lat: 16.3067, lng: 80.4365 };
        var options = {
            center: location,
            zoom: 10
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // this.showPolygonsOnMap();
        // this.calcRoute();
        //krishna river sand reach mid point
        this.applymarkers({ lat: 16.493096, lng: 80.626315 }, "Krishna River Sand Reach", google.maps.SymbolPath.CIRCLE, "red", { lat: 16.493096, lng: 80.626315 }, 0)
        //krishna river dumps
        this.applymarkers({ lat: 16.493754, lng: 80.161505 }, "Patamata Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)

    }

    applymarkers(locObject, vehicleNumber, type, color, sandReachloc, sandReachType) {

        //google.maps.SymbolPath.FORWARD_CLOSED_ARROW

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);
        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            icon: {
                path: type,
                strokeColor: color,
                scale: 6
            },
            animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {
            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                let contentString = `<table style="width:200px">
              <tr>
              <td> <b>  ` + vehicleNumber + ` </b></td>
              </tr>
              <tr><td> <b> Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);

            }).catch((google_error) => {
                // handle errors 

            });
        });
    }

    calcRoute(startLoc, endLoc) {

        let directionsService = new google.maps.DirectionsService();
        let start = new google.maps.LatLng(startLoc.lat, startLoc.lng);
        let end = new google.maps.LatLng(endLoc.lat, endLoc.lng);
        let request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, (response, status) => {
            // console.log("response == >" + JSON.stringify(response.routes[0].legs[0].distance.text));

            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
                /*  this.map.setZoom(12);
                 this.googleBounds.extend(new google.maps.LatLng(16.493096, 80.626315));
                 this.googleBounds.extend(new google.maps.LatLng(16.493754, 80.161505));
                 this.map.fitBounds(this.googleBounds); */
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

}
