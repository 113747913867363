import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
import { SelectItem } from 'primeng/primeng';
import * as $AB from 'jquery';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import * as jquery from 'jquery';
import { Observable } from 'rxjs';
declare var google: any;
declare var jQuery: any;
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

declare var jquery: any;   // not required
declare var $: any;   // not required

import { Headers, RequestOptions } from '@angular/http';


@Component({
  selector: 'app-medicalTripSummaryReportComponent',
  templateUrl: './medical-trip-summary-report.component.html',
  styleUrls: ['./medical-trip-summary-report.component.scss']
})
export class MedicalTripSummaryReportComponent implements OnInit {
    rowData: any;
    lrCreationForm: FormGroup;
    statusForm: FormGroup;
    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    private value: any = {};
    dataModel;
    cols: any[];

    sourceList = []
    destinationList = []
    sourceListObj = []
    destinationListObj = []
    selectedVehicalObj
    selectedSource
    //selectedDestination;
    selectedDestination = []
    lrReportStatus
    status: SelectItem[];
    selectedStatus: any;
    lrRecordUpdateStatus: any;
    UpdateselectedVehical;
    UpdateselectedSource;
    UpdateselectedDestination;
    tableValue = [];
    removeEndedTripFromTable = [];
    seletedTest;
    lrId;
    selectedStopIDEle : any;
    trip : any;
    map: any;
    @ViewChild("mapRef") mapRef: ElementRef;
    markersArray: any = [];
    directionsDisplay: any;
    grossWeight
    tareweightInput
    printLaryReceiptData
    selectedVehicleNumberForUpdate;
    selectedSourceForUpdate;
    selectedDestinationForUpdate
    dateUpdatetoDBnotSelected;
    updateDestinationList = [];
    netValue
    obj;
    LeadTimeRemaining = "red"
    testList;
    public ngxValue: any = [];
    allWell = 0;
    mediuIssues = 0;
    delyedTrips = 0;
    gpsDelyedHR = 0;
    tripCompleted = 0;
    asignedGpsDevice = [];

    expectedReachDate
    actualReachDate


    singaleVeicalArrayObj = [];
    StatusCheck = [{ value: "Proggress", selected: 0 },
    { value: "Reached", selected: 1 },
    { value: "Return", selected: 1 },];
    StatusType: any = [{ 'name': 'Please Select', 'value': -1 },
    { 'name': 'Proggress', 'value': 0 },
    { 'name': 'Reached', 'value': 1 },
    { 'name': 'Return', 'value': 2 },
    { 'name': 'End', 'value': 3 }];

    config = {
        displayKey: "description", //if objects array passed which key to be displayed defaults to description
        search: true, //true/false for the search functionlity defaults to false,
        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
        customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
        // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'No results found!',// text to be displayed when no items are found while searching
        searchPlaceholder: 'Search', // label thats displayed in search input,
        searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    }

    public typed(value: any): void { let el: HTMLElement = document.getElementsByClassName("ui-select-choices dropdown-menu")[0] as HTMLElement; if (typeof el !== 'undefined') { el.style.display = 'block'; } }


    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { month: new Date().getMonth() + 1, day: new Date().getDate() + 1, year: new Date().getFullYear() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { month: new Date().getMonth() + 1, day: new Date().getDate() + 1, year: new Date().getFullYear() }
    };


    // Initialized to specific date (09.10.2018).
    public lrDate: any;
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    selectedVehicleNumber: any;
    dashboardData: any;
    vehicalNo = [];
    setForUpdateTrackNo: any;
    setForUpdateSource: any;
    setForUpdateDestination: any;
    userMobile: any
    // centralizedLRCreation Data 
    centralizedLRObject: any = []
    singaleLRReportObj = [];
    statusArray = [];


       modelArrayData = []
    constructor(private httpService: HttpService,
        private urlFormatter: URLStringFormatter,
        private formBuilder: FormBuilder,) {

    }

 


    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobile = localStorage.getItem("userMobile");
        // this.getVehicalNo();
        // this.LorryReportCreationForm();
       
        
   
        this.statusForm = this.formBuilder.group({
            lrCreationstatus: ''
        })

        this.cols = [
            { field: 'lrNo', header: 'lR No' },
            { field: 'vehicleNo', header: 'Vehical No' },
            { field: 'tripdestName', header: 'Dest-Name' },
            { field: 'lrDateAndTime', header: 'Dispatch Date' },
            { field: 'vexpectedReachDate', header: 'End Date' },
            { field: 'tripStatus', header: 'Satus' },
            { field: 'yetToReactDistance', header: 'Travel Distance Pending' }
        ];
        
    }


    outsdiedCaptureEvent() {
        jQuery("#onLRUpdateModel").on("hidden.bs.modal", () => {
        });
    }
   

    convert() {
        // let body = [];
        // body.push(this.dashboardData);
        // console.log(JSON.stringify(this.centralizedLRObject));

        let value = [];
        this.centralizedLRObject.forEach(tripDateObj => {
            let subValueArray = [];
            subValueArray.push(tripDateObj.driverName);
            subValueArray.push(tripDateObj.vehicleNo);
            subValueArray.push(tripDateObj.creationDateAndTime);
            subValueArray.push(tripDateObj.endDateAndTime);
            subValueArray.push(tripDateObj.noOfStops);
            subValueArray.push(tripDateObj.gpsCaptured);
            subValueArray.push(tripDateObj.tripDuration);
            subValueArray.push(tripDateObj.tripDistance);

            
            value.push(subValueArray);
        });

        let headerName = "Pharma Logistics Report (" + this.fromDate.formatted + " to " + this.toDate.formatted + ")";

        let body = {
            "headerName": headerName,
            "excelName": "PharmaLogisticsReport",
            "header": [
                "Driver Name",
                "Vehicle No",
                "Trip Start Time",
                "Trip End Time",
                "No Of Stops",
                "GPS Reg Stops",
                "Trip Duration",
                "Distance Travelled"
            ],
            "value": value
        }

        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "PharmaLogisticsReport.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }



    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + 'genericxls', true);
            xhr.setRequestHeader('Content-type', 'application/json');
          //  xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://excel.hawkeyeway.com');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    getPharmaLogisticsReport() {
      if (!(this.fromDate && this.fromDate.formatted)) {
          alert('Please select From Date first');
          return;
      }

      if (!(this.toDate && this.toDate.formatted)) {
          alert('Please select To Date first');
          return;
      }
     
      this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: false, polylineOptions: { strokeColor: "green" } });
      this.allWell = 0;
      this.mediuIssues = 0;
      this.delyedTrips = 0;
      this.gpsDelyedHR = 0;
      this.tripCompleted = 0;

      
      this.fromDate = JSON.parse(JSON.stringify(this.fromDate));
      this.toDate = JSON.parse(JSON.stringify(this.toDate));
      this.loading = true;
      let reqBody = {
          "mobileNumber" : this.userMobile,
          "startDate": this.fromDate.formatted,
          "endDate": this.toDate.formatted
      }

      let headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
      let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
      this.httpService.httpPostPromise(`${AppConstants.get_Trips_Of_All_Vehicle_BW_Dates}`, reqBody, '', reqOptions).then(tripSheetObj => {
        
          this.loading = false;
          console.log(JSON.stringify(tripSheetObj)) 
         

          this.centralizedLRObject = tripSheetObj;
          this.isPdfNotAvailable = false;
          console.log('==tripSheetObj====>', JSON.stringify(tripSheetObj))
          if(tripSheetObj.length ===0){
              this.isPdfNotAvailable = true;
              Swal.fire({
                  title: 'Livepin',
                  text: 'Please Select Other Date!',
                  imageUrl: '../../assets/statusImg/no_recordFond.png',
                  imageWidth: 400,
                  imageHeight: 200,
                  imageAlt: 'Today',
                  animation: false
                })
          }


      }).catch((tripSheetObjErr) => {
          this.loading = false;
          console.log("getAllLRlocationErr...", JSON.stringify(tripSheetObjErr));
      });
  }
    
   

    valueChanged(data) {
        console.log(data);
        const dataObj = []
        dataObj.push(data);
        this.lrRecordUpdateStatus = this.removeDuplicates(dataObj);
    }

    removeDuplicates(array) {
        let uniq = [];
        return array.filter(obj => !uniq[obj._id] && (uniq[obj._id] = true))
    }


    getVehicleTripDetais(imeiNumber,mobileNumber) {
       
        let path = AppConstants.get_Medical_Vehicle_Trip_Tracking;
        let reqBody = {};
        if(imeiNumber!= undefined && imeiNumber != ""){
             reqBody = {
                  "imeiNumber": imeiNumber,
                  "mobileNumber" : mobileNumber
                    }
                }else{
             reqBody = {
                   "imeiNumber": imeiNumber,
                   "mobileNumber" : mobileNumber
             };

            }
       
        this.loading = true;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(path, reqBody, '', reqOptions).then((data) => {
           
            this.loading = false;
            this.trip = data.dataList;
            if(imeiNumber!= undefined && imeiNumber != ""){  
           
            }else{
                this.selectedStopIDEle = "";
            }
            // console.log(this.trip); 
             
           // this.scrollToCurrentTrip();
        }).catch((err) => {
            this.loading = false;
        })
    }



   
  
    calcRoute(startLoc, curentlocation, endLoc) {

        let directionsService = new google.maps.DirectionsService();
        let start = new google.maps.LatLng(startLoc.lat, startLoc.lng);
        let curentlatlng = new google.maps.LatLng(curentlocation.lat, curentlocation.lng);
        let end = new google.maps.LatLng(endLoc.lat, endLoc.lng);
        let request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        console.log('yes one')
        directionsService.route(request, (response, status) => {
            // console.log("response == >" + JSON.stringify(response.routes[0].legs[0].distance.text));

            if (status == google.maps.DirectionsStatus.OK) {
                console.log('yes two')
                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
                this.directionsDisplay.setPosition(curentlatlng);
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }
    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }
    

    distanceFrom(oldLatLng, newLatLng) {
        let EarthRadiusMeters = 6378137.0; // meters
        let lat1 = oldLatLng.lat;
        let lon1 = oldLatLng.lng;
        let lat2 = newLatLng.lat;
        let lon2 = newLatLng.lng;
        let dLat = (lat2 - lat1) * Math.PI / 180;
        let dLon = (lon2 - lon1) * Math.PI / 180;
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = EarthRadiusMeters * c;
        return d;

    }

    
    
    


    filterStatus(event) {
        console.log(event)
        let data = this.centralizedLRObject;
        // this.centralizedLRObject = data.filter(item => {
        //   if(item.alertLevel !==null){
        //     console.log("YEs");
        //    return item.alertLevel == event 
        //   } 
        // })
        this.centralizedLRObject = data.filter(item => parseInt(item.alertLevel) === parseInt(event))
        this.centralizedLRObject.forEach(element => {
            if (parseInt(element.alertLevel) === parseInt(event)) {
                console.log(element)
            }
        })
        console.log(this.centralizedLRObject)
    }
    
    doSelectOptions(event) {
        // console.log('doSelectOptions',event)
    }
    doRemove(event) {
        // console.log('doRemove',event)
    }
    doSelectVehical(event) {
        // console.log('doSelectVehical',event)
        this.getTripStatusForVehicleNumber(event)
    }
    getTripStatusForVehicleNumber(vehicalNo) {
        // let headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        // let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_trip_status_for_vehicle_number + vehicalNo, '', '').then(singaleVeicalObj => {
            console.log('singaleVeicalObj', JSON.stringify(singaleVeicalObj))
            this.singaleVeicalArrayObj = singaleVeicalObj;
        }).catch((singaleVeicalObjErr) => {
            console.log("getsingaleVeicalObjErr...", JSON.stringify(singaleVeicalObjErr));
        });
    }
    getFormattedDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;
    }
    onClickCompletTrip(data) {
        if (data.closeTripDate.length === 0) {
            alert("Please Select Trip Compleation Date!")
            return;
        }
        console.log("calling");
        jQuery('#completeTripModal').modal('hide');
        // console.log('data.closeTripDate',JSON.stringify(data));
    }

    daysBetweenTwoDaye(date1, date2) {

        // The number of milliseconds in one day
        const ONE_DAY = 1000 * 60 * 60 * 24;

        // Calculate the difference in milliseconds
        this.expectedReachDate = new Date(date1)
        this.actualReachDate = new Date(date2)
        var differenceMs = Math.abs(this.actualReachDate - this.expectedReachDate);

        //  console.log(this.expectedReachDate)
        //  console.log(this.actualReachDate)
        //  console.log(differenceMs)
        //  console.log(Math.round(differenceMs / ONE_DAY))

        // Convert back to days and return
        return Math.round(differenceMs / ONE_DAY);

    }

    // Function to show the trip Route Details in the popup
    openCompleteTripModal(data) {
        this.rowData = data;
        this.getVehicleTripDetais(this.rowData.imeiNumber,this.rowData.mobileNumber);
        jQuery('#completeTripModal').modal('show');
     //   console.log(data, jQuery('#completeTripModal'))
    }

    onClearClick() {
        this.fromDate = null;
        this.toDate = null;
        this.isPdfNotAvailable = true;
        this.centralizedLRObject = [];
    }
}
