import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-speedViolationReports',
    templateUrl: './speedViolationReports.component.html',
    styleUrls: ['./speedViolationReports.component.scss']
})

export class SpeedViolationReports implements OnInit {

    @ViewChild("mapRef") mapRef: ElementRef;

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    map: any;
    asignedGpsDevice: any = [];
    defalultVehicle:any;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicleNumber: any;
    speedLimit: any = "40";
    dateWiseData: any = {};
    data: any;
    markersArray: any = [];
    googleBounds: any;
    userLocation: any;
    limitSelectionType: any = "0";

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");

        if (!(localStorage.getItem("userLocation") === "undefined")) {
            this.userLocation = JSON.parse(localStorage.getItem("userLocation"));
        }
        this.showMap();
        this.getVehicleList();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicleNumber = this.gpsDevicesArray[0].vehicleNumber;
            this.gpsDevicesArray.forEach(element => {
              this.asignedGpsDevice.push(element.vehicleNumber);
            });
            this.defalultVehicle = this.asignedGpsDevice[0];
        })
    }

    onTodayClick() {
        this.isTodayClicked = !this.isTodayClicked;
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {

        // let body = [];
        // body.push(this.vehicleDetails);
        this.downloadExcelSheet(this.data).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {


        // console.log("pppppp", this.speedLimit);
        // console.log("pppppp", this.selectedVehicleNumber);

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        // this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(AppConstants.get_whole_day_data + this.selectedVehicleNumber + '&toDate=' + this.toDate.formatted + '&fromDate=' + this.fromDate.formatted, '', reqOptions).then((data) => {
            delete data.statusMessage;
            if(data.respDocArray.length===0){
                this.loading = false;
                // Swal.fire({
                //   type: 'error',
                //   title: 'Oops...',
                //   text: 'No Record found',
                //   footer: 'Please Select Other Date'
                // })
                  Swal.fire({
                  title: 'Livepin',
                  text: 'Please Select Other Date!',
                  imageUrl: '../../assets/statusImg/no_recordFond.png',
                  imageWidth: 400,
                  imageHeight: 200,
                  imageAlt: 'Please Select Other Date',
                  animation: false
                })

            } else {
            // console.log(data);
            this.data = data;
            this.isPdfNotAvailable = false;
            this.loading = false;
            this.constructTableData();
            }

        }).catch((err) => {
           let timerInterval
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong please try again later!',
                  timer: 2000,
                  onBeforeOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                    }, 100)
                  },
                  onClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.timer
                  ) {
                    console.log('I was closed by the timer')
                  }
                })
            console.log(err)
            this.loading = false;
        })
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/speedviolation', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    showMap() {
        // navigator.geolocation.getCurrentPosition((position) => {
        //     console.log("Got position", position.coords);
        //     var options = {
        //         center: { lat: position.coords.latitude, lng: position.coords.longitude },
        //         zoom: 15
        //     }
        //     this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // });

        const location = { lat: 12.916476, lng: 77.602750 };
        var options = {
            center: location,
            zoom: 15
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
    }

    constructTableData() {
        this.data.respDocArray.forEach(element => {
            let numberOfSpeedViolations = 0;
            element.waypoints.forEach(waypoint => {
                if (this.limitSelectionType == "1") {
                    if (this.userLocation && this.userLocation.radius && this.userLocation.lat) {
                        if ((waypoint.speed > this.speedLimit) &&
                            (this.distanceFrom(this.userLocation, waypoint) < this.userLocation.radius) &&
                            this.userLocation.isBoundryEnabled &&
                            this.isLatLngInZone(waypoint.lat, waypoint.lng)) {

                            numberOfSpeedViolations++;

                        } else if ((waypoint.speed > this.speedLimit) &&
                            (this.distanceFrom(this.userLocation, waypoint) < this.userLocation.radius)) {
                            if (!this.userLocation.isBoundryEnabled) {
                                numberOfSpeedViolations++;
                            }

                        }
                    } else {
                        if (waypoint.speed > this.speedLimit) {
                            // this.applymarkers(element);
                            numberOfSpeedViolations++;
                        }
                    }
                } else {
                    if (waypoint.speed > this.speedLimit) {
                        // this.applymarkers(element);
                        numberOfSpeedViolations++;
                    }
                }
            });
            element.numberOfSpeedViolations = numberOfSpeedViolations;
        });
    }

    onMapButtonClick(dataItem: any) {
        // console.log("this.userLocation ====" + JSON.stringify(this.userLocation));

        this.googleBounds = new google.maps.LatLngBounds();
        this.clearOverlays();
        dataItem.waypoints.forEach(waypoint => {
            if (this.limitSelectionType == "1") {
                if (this.userLocation && this.userLocation.radius && this.userLocation.lat) {
                    if ((waypoint.speed > this.speedLimit) &&
                        (this.distanceFrom(this.userLocation, waypoint) < this.userLocation.radius) &&
                        this.userLocation.isBoundryEnabled &&
                        this.isLatLngInZone(waypoint.lat, waypoint.lng)) {

                        this.applymarkers(waypoint);
                        this.googleBounds.extend(new google.maps.LatLng(waypoint.lat, waypoint.lng));

                    } else if ((waypoint.speed > this.speedLimit) &&
                        (this.distanceFrom(this.userLocation, waypoint) < this.userLocation.radius)) {
                        if (!this.userLocation.isBoundryEnabled) {
                            this.applymarkers(waypoint);
                            this.googleBounds.extend(new google.maps.LatLng(waypoint.lat, waypoint.lng));
                        }
                    }
                } else {
                    if (waypoint.speed > this.speedLimit) {
                        this.applymarkers(waypoint);
                        this.googleBounds.extend(new google.maps.LatLng(waypoint.lat, waypoint.lng));
                    }
                }

            } else {
                if (waypoint.speed > this.speedLimit) {
                    this.applymarkers(waypoint);
                    this.googleBounds.extend(new google.maps.LatLng(waypoint.lat, waypoint.lng));
                }
            }
        });
        this.map.fitBounds(this.googleBounds);
    }

    applymarkers(locObject) {

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);

        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            // animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {

            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;

                // (<HTMLInputElement>document.getElementById('address')).innerHTML = geo_address;
                let contentString = `<table style="width:200px">
                <tr><td> <b> Present Address : </b></td></tr>
              <tr>
              <td colspan="2" id = "address">`+ address + `</td>
              </tr>
              <tr>
              <td> <b> Speed : ` + locObject.speed + ` km/hr </b></td>
              </tr>
              <tr>
              <td> <b> Time : ` + new Date(locObject.timestamp).toLocaleTimeString(); + ` </b></td>
              </tr>
              </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);
            }).catch((google_error) => {
                // handle errors

            });

            infoWindow.open(this.map, vehicleMarker);

        });
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

    clearOverlays() {
        for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
        }
        this.markersArray.length = 0;
    }

    onSpeedLimitChange() {
        this.showMap();
        if (this.data && this.data.respDocArray && this.data.respDocArray.length > 0) {
            this.constructTableData();
        }
    }

    onVehicleNumberChange() {
        this.showMap();
        this.getTableData();
    }

    distanceFrom(oldLatLng, newLatLng) {

        let EarthRadiusMeters = 6378137.0; // meters
        let lat1 = oldLatLng.lat;
        let lon1 = oldLatLng.lng;
        let lat2 = newLatLng.lat;
        let lon2 = newLatLng.lng;
        let dLat = (lat2 - lat1) * Math.PI / 180;
        let dLon = (lon2 - lon1) * Math.PI / 180;
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = EarthRadiusMeters * c;
        return d.toFixed(2);
    }

    onLimitTypeChange() {
        this.showMap();
        if (this.data && this.data.respDocArray && this.data.respDocArray.length > 0) {
            this.constructTableData();
        }
    }

    isLatLngInZone(lat, lng) {
        let latLngs = [{ lng: 80.434445, lat: 16.294318 },
        { lng: 80.434557, lat: 16.294318 },
        { lng: 80.434654, lat: 16.294292 },
        { lng: 80.434740, lat: 16.294251 },
        { lng: 80.434852, lat: 16.294205 },
        { lng: 80.434960, lat: 16.294158 },
        { lng: 80.435088, lat: 16.294096 },
        { lng: 80.435201, lat: 16.294055 },
        { lng: 80.435341, lat: 16.294009 },
        { lng: 80.435421, lat: 16.293942 },
        { lng: 80.435507, lat: 16.293896 },
        { lng: 80.435603, lat: 16.293834 },
        { lng: 80.435695, lat: 16.293772 },
        { lng: 80.435813, lat: 16.293705 },
        { lng: 80.435909, lat: 16.293654 },
        { lng: 80.435974, lat: 16.293582 },
        { lng: 80.435995, lat: 16.293504 },
        { lng: 80.435952, lat: 16.293427 },
        { lng: 80.435915, lat: 16.293370 },
        { lng: 80.435861, lat: 16.293273 },
        { lng: 80.435823, lat: 16.293201 },
        { lng: 80.435786, lat: 16.293077 },
        { lng: 80.435759, lat: 16.292964 },
        { lng: 80.435732, lat: 16.292814 },
        { lng: 80.435743, lat: 16.292722 },
        { lng: 80.435786, lat: 16.292655 },
        { lng: 80.435872, lat: 16.292603 },
        { lng: 80.435882, lat: 16.292531 },
        { lng: 80.435882, lat: 16.292418 },
        { lng: 80.435850, lat: 16.292305 },
        { lng: 80.435818, lat: 16.292238 },
        { lng: 80.435754, lat: 16.292202 },
        { lng: 80.435668, lat: 16.292197 },
        { lng: 80.435496, lat: 16.292222 },
        { lng: 80.435249, lat: 16.292279 },
        { lng: 80.435040, lat: 16.292325 },
        { lng: 80.434890, lat: 16.292336 },
        { lng: 80.434627, lat: 16.292377 },
        { lng: 80.434343, lat: 16.292408 },
        { lng: 80.434118, lat: 16.292433 },
        { lng: 80.434037, lat: 16.292500 },
        { lng: 80.434005, lat: 16.292567 },
        { lng: 80.433994, lat: 16.292650 },
        { lng: 80.433989, lat: 16.292747 },
        { lng: 80.434016, lat: 16.292861 },
        { lng: 80.434048, lat: 16.292928 },
        { lng: 80.434059, lat: 16.293020 },
        { lng: 80.434091, lat: 16.293103 },
        { lng: 80.434128, lat: 16.293159 },
        { lng: 80.434177, lat: 16.293231 },
        { lng: 80.434230, lat: 16.293298 },
        { lng: 80.434278, lat: 16.293355 },
        { lng: 80.434343, lat: 16.293437 },
        { lng: 80.434375, lat: 16.293515 },
        { lng: 80.434364, lat: 16.293587 },
        { lng: 80.434311, lat: 16.293618 },
        { lng: 80.434241, lat: 16.293659 },
        { lng: 80.434177, lat: 16.293705 },
        { lng: 80.434150, lat: 16.293752 },
        { lng: 80.434166, lat: 16.293813 },
        { lng: 80.434214, lat: 16.293885 },
        { lng: 80.434257, lat: 16.293983 },
        { lng: 80.434295, lat: 16.294071 },
        { lng: 80.434332, lat: 16.294158 },
        { lng: 80.434375, lat: 16.294241 },
        { lng: 80.434407, lat: 16.294297 },
        { lng: 80.434445, lat: 16.294318 }
        ]

        let bermudaTriangle = new google.maps.Polygon({ paths: latLngs });
        return google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), bermudaTriangle);
    }
    doSelectVehical(event) {
      const vehicalNo = this.gpsDevicesArray.filter(vehicle=>vehicle.vehicleNumber === event);
      this.selectedVehicleNumber = vehicalNo[0];
      console.log('doSelectVehical', event);
      console.log('selectedVehicleNumber', this.selectedVehicleNumber);
      this.showMap();
      this.getTableData();
    }
    doNgxDefault(){
      return this.defalultVehicle;
    }

}
