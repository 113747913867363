import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-dumpsReaches',
    templateUrl: './dumpsReaches.component.html',
    styleUrls: ['./dumpsReaches.component.scss']
})

export class DumpsReaches implements OnInit {

    @ViewChild("mapRef") mapRef: ElementRef;

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    map: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    selectedVehicleNumber: any;
    speedLimit: any = "40";
    dateWiseData: any = {};
    data: any;
    markersArray: any = [];
    polyLinesArray: any = [];
    googleBounds: any = new google.maps.LatLngBounds();
    userMobile: any;
    directionsDisplay: any;

    pennaRiverCoordinates = [

        new google.maps.LatLng(14.913304, 77.990663),
        new google.maps.LatLng(14.914092, 77.992208),
        new google.maps.LatLng(14.914216, 77.992229),
        new google.maps.LatLng(14.914652, 77.992294),
        new google.maps.LatLng(14.914569, 77.993538),
        new google.maps.LatLng(14.914423, 77.994353),
        new google.maps.LatLng(14.914755, 77.995255),
        new google.maps.LatLng(14.915108, 77.995619),
        new google.maps.LatLng(14.915294, 77.995619),
        new google.maps.LatLng(14.915751, 77.995941),
        new google.maps.LatLng(14.915460, 77.997057),
        new google.maps.LatLng(14.915543, 77.997906),
        new google.maps.LatLng(14.915709, 77.999108),
        new google.maps.LatLng(14.915937, 77.999966),
        new google.maps.LatLng(14.916144, 78.000803),
        new google.maps.LatLng(14.916497, 78.002112),
        new google.maps.LatLng(14.916841, 78.003202),
        new google.maps.LatLng(14.917111, 78.004254),
        new google.maps.LatLng(14.917401, 78.005455),
        new google.maps.LatLng(14.917878, 78.006271),
        new google.maps.LatLng(14.918396, 78.007096),
        new google.maps.LatLng(14.919101, 78.007804),
        new google.maps.LatLng(14.919910, 78.008533),
        new google.maps.LatLng(14.918873, 78.009907),
        new google.maps.LatLng(14.917360, 78.009306),
        new google.maps.LatLng(14.916509, 78.009027),
        new google.maps.LatLng(14.915970, 78.008619),
        new google.maps.LatLng(14.915556, 78.008040),
        new google.maps.LatLng(14.915203, 78.007160),
        new google.maps.LatLng(14.914996, 78.006366),
        new google.maps.LatLng(14.915037, 78.005229),
        new google.maps.LatLng(14.914954, 78.004092),
        new google.maps.LatLng(14.914851, 78.003104),
        new google.maps.LatLng(14.914664, 78.002268),
        new google.maps.LatLng(14.913918, 78.001066),
        new google.maps.LatLng(14.913290, 78.000132),
        new google.maps.LatLng(14.913041, 77.999424),
        new google.maps.LatLng(14.912896, 77.998566),
        new google.maps.LatLng(14.912792, 77.997536),
        new google.maps.LatLng(14.912772, 77.996368),
        new google.maps.LatLng(14.912834, 77.995488),
        new google.maps.LatLng(14.912979, 77.994480),
        new google.maps.LatLng(14.913124, 77.993621),
        new google.maps.LatLng(14.913290, 77.992892),
        new google.maps.LatLng(14.913228, 77.992055),
        new google.maps.LatLng(14.913249, 77.990660)

    ];

    krishnaRiverCoordinates = [

        new google.maps.LatLng(16.501071, 80.596958),
        new google.maps.LatLng(16.505351, 80.601421),
        new google.maps.LatLng(16.511770, 80.603996),
        new google.maps.LatLng(16.512263, 80.606399),
        new google.maps.LatLng(16.509795, 80.607772),
        new google.maps.LatLng(16.510453, 80.609661),
        new google.maps.LatLng(16.506009, 80.613952),
        new google.maps.LatLng(16.505351, 80.618415),
        new google.maps.LatLng(16.501894, 80.623737),
        new google.maps.LatLng(16.500084, 80.627685),
        new google.maps.LatLng(16.496463, 80.633693),
        new google.maps.LatLng(16.494323, 80.640388),
        new google.maps.LatLng(16.491854, 80.646396),
        new google.maps.LatLng(16.485928, 80.652404),
        new google.maps.LatLng(16.482133, 80.658089),
        new google.maps.LatLng(16.475384, 80.664097),
        new google.maps.LatLng(16.466660, 80.669419),
        new google.maps.LatLng(16.463532, 80.671307),
        new google.maps.LatLng(16.457770, 80.677315),
        new google.maps.LatLng(16.445916, 80.683151),
        new google.maps.LatLng(16.442294, 80.671650),
        new google.maps.LatLng(16.446575, 80.667359),
        new google.maps.LatLng(16.448057, 80.666329),
        new google.maps.LatLng(16.452172, 80.659634),
        new google.maps.LatLng(16.455465, 80.655686),
        new google.maps.LatLng(16.457770, 80.654484),
        new google.maps.LatLng(16.461556, 80.654312),
        new google.maps.LatLng(16.464355, 80.651737),
        new google.maps.LatLng(16.467647, 80.646416),
        new google.maps.LatLng(16.469952, 80.641781),
        new google.maps.LatLng(16.472448, 80.634249),
        new google.maps.LatLng(16.476564, 80.628413),
        new google.maps.LatLng(16.482325, 80.624808),
        new google.maps.LatLng(16.488086, 80.620173),
        new google.maps.LatLng(16.492860, 80.615023),
        new google.maps.LatLng(16.496810, 80.610388),
        new google.maps.LatLng(16.500431, 80.606097),
        new google.maps.LatLng(16.501912, 80.604552),
        new google.maps.LatLng(16.500760, 80.601977),
        new google.maps.LatLng(16.500102, 80.599230),
        new google.maps.LatLng(16.500925, 80.596827)

    ];

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {
        this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true, polylineOptions: { strokeColor: "green" } });
        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobile = localStorage.getItem("userMobile");
        this.showMap();
    }

    getTableData() {

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;

        let reqBody = {
            "userId": this.userMobile
        }
        this.loading = true;
        // console.log("====" + JSON.stringify(reqBody));

        // this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option 
        this.httpService.httpPostPromise(AppConstants.get_history_for_all_vehicles_bw_interval, reqBody, '', reqOptions).then((data) => {
            // console.log("===========" + JSON.stringify(data));
            delete data.statusMessage;
            this.data = data;
            this.isPdfNotAvailable = false;
            this.loading = false;
            // this.constructTableData();
            this.clearOverlays();
            this.data.dataList.forEach(data => {
                data.locationDetails.forEach((locObj, index) => {
                    this.googleBounds.extend(new google.maps.LatLng(locObj.lat, locObj.lng));
                    if (index == 0 || index == data.locationDetails.length - 1) {
                        // this.applymarkers(locObj, data.vehicleNumber);
                    }
                });
                let randomColor = "#000000".replace(/0/g, () => { return (~~(Math.random() * 16)).toString(16); });
                this.drawPolyline(data.locationDetails, randomColor)
            });
            this.map.fitBounds(this.googleBounds);

        }).catch((err) => {

        })
    }

    showMap() {

        const location = { lat: 16.3067, lng: 80.4365 };
        var options = {
            center: location,
            zoom: 7
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // this.showPolygonsOnMap();
        // this.calcRoute();
        //krishna river sand reach mid point
        this.applymarkers({ lat: 16.493096, lng: 80.626315 }, "Krishna River Sand Reach", google.maps.SymbolPath.CIRCLE, "red", { lat: 16.493096, lng: 80.626315 }, 0)
        //krishna river dumps
        this.applymarkers({ lat: 16.493754, lng: 80.161505 }, "Patamata Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)
        this.applymarkers({ lat: 16.478117, lng: 80.619105 }, "Tadapalli Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)
        this.applymarkers({ lat: 16.511859, lng: 80.633868 }, "Governor Peta Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 16.493096, lng: 80.626315 }, 0)

        //PENNA RIVER sand reach mid point
        this.applymarkers({ lat: 14.915456, lng: 78.001847 }, "PENNA RIVERS Sand Reach", google.maps.SymbolPath.CIRCLE, "red", { lat: 14.915456, lng: 78.001847 }, 1)
        //krishna river dumps
        this.applymarkers({ lat: 14.911693, lng: 78.016488 }, "Sunkulamma Palem Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 14.915456, lng: 78.001847 }, 1)
        this.applymarkers({ lat: 14.904773, lng: 77.993465 }, "Sanjeeva Nager Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 14.915456, lng: 78.001847 }, 1)
        this.applymarkers({ lat: 14.943811, lng: 78.025286 }, "Sajjaladinne Dump", google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, "green", { lat: 14.915456, lng: 78.001847 }, 1)
    }

    applymarkers(locObject, vehicleNumber, type, color, sandReachloc, sandReachType) {

        //google.maps.SymbolPath.FORWARD_CLOSED_ARROW

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);

        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            icon: {
                path: type,
                strokeColor: color,
                scale: 6
            },
            animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {

            /*   this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                  let geo_address = google_response.results[0].formatted_address;
  
                  let address = geo_address;
  
                  // console.log("====> kk" + address);
  
  
                  // (<HTMLInputElement>document.getElementById('address')).innerHTML = geo_address;
                 
              }).catch((google_error) => {
                  // handle errors 
  
              }); */


            if (type == 3) {
                this.calcRoute(sandReachloc, locObject, infoWindow, vehicleMarker, vehicleNumber)
            } else if (type == 0) {
                if (sandReachType == 0) {
                    this.drawPolyline(this.krishnaRiverCoordinates, 'red')
                } else {
                    this.drawPolyline(this.pennaRiverCoordinates, 'red')
                }
            }

        });
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

    clearOverlays() {
        /* for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
        }
        this.markersArray.length = 0; */

        /*  this.polyLinesArray.forEach(polyLine => {
             polyLine.setMap(null);
         }); */

        if (this.directionsDisplay != null) {
            this.directionsDisplay.setMap(null);
        }
    }

    calcRoute(startLoc, endLoc, infoWindow, vehicleMarker, vehicleNumber) {

        this.clearOverlays();
        let directionsService = new google.maps.DirectionsService();
        let start = new google.maps.LatLng(startLoc.lat, startLoc.lng);
        let end = new google.maps.LatLng(endLoc.lat, endLoc.lng);
        let request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, (response, status) => {
            // console.log("response == >" + JSON.stringify(response.routes[0].legs[0].distance.text));

            if (status == google.maps.DirectionsStatus.OK) {

                let contentString = `<table style="width:200px">
                <tr>
                <td> <b>` + vehicleNumber + ` </b></td>
                </tr>
                <tr>
                <td> <b> Distance : ` + response.routes[0].legs[0].distance.text + ` </b></td>
                </tr>
                <tr>
                <td> <b> Time : ` + response.routes[0].legs[0].duration.text + ` </b></td>
                </tr>
                <tr>
                <td> <b> Available Vehicles : ` + 12 + ` </b></td>
                </tr>
                </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);


                this.directionsDisplay.setDirections(response);
                this.directionsDisplay.setMap(this.map);
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }


    drawPolyline(PolylineCoords, color) {
        // console.log("hiiiii 4444");
        this.clearOverlays();

        let historyPolyLine = new google.maps.Polyline({
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 3,
            path: PolylineCoords,
            clickable: false
        });
        historyPolyLine.setMap(this.map);
        this.polyLinesArray.push(historyPolyLine)
    }

}
