import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import $ from 'jquery'
// import * as Tesseract from 'tesseract.js';
declare var google: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  @ViewChild("mapRef") mapRef: ElementRef;
  constructor() { }

  ngOnInit() {
    this.showMap();
    /* let filename = 'assets/img/abcdefg.jpg'
    Tesseract.recognize(filename)
      .progress(function (p) { console.log('progress', p) })
      .catch(err => console.error(err))
      .then(function (result) {
        console.log("result ======<<<<>>>>>");
        console.log(result.text)
      }) */
  }

  showMap() {
    // console.log(this.mapRef.nativeElement);
    const location = { lat: 12.916476, lng: 77.602750 };
    var options = {
      center: location,
      zoom: 15
    }

    const map = new google.maps.Map(this.mapRef.nativeElement, options);
    this.addMarket(map, location);
  }
  addMarket(map, pos) {
    return new google.maps.Marker({
      position: pos,
      map: map,
    });
  }

  ngAfterViewInit() {
    var Messenger = function (el) {
      'use strict';
      var m = this;

      m.init = function () {
        m.codeletters = "&#*+%?£@§$";
        m.message = 0;
        m.current_length = 0;
        m.fadeBuffer = false;
        m.messages = [
          'We are all now connected by the Internet, like neurons in a giant brain.'
        ];

        setTimeout(m.animateIn, 100);
      };

      m.generateRandomString = function (length) {
        var random_text = '';
        while (random_text.length < length) {
          random_text += m.codeletters.charAt(Math.floor(Math.random() * m.codeletters.length));
        }

        return random_text;
      };

      m.animateIn = function () {
        if (m.current_length < m.messages[m.message].length) {
          m.current_length = m.current_length + 2;
          if (m.current_length > m.messages[m.message].length) {
            m.current_length = m.messages[m.message].length;
          }

          var message = m.generateRandomString(m.current_length);
          $(el).html(message);

          setTimeout(m.animateIn, 20);
        } else {
          setTimeout(m.animateFadeBuffer, 20);
        }
      };

      m.animateFadeBuffer = function () {
        if (m.fadeBuffer === false) {
          m.fadeBuffer = [];
          for (var i = 0; i < m.messages[m.message].length; i++) {
            m.fadeBuffer.push({ c: (Math.floor(Math.random() * 12)) + 1, l: m.messages[m.message].charAt(i) });
          }
        }

        var do_cycles = false;
        var message = '';

        for (var i = 0; i < m.fadeBuffer.length; i++) {
          var fader = m.fadeBuffer[i];
          if (fader.c > 0) {
            do_cycles = true;
            fader.c--;
            message += m.codeletters.charAt(Math.floor(Math.random() * m.codeletters.length));
          } else {
            message += fader.l;
          }
        }

        $(el).html(message);

        if (do_cycles === true) {
          setTimeout(m.animateFadeBuffer, 50);
        } else {
          setTimeout(m.cycleText, 5000);
        }
      };

      m.cycleText = function () {
        m.message = m.message + 1;
        if (m.message >= m.messages.length) {
          m.message = 0;
        }

        m.current_length = 0;
        m.fadeBuffer = false;
        $(el).html('');

        setTimeout(m.animateIn, 200);
      };

      m.init();
    }

    console.clear();
    var messenger = new Messenger($('#messenger'));
  }

}
