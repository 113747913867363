import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-acUsageReports',
    templateUrl: './acUsageReports.component.html',
    styleUrls: ['./acUsageReports.component.scss']
})

export class AcUsageReports implements OnInit {

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;




    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicle: any;
    dashboardData: any;

    tripSummaryData: any = [];
    address: any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getVehicleList();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            // this.imeiNumber = this.gpsDevicesArray[0].imeiNumber;
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
            // console.log('--------------selectedVehicleNumber>>>>', this.selectedVehicleNumber);
            // console.log('--------------imeiNumber>>>>', this.imeiNumber);
        })
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {
        // console.log("this.dashboardData ===>>>" + JSON.stringify(this.dashboardData));

        let value = [];
        this.dashboardData.forEach(tripDateObj => {
            tripDateObj.startStopList.forEach(trip => {
                let subValueArray = [];
                subValueArray.push(tripDateObj.date);
                subValueArray.push(trip.startTime);
                subValueArray.push(trip.endTime);
                subValueArray.push(trip.acDuration);
                value.push(subValueArray);
            });

        });

        let headerName = "AC Usage Report (" + this.fromDate.formatted + " to " + this.toDate.formatted + ")";

        let body = {
            "headerName": headerName,
            "excelName": "acUsageReport",
            "header": [
                "Date (M/D/Y)",
                "AC Switch On Time",
                "AC Switch Off Time",
                "AC Duration"
            ],
            "value": value
        }
        // console.log("body ====" + JSON.stringify(body));

        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
        this.dashboardData = null;
    }

    // todaysDate() {
    //     var d = new Date(),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;

    //     return [year, month, day].join('-');
    // }

    async getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        const dashBoardParams = {
            fromDate: this.fromDate.formatted,
            toDate: this.toDate.formatted,
            imeiNumber: this.selectedVehicle.imeiNumber
        }

        // console.log("dashBoardParams ===" + JSON.stringify(dashBoardParams));

        try {
            let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
            let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
            let dashboardData = await this.httpService.httpPostPromise(AppConstants.get_ac_usage_report, dashBoardParams, '', reqOptions);
            // console.log(JSON.stringify(dashboardData));
            this.isPdfNotAvailable = false;
            if (dashboardData && dashboardData.statusCode === 200) {
                this.dashboardData = dashboardData.result;
                for (const acstatus of this.dashboardData) {
                    acstatus.startStopList.forEach(async acusage => {
                        acusage.startTime = this.convertToLocalStringFormatHhMmSs(new Date(acusage.startTime));
                        acusage.endTime = this.convertToLocalStringFormatHhMmSs(new Date(acusage.endTime));
                    });
                }
                this.loading = false;
            }
        } catch (err) {
            this.loading = false;
        }
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/genericxls', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange(device) {
        this.selectedVehicle = device;
        this.getTableData();
    }

    convertToLocalStringFormatHhMmSs(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        // minutes = minutes < 10 ? '0'+minutes : minutes;
        minutes = ('0' + minutes).slice(-2);
        seconds = ('0' + seconds).slice(-2);
        // let localTimeFormat = hours + ':' + minutes + ' ' + ampm;
        let localTimeFormat = `${hours}:${minutes}:${seconds} ${ampm}`;
        return localTimeFormat;
    }

    // local josn data checking for local reference
    // getAcUsageReport() {
    //     this.httpService.getAcUsage().subscribe((acusage) => {
    //         console.log('***********************************************************************');
    //         console.log(acusage);
    //     })
    // }
}
