import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';
import { VehicleIcons } from '../base/VehicleIcons';
import { AnimateHelpers } from '../base/animatehelpers';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-dailyStopageReport',
    templateUrl: './dailyStopageReport.component.html',
    styleUrls: ['./dailyStopageReport.component.scss']
})

export class DailyStopageReport implements OnInit {

    @ViewChild("mapRef") mapRef: ElementRef;

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    selectedFromTime: any;
    selectedToTime: any;
    map: any;
    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    selectedVehicle: any;
    speedLimit: any = "40";
    dateWiseData: any = {};
    data: any;
    markersArray: any = [];
    polyLinesArray: any = [];
    gpsDevicesArray: any = [];
    googleBounds: any = new google.maps.LatLngBounds();
    userMobile: any;
    totalDistanceTravelled: any = 0;
    isPauseVisible: boolean = false;
    totalStop: any
    stopageTimeDuration: any;
    butDisabled: boolean = true;
    asignedGpsDevice: any = [];
    defalultVehicle:any;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
    };

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobile = localStorage.getItem("userMobile");
        this.showMap();
        this.getVehicleList();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
            this.gpsDevicesArray.forEach(element => {
              this.asignedGpsDevice.push(element.vehicleNumber);
            });
            this.defalultVehicle = this.asignedGpsDevice[0];
        })
    }


    onTodayClick() {
        this.isTodayClicked = !this.isTodayClicked;
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
        this.stopageTimeDuration = '';
        this.butDisabled = true;
        this.showMap();
        this.selectedVehicle = '';
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            Swal.fire({
                type: 'info',
                title: 'Oops...',
                text: 'Please select  Date first!'
            })
            // alert('Please select From Date first');
            return;
        }

        // if (!(this.toDate && this.toDate.formatted)) {
        //     alert('Please select To Date first');
        //     return;
        // }

        // if (!(this.selectedFromTime)) {
        //     alert('Please select From Time first');
        //     return;
        // }

        // if (!(this.selectedToTime)) {
        //     alert('Please select To Time first');
        //     return;
        // }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;

        // let reqBody = {
        //     "userId": this.userMobile,
        //     "fromDate": this.fromDate.formatted,
        //     "toDate": this.toDate.formatted,
        //     "fromTimeStamp": this.selectedFromTime,
        //     "toTimeStamp": this.selectedToTime,
        //     "imeiNumber": this.selectedVehicle.imeiNumber,
        // }
        let reqBody = {
            "imeiNumber": this.selectedVehicle.imeiNumber,
            "historyDate": this.getFormattedDate(new Date(this.fromDate.formatted))
        }
        this.loading = true;
        // console.log("====" + JSON.stringify(reqBody));

        // this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.post_get_generic_stoppage_report, reqBody, '', reqOptions).then((data) => {
            data.forEach(stopDuration => {
                stopDuration.stopDurationTime = Math.round(stopDuration.vehicleStoppageTime / (60 * 1000));
                // stopDuration.stopDurationTime =   (stopDuration.vehicleStoppageTime / (60 * 1000)).toFixed(2);
            })
            // console.log("generic_stoppage_report===========>" + JSON.stringify(data));
            delete data.statusMessage;
            this.data = data;
            this.butDisabled = false;
            this.isPdfNotAvailable = false;
            this.loading = false;
            this.clearOverlays();
            this.totalStop = data.length;
            // console.log(data.length);
            this.googleBounds = new google.maps.LatLngBounds();
            this.data.forEach(data => {
                let objofLatAndLng = {
                    lat: data.lat,
                    lng: data.lng
                }
                this.googleBounds.extend(new google.maps.LatLng(data.lat, data.lng));
                this.applymarkers(objofLatAndLng, data.vehicleStoppedAt, data.vehicleStartedAt, data.vehicleStoppageTime);
            });
            this.map.fitBounds(this.googleBounds);

        }).catch((err) => {
            if (err.status === 500) {
                Swal.fire({
                    title: 'Livepin',
                    text: 'Please Select Other Date!',
                    imageUrl: '../../assets/statusImg/no_recordFond.png',
                    imageWidth: 400,
                    imageHeight: 200,
                    imageAlt: 'Please Select Other Date',
                    animation: false
                })
            } else {
                let timerInterval
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong please try again later!',
                    timer: 2000,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                        }, 100)
                    },
                    onClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.timer
                    ) {
                        console.log('I was closed by the timer')
                    }
                })
            }
            console.log(err)
            this.loading = false;
        })
    }

    showMap() {

        const location = { lat: 12.916476, lng: 77.602750 };
        var options = {
            center: location,
            zoom: 15
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
    }

    applymarkers(locObject, vehicleStoppedAt, vehicleStartedAt, stoppageTime) {

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);

        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            // animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {
            this.getAddressFromGoogle(locObject.lat, locObject.lng).then((google_response) => {
                let geo_address = google_response.results[0].formatted_address;

                let address = geo_address;
                let vehicleStoppageTime = (stoppageTime / (60 * 1000)).toFixed(2);
                let timeInterval = new Date(vehicleStoppedAt).toLocaleTimeString() + " - " + new Date(vehicleStartedAt).toLocaleTimeString();

                // console.log("====> kk" + address);
                // <tr>
                // <td colspan="2" id = "address">`+ totalDistance + `</td>
                // </tr>
                //<tr><td> <b> Total Distance : </b></td></tr>

                // (<HTMLInputElement>document.getElementById('address')).innerHTML = geo_address;
                let contentString = `
                <table style="width:250px">
                  <tr>
                      <td width="80px" style="vertical-align: top;">
                           <b style="font-weight: bold;"> StopTime : </b>
                       </td>

                      <td>
                          <b>${timeInterval}</b>
                      </td>
                  </tr>
                  <tr>
                      <td style="vertical-align: top;">
                          <b style="font-weight: bold;"> Duration :</b>
                       </td>
                      <td>
                          <b> ${vehicleStoppageTime} minutes</b>
                       </td>
                  </tr>
                  <tr>
                      <td style="vertical-align: top;">
                           <b style="font-weight: bold;">  Address :</b>
                       </td>
                      <td>
                          <b> ${address}</b>
                      </td>
                  </tr>
                </table>`;
                infoWindow.setContent(contentString);
                infoWindow.open(this.map, vehicleMarker);
            }).catch((google_error) => {
                // handle errors

            });

            infoWindow.open(this.map, vehicleMarker);

        });
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

    clearOverlays() {
        for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
        }
        this.markersArray.length = 0;

        // this.polyLinesArray.forEach(polyLine => {
        //     polyLine.setMap(null);
        // });
    }


    drawPolyline(PolylineCoords, color) {
        // console.log("hiiiii 4444");

        let historyPolyLine = new google.maps.Polyline({
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 3,
            path: PolylineCoords,
            clickable: false
        });
        historyPolyLine.setMap(this.map);
        this.polyLinesArray.push(historyPolyLine)
    }

    onVehicleNumberChange(device) {
        this.showMap();
        this.selectedVehicle = device;
        this.getTableData();
    }

    calculateTotalDistanceFromHaversineFormula(locationDetails) {
        if (locationDetails.length >= 3) {
            // locationDetails.sort((a, b) => Number(new Date(a.timestamp).getTime()) - Number(new Date(b.timestamp).getTime()));
            let lengthOfLocationDetails = locationDetails.length;

            let totalDistance = 0;
            for (let i = 0; i < lengthOfLocationDetails - 2; i++) {
                let currentLatLng = locationDetails[i];
                let nextLatLng = locationDetails[i + 1];
                totalDistance += this.distanceFrom(currentLatLng, nextLatLng);
            }
            let totalDistanceInKms = (totalDistance / 1000).toFixed(2);

            return totalDistanceInKms;
        }
        else {
            return;
        }
    }
    distanceFrom(oldLatLng, newLatLng) {
        let EarthRadiusMeters = 6378137.0; // meters
        let lat1 = oldLatLng.lat;
        let lon1 = oldLatLng.lng;
        let lat2 = newLatLng.lat;
        let lon2 = newLatLng.lng;
        let dLat = (lat2 - lat1) * Math.PI / 180;
        let dLon = (lon2 - lon1) * Math.PI / 180;
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = EarthRadiusMeters * c;
        return d;

    }

    getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }
    onstopageTimeDuration() {
        this.butDisabled = false;
        this.showMap();
        if (this.data && this.data.length > 0) {
            this.googleBounds = new google.maps.LatLngBounds();
            let stopageCount = 0
            this.totalStop = stopageCount;
            this.data.forEach(stopTimeFilter => {

                if (stopTimeFilter.stopDurationTime !== 'NaN') {
                    if (stopTimeFilter.stopDurationTime > this.stopageTimeDuration) {
                        // console.log('Time', this.stopageTimeDuration);
                        // console.log('Stop',stopTimeFilter.stopDurationTime);
                        this.totalStop = ++stopageCount;
                        // console.log('this.totalStop.length', this.totalStop);

                        let objofLatAndLng = {
                            lat: stopTimeFilter.lat,
                            lng: stopTimeFilter.lng
                        }
                        // console.log('objofLatAndLng', objofLatAndLng)
                        this.googleBounds.extend(new google.maps.LatLng(stopTimeFilter.lat, stopTimeFilter.lng));
                        this.applymarkers(objofLatAndLng, stopTimeFilter.vehicleStoppedAt, stopTimeFilter.vehicleStartedAt, stopTimeFilter.vehicleStoppageTime);
                        this.map.fitBounds(this.googleBounds);
                    }
                }

            })
        }
    }

    convert() {

        let promiseList = [];
        let value = [];
        this.data.forEach(data => {
            promiseList.push(this.getAddressFromGoogle(data.lat, data.lng));
        });

        Promise.all(promiseList).then((returnedValues) => {
            returnedValues.forEach((adressResp, index) => {
                let geo_address = adressResp.results[0].formatted_address;
                this.data[index].address = geo_address;
            });
            this.data.forEach(eachLoc => {
                let subValueArray = [];
                subValueArray.push(new Date(eachLoc.vehicleStoppedAt).toString().split(" ")[4]);
                subValueArray.push(new Date(eachLoc.vehicleStartedAt).toString().split(" ")[4]);
                subValueArray.push(eachLoc.stopDurationTime);
                subValueArray.push(eachLoc.address);
                value.push(subValueArray);
            });


            let headerName = "Daily Stoppage Report (" + new Date(this.data[0].vehicleStoppedAt).toDateString() + ")";
            let body = {
                "headerName": headerName,
                "excelName": "dailyStoppageReport",
                "header": [
                    "Vehicle Stop Time",
                    "Vehicle Start Time",
                    "Stop Duration",
                    "Address"
                ],
                "value": value
            }
            // console.log("body ====" + JSON.stringify(body));
            this.downloadExcelSheet(body).subscribe(blob => {
                // Doing it this way allows you to name the file
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Report.xlsx";
                link.click();
            }, error => console.log("Error downloading the file."),
                () => console.log('Completed file download.'));
        });



    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/genericxls', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }
    doSelectVehical(event) {
      const vehicalNo = this.gpsDevicesArray.filter(vehicle=>vehicle.vehicleNumber === event);
      this.selectedVehicle = vehicalNo[0];
      console.log('doSelectVehical', event);
      console.log('selectedVehicle', this.selectedVehicle);
      this.showMap();
      this.getTableData();
    }
    doNgxDefault(){
      return this.defalultVehicle;
    }

}
