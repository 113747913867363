import { MedicalTripTracker } from "./medicalTripTracker/medicalTripTracker.component";
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { LivePinComponent } from './livePin/livePin';
import { LoginComponent } from './login/login.component';
import { AdminLivePinHome } from './adminLivePinHome/adminLivePinHome.component';
import { SpeedViolationReports } from './speedViolationReports/speedViolationReports.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { VehicleStoppageReports } from './vehicleStoppageReport/vehicleStoppageReports.component';
import { DailySummaryReports } from './dailySummaryReports/dailySummaryReports.component';
import { FuelSummaryReports } from './fuelSummaryReports/fuelSummaryReports.component';
import { DestinationReports } from './destinationReports/destinationReports.component';
import { DeviceAssignComponent } from './device-assign/device-assign.component';
import { AisDeviceDashboardComponent } from './aisdevicedashboard/aisdevicedashboard.component';
import { DeviationTrackingReports } from './deviationTrackingReports/deviationTrackingReports.component';
import { VehicleAndClusterPlanner } from './vehicleAndClusterPlanner/vehicleAndClusterPlanner.component';
import { VehicleHistorySummaryReport } from './vehicleHistorySummary/vehicleHistorySummary.component';
import { TripSummaryReports } from './tripSummaryReports/tripSummaryReports.component';
import { AllVehicleHistoryReport } from './allVehicleHistory/allVehicleHistory.component';
import { SingleVehicleHistoryReport } from './singleVehicleHistory/singleVehicleHistory.component';
import { DeviceInstallationReports } from './deviceInstallationReport/deviceInstallationReport.component';
import { AcUsageReports } from './acUsageReports/acUsageReports.component';
import { LorryReceiptCreation } from './lorryReceiptCreation/lorryReceiptCreation.component';
import { LorryReceiptHistory } from './lorryReceiptHistory/lorryReceiptHistory.component';
import { DashBoard } from './dashBoard/dashBoard.component';
import { DumpsReaches } from './dumpsReaches/dumpsReaches.component';
import { SandAvalabity } from './sandAvalabity/sandAvalabity.component';
import { TripDetails } from './tripDetails/tripDetails.component';
import { VehicleTracking } from './vehicleTracking/vehicleTracking.component';
import { Contacts } from './contacts/contacts.component';
import { TrackYourOrder } from './trackYourOrder/trackYourOrder.component';
import { PlantEntry } from './plantEntry/plantEntry.component';
import { DailyStopageReport } from './dailyStopageReport/dailyStopageReport.component';
import { IntervalBandLocation } from './intervalBandLocation/intervalBandLocation.component';
import { DeviceRequestComponent } from './device-request/device-request.component';
import { CentralizedLRComponent } from './centralizedLR/centralizedLR.component';
import { MedicalTripSummaryReportComponent } from './medical-trip-summary-report/medical-trip-summary-report.component';
import { ClientClusteringComponent } from './client-clustering/client-clustering.component';
const routes: Routes = [
  { path: 'user-profile', component: ProfileComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'nucleoicons', component: NucleoiconsComponent },
  { path: 'livepin', component: LivePinComponent },
  { path: 'login', component: LoginComponent },
  { path: 'adminLivePinHome', component: AdminLivePinHome },
  { path: 'speedViolationReports', component: SpeedViolationReports },
  { path: 'sideBarHome', component: SidebarComponent },
  { path: 'vehicleStoppageReports', component: VehicleStoppageReports },
  { path: 'dailySummaryReports', component: DailySummaryReports },
  { path: 'fuelSummaryReports', component: FuelSummaryReports },
  { path: 'destinationReports', component: DestinationReports },
  { path: 'deviceAssign', component: DeviceAssignComponent },
  { path: 'AisDashboard', component: AisDeviceDashboardComponent },

  { path: 'OverSpeed', component: SpeedViolationReports },
  { path: 'Stopage', component: VehicleStoppageReports },
  { path: 'Deviation', component: DeviationTrackingReports },
  { path: 'Muncipality', component: AdminLivePinHome },
  { path: 'DailySummary', component: DailySummaryReports },
  { path: 'VehicleAndClusterPlanner', component: VehicleAndClusterPlanner },
  { path: 'vehicleHistorySummary', component: VehicleHistorySummaryReport },
  { path: 'tripSummary', component: TripSummaryReports },
  { path: 'allVehicleHistory', component: AllVehicleHistoryReport },
  { path: 'singleVehicleHistory', component: SingleVehicleHistoryReport },
  { path: 'deviceInstallationReport', component: DeviceInstallationReports },
  { path: 'acUsageReport', component: AcUsageReports },
  { path: 'FuelSummary', component: FuelSummaryReports },
  { path: 'createSourceDestination', component: DestinationReports },
  { path: 'lRCreation', component: LorryReceiptCreation },
  { path: 'lRHistory', component: LorryReceiptHistory },
  { path: 'Dashboard', component: DashBoard },
  { path: 'DumpsAndReaches', component: DumpsReaches },
  { path: 'SandAvailability', component: SandAvalabity },
  { path: 'SandTripDetails', component: TripDetails },
  { path: 'SandVehicleTrack', component: VehicleTracking },
  { path: 'Contacts', component: Contacts },
  { path: 'TrackYourOrder', component: TrackYourOrder },
  { path: 'PlantEntry', component: PlantEntry },
  { path: 'DailyStopageReport', component: DailyStopageReport },
  { path: 'IntervalBandLocation', component: IntervalBandLocation },
  { path: 'DeviceRequest', component: DeviceRequestComponent },
  { path: 'AISDeviceDashboard', component: AisDeviceDashboardComponent },
  { path: 'DeviceAssign', component: DeviceAssignComponent },
  { path: 'CentralizedLR', component: CentralizedLRComponent },
  { path: 'MedicalTripTracker', component: MedicalTripTracker },
  { path: 'MedicalTripSummaryReport', component: MedicalTripSummaryReportComponent },
  { path: 'ClientClustering', component: ClientClusteringComponent },

  { path: '', redirectTo: 'landing', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
