import {AbstractControl} from '@angular/forms'
import {Injectable} from '@angular/core'

@Injectable()
export class RegexValidations {

    constructor() {}

    validateMobileNumber(control: AbstractControl)
    {
      
      // let mobileNumberRegex = /^[7-9][0-9]{9}$/;
      let mobileNumberRegex = /^[0-9]{10}$/;
      
       if(!mobileNumberRegex.exec(control.value))
       {
         
         return {invalidMobileNumber :  true}
       }
       return null;
    }

    validatePincode(control: AbstractControl)
    {
      
      // let mobileNumberRegex = /^[7-9][0-9]{9}$/;
      let pincodeRegex = /^[0-9]{6}$/;
      
       if(!pincodeRegex.exec(control.value))
       {
         
         return {invalidPincode :  true}
       }
       return null;
    }

    validateEmailId(control: AbstractControl)
    {
        let emailIdRegex = /^\w+@\w+\.\w+$/;
        
         if(!emailIdRegex.exec(control.value))
         {
           
           return {invalidEmailId :  true}
         }
         return null;
    }

    validateOtpCode(control: AbstractControl)
    {
      
      // let mobileNumberRegex = /^[7-9][0-9]{9}$/;
      let otpCodeRegex = /^[0-9]{6}$/;
      
       if(!otpCodeRegex.exec(control.value))
       {
         
         return {invalidOTPNumber :  true}
       }
       return null;
    }
}