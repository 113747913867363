import { Component, OnInit } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-adminLivePinHome',
    templateUrl: './adminLivePinHome.component.html',
    styleUrls: ['./adminLivePinHome.component.scss']
})

export class AdminLivePinHome implements OnInit {

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    data: any;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {
    }

    onTodayClick() {
        this.isTodayClicked = !this.isTodayClicked;
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {

        let body = [];
        body.push(this.vehicleDetails);
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        // console.log(this.isTodayClicked);

        if (this.isTodayClicked) {
            this.fromDate = this.todaysDate();
            this.toDate = this.todaysDate();
            this.isSubmitClick = true;
            this.isPdfNotAvailable = true;
            this.loading = true;

            this.httpService.httpGetPromise(this.urlFormatter.format(AppConstants.today_get_munispality_table_records, this.fromDate, this.toDate), "")
                .then(response => {
                    this.data = response;
                    if(this.data.result.length === 0){
                        Swal.fire({
                          title: 'Livepin',
                          text: 'Please Select Other Date!',
                          imageUrl: '../../assets/statusImg/no_recordFond.png',
                          imageWidth: 400,
                          imageHeight: 200,
                          imageAlt: 'Today',
                          animation: false
                        })
                        this.loading = false;

                    } else {
                        // console.log(JSON.stringify(response));
                        this.vehicleDetails = response;
                        this.isPdfNotAvailable = false;
                        this.loading = false;
                    }
                }).catch(loginError => {
                    let timerInterval
                    Swal.fire({
                      type: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong please try again later!',
                      timer: 2000,
                      onBeforeOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                        }, 100)
                      },
                      onClose: () => {
                        clearInterval(timerInterval)
                      }
                    }).then((result) => {
                      if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.timer
                      ) {
                        console.log('I was closed by the timer')
                      }
                    })
                console.log(loginError)
                this.loading = false;
                });

        } else {

            if (!(this.fromDate && this.fromDate.formatted)) {
                alert('Please select From Date first or Click today checkbox');
                return;
            }

            if (!(this.toDate && this.toDate.formatted)) {
                alert('Please select From Date first or Click today checkbox');
                return;
            }

            this.isSubmitClick = true;
            this.isPdfNotAvailable = true;
            this.loading = true;

            this.httpService.httpGetPromise(this.urlFormatter.format(AppConstants.get_munispality_table_records, this.fromDate.formatted, this.toDate.formatted), "")
                .then(response => {
                    this.data = response;
                    if(response.message ==='Failed' || response.dayWiseTripSummary.length ===0){
                         Swal.fire({
                          title: 'Livepin',
                          text: 'Please Select Other Date!',
                          imageUrl: '../../assets/statusImg/no_recordFond.png',
                          imageWidth: 400,
                          imageHeight: 200,
                          imageAlt: 'Please Select Other Date',
                          animation: false
                        })
                        this.loading = false;
                    } else{ 
                            // console.log('response else',JSON.stringify(response));
                            this.vehicleDetails.result = response.dayWiseTripSummary;
                            this.isPdfNotAvailable = false;
                            this.loading = false;
                           }

                }).catch(loginError => {
                    let timerInterval
                    Swal.fire({
                      type: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong please try again later!',
                      timer: 2000,
                      onBeforeOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                        }, 100)
                      },
                      onClose: () => {
                        clearInterval(timerInterval)
                      }
                    }).then((result) => {
                      if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.timer
                      ) {
                        console.log('I was closed by the timer')
                      }
                    })
                console.log(loginError)
                this.loading = false;
                });
        }
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/tripsdetails', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

}
