import { MedicalTripTracker } from "./medicalTripTracker/medicalTripTracker.component";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { SelectModule } from 'ng2-select';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { NgxSelectModule } from 'ngx-select-ex';
import { DropdownModule } from 'primeng/primeng';
import { FileUploadModule } from 'primeng/primeng';

import { AccordionModule } from 'primeng/primeng';
import { MenuItem } from 'primeng/primeng';
import { DataTableModule, SharedModule } from 'primeng/primeng';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';


import { HomeModule } from './home/home.module';
import { LivePinComponent } from './livePin/livePin';
import { LoginComponent } from './login/login.component';
import { HttpService } from './base/http.service';
import { RegexValidations } from './base/regexvalidations';
import { AppConstants } from './base/appconstants';
import { HttpModule } from '@angular/http';
import { MyDatePickerModule } from 'mydatepicker';
import { AdminLivePinHome } from './adminLivePinHome/adminLivePinHome.component';
import { URLStringFormatter } from './base/url-string-formatter';
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { SpeedViolationReports } from './speedViolationReports/speedViolationReports.component';
import { SidebarComponent } from '../../src/app/shared/sidebar/sidebar.component';
import { VehicleStoppageReports } from './vehicleStoppageReport/vehicleStoppageReports.component';
import { DeviationTrackingReports } from './deviationTrackingReports/deviationTrackingReports.component';
import { DailySummaryReports } from './dailySummaryReports/dailySummaryReports.component';
import { VehicleAndClusterPlanner } from './vehicleAndClusterPlanner/vehicleAndClusterPlanner.component';
import { VehicleHistorySummaryReport } from './vehicleHistorySummary/vehicleHistorySummary.component';
import { TripSummaryReports } from './tripSummaryReports/tripSummaryReports.component';
import { AllVehicleHistoryReport } from './allVehicleHistory/allVehicleHistory.component';
import { SingleVehicleHistoryReport } from './singleVehicleHistory/singleVehicleHistory.component';
import { DeviceInstallationReports } from './deviceInstallationReport/deviceInstallationReport.component';
import { AcUsageReports } from './acUsageReports/acUsageReports.component';
import { FuelSummaryReports } from './fuelSummaryReports/fuelSummaryReports.component';
import { DestinationReports } from './destinationReports/destinationReports.component';
import { LorryReceiptCreation } from './lorryReceiptCreation/lorryReceiptCreation.component';
import { LorryReceiptHistory } from './lorryReceiptHistory/lorryReceiptHistory.component';
import { DashBoard } from './dashBoard/dashBoard.component';
import { SandAvalabity } from './sandAvalabity/sandAvalabity.component';
import { DumpsReaches } from './dumpsReaches/dumpsReaches.component';
import { Contacts } from './contacts/contacts.component';
import { TrackYourOrder } from './trackYourOrder/trackYourOrder.component';
import { TripDetails } from './tripDetails/tripDetails.component';
import { VehicleTracking } from './vehicleTracking/vehicleTracking.component';
import { PlantEntry } from './plantEntry/plantEntry.component';
import { DailyStopageReport } from './dailyStopageReport/dailyStopageReport.component';
import { IntervalBandLocation } from './intervalBandLocation/intervalBandLocation.component';
import { DeviceRequestComponent } from './device-request/device-request.component';
import { DeviceAssignComponent } from './device-assign/device-assign.component';
import { AisDeviceDashboardComponent } from './aisdevicedashboard/aisdevicedashboard.component';
import { CentralizedLRComponent } from './centralizedLR/centralizedLR.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MedicalTripSummaryReportComponent } from './medical-trip-summary-report/medical-trip-summary-report.component';
import { ClientClusteringComponent } from './client-clustering/client-clustering.component';
@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LivePinComponent,
    LoginComponent,
    AdminLivePinHome,
    SpeedViolationReports,
    SidebarComponent,
    VehicleStoppageReports,
    DeviationTrackingReports,
    DailySummaryReports,
    VehicleAndClusterPlanner,
    VehicleHistorySummaryReport,
    TripSummaryReports,
    AllVehicleHistoryReport,
    SingleVehicleHistoryReport,
    DeviceInstallationReports,
    AcUsageReports,
    FuelSummaryReports,
    DestinationReports,
    LorryReceiptCreation,
    LorryReceiptHistory,
    DashBoard,
    SandAvalabity,
    DumpsReaches,
    Contacts,
    TrackYourOrder,
    TripDetails,
    VehicleTracking,
    PlantEntry,
    DailyStopageReport,
    IntervalBandLocation,
    AisDeviceDashboardComponent,
    DeviceRequestComponent,
    DeviceAssignComponent,
    CentralizedLRComponent,
    MedicalTripTracker,
    MedicalTripSummaryReportComponent,
    ClientClusteringComponent
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    MyDatePickerModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    SelectModule,
    SelectDropDownModule,
    NgxSelectModule,
    AccordionModule,
    BrowserAnimationsModule,
    DataTableModule,
    SharedModule,
    DropdownModule,
    FileUploadModule,
    NgxMaterialTimepickerModule,
    NgMultiSelectDropDownModule.forRoot()

  ],
  providers: [HttpService, RegexValidations, AppConstants, URLStringFormatter],
  bootstrap: [AppComponent]
})
export class AppModule { }
