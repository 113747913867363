export class AppConstants {

    // Dev Environment GCP
    // public static WebSocket_URL = "http://35.229.17.101:3000";
    // public static BASE_URLS = "http://35.229.17.101:3001/livepin/";
    //http://35.231.37.82:4009

    //http://192.168.2.6:8080/

    // Production Environment
    // public static WebSocket_URL = "http://ws.hawkeyeway.com";
    //       public static BASE_URLS = "http://apiservices.hawkeyeway.com/livepin/"; 
         public static BASE_URLS = "https://restapi.hawkeyeway.com/livepin/";
         public static EXCEL_DOWNLOAD_URL = 'https://excel.hawkeyeway.com/ReportGeneration/';
      // public static EXCEL_DOWNLOAD_URL = 'http://13.234.77.117:4009/ReportGeneration/';
      //   public static BASE_URLS = "http://localhost:8081/livepin/";

    // public static BASE_URLS2 = "http://35.154.78.134:8080/";
    // public static BASE_URLS3 = "http://192.168.2.74:8081/livepin/";
    public static Google_GeoCoding_Api_Key = 'AIzaSyAzSLr-lgYdkmHX2LkeI2CHKg7CKB6oXUA';
    public static Google_GeoCoding_API = `https://maps.googleapis.com/maps/api/geocode/json?key=${AppConstants.Google_GeoCoding_Api_Key}&`;
    public static public_login = `${AppConstants.BASE_URLS}public/publiclogin`;
    public static passport_public_login = `${AppConstants.BASE_URLS}public/passportpubliclogin`;
    public static get_munispality_table_records = `${AppConstants.BASE_URLS}public/getmunicipalreportsummary/{0}/{1}/999`;
    public static today_get_munispality_table_records = `${AppConstants.BASE_URLS}public/getmunicipalreport/{0}/{1}`;
    public static get_whole_day_data = `${AppConstants.BASE_URLS}public/publicuserhistorybyvehiclenumberanddates?vehicleNumber=`
    public static gps_devices = `${AppConstants.BASE_URLS}public/user/`;
    public static get_dashboard_data_for_report = `${AppConstants.BASE_URLS}public/getvehicledashboarddataforreport`;
    public static get_deviation_tracking_data_for_report = `${AppConstants.BASE_URLS}public/getUserDeviationTrackingReport`;
    public static get_stoppage_report_data_for_report = `${AppConstants.BASE_URLS}public/getUserStoppageReport`;
    public static get_optimal_route_details_from_db = `${AppConstants.BASE_URLS}public/getOptimalRouteDetailsFromDB`;
    public static update_optimal_route_details = `${AppConstants.BASE_URLS}public/updateOptimalRouteDetails`;
    public static get_start_and_stop_coordinates_date_list = `${AppConstants.BASE_URLS}public/getStartAndStopCoordinatesDateList`;
    public static get_trans_company_vehicle_trip_report = `${AppConstants.BASE_URLS}public/getTransCompanyVehicleTripReport`;
    public static get_history_for_all_vehicles_bw_interval = `${AppConstants.BASE_URLS}public/getHistoryForAllVehiclesBWInterval`;
    public static get_history_for_one_vehicle_bw_interval = `${AppConstants.BASE_URLS}public/getHistoryForOneVehicleBWInterval`;
    public static get_Medical_Vehicle_Trip_Tracking = `${AppConstants.BASE_URLS}public/getVehicleTripTracking`;
    public static get_devices_installation_report = `${AppConstants.BASE_URLS}public/getDevicesInstallationReport`;
    public static get_ac_usage_report = `${AppConstants.BASE_URLS}public/getACRunningTimeReport`;
    public static get_diesel_data_for_report = `${AppConstants.BASE_URLS}public/getDieselDataForReport`;
    public static get_history_for_one_vehicle_last_few_minutes = `${AppConstants.BASE_URLS}public/getLastFewMinutesVehiclesLocation`;

    public static post_public_createLRLocation = `${AppConstants.BASE_URLS}public/createLRLocation`;
    public static get_public_getAllLRLocations = `${AppConstants.BASE_URLS}public/getAllLRLocations`;
    public static post_public_updateLRLocations = `${AppConstants.BASE_URLS}public/updateLRLocations`;
    public static get_public_removeLRLocation = `${AppConstants.BASE_URLS}public/removeLRLocation/`;

    //LR API Details
    public static get_all_lr_locations = `${AppConstants.BASE_URLS}public/getAllLRLocations`;
    public static post_create_lr_records = `${AppConstants.BASE_URLS}public/createLRRecords`;
    public static get_all_lr_records = `${AppConstants.BASE_URLS}public/getAllLRRecords`;
    public static post_update_lr_records = `${AppConstants.BASE_URLS}public/updateLRRecords`;
    public static get_remove_lr_records = `${AppConstants.BASE_URLS}public/removeLRRecords/`;
    public static update_current_status_lr_records = `${AppConstants.BASE_URLS}public/updateCurrentStatusLRRecords/`;


    public static post_create_lr_entry = `${AppConstants.BASE_URLS}public/createLREntry`;
    public static get_create_all_lr_entry = `${AppConstants.BASE_URLS}public/getAllLREntry`;
    public static update_lr_entry_status = `${AppConstants.BASE_URLS}public/updateStatusLREntry/`;

    public static post_get_generic_stoppage_report = `${AppConstants.BASE_URLS}public/getGenericStoppageReport`;
    public static post_create_supply_device_request = `${AppConstants.BASE_URLS}public/createSupplyDeviceRequest`;
    public static get_supply_device_assign_by_mobile_number = `${AppConstants.BASE_URLS}public/getSupplyDeviceAssignByMobileNumber/`;

    public static post_create_supply_device_assign = `${AppConstants.BASE_URLS}public/createSupplyDeviceAssign`;
    public static post_update_supply_device_assign = `${AppConstants.BASE_URLS}public/updateSupplyDeviceAssign`;
    public static post_remove_supply_device_assign = `${AppConstants.BASE_URLS}public/removeSupplyDeviceAssign/`;
    // public static post_create_supply_device_request = `${AppConstants.BASE_URLS}public/createSupplyDeviceRequest/`;
    public static get_all_supply_device_request = `${AppConstants.BASE_URLS}public/getAllSupplyDeviceRequest`;
    public static get_all_input_stock = `${AppConstants.BASE_URLS}public/getAllInputStock`;
    //http://192.168.2.74:8081/livepin/public/getmunicipalreport/"2018-06-28"/"2018-07-02" livepin/public/getAllSupplyDeviceRequest; getAllInputStock
    public static get_trip_status_analyser_data = `${AppConstants.BASE_URLS}public/getTripStatusAnalyserData`;
    public static get_gpsdevice = `${AppConstants.BASE_URLS}public/user/`;
    public static get_trip_status_for_vehicle_number = `${AppConstants.BASE_URLS}public/getTripStatusForVehicleNumber/`
    public static update_trip_status_analyser_data = `${AppConstants.BASE_URLS}public/updateTripStatusAnalyserData`

    // Medical Logistics Management
    
    public static get_Medi_Customer_BaseData = `${AppConstants.BASE_URLS}public/getMediCustomerBaseData/`;
    public static get_All_Latest_Trips_Of_every_IMEI = `${AppConstants.BASE_URLS}public/getAllLatestTripsOfeveryIMEI/`;
    public static get_Latest_Trip_Of_Specific_Stop = `${AppConstants.BASE_URLS}public/getLatestTripOfSpecificStop/`;
    public static get_Trips_Of_All_Vehicle_BW_Dates = `${AppConstants.BASE_URLS}public/getTripsOfAllVehicleBWDates/`;
  
    

    public static save_Medical_Vehicle_Trip_Info = `${AppConstants.BASE_URLS}public/saveMedicalVehicleTripInfo`
    constructor() { }




}