import { Injectable, Output, EventEmitter } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AppConstants } from './appconstants';
import { Observable } from 'rxjs/Rx';
import { Config } from './config'
import 'rxjs/add/operator/toPromise';

@Injectable()
export class HttpService extends AppConstants {


    @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

    constructor(private http: Http) {
        super();
    }

    private HOST;

    httpGetPromise(path: string, baseUrl: string, reqOptions?: any): Promise<any> {

        this.HOST = baseUrl;
        let uri = path;
        if (!reqOptions) {
            let headers = new Headers({ 'accept': 'application/json', 'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1OWEzYmViOGUzM2M3ZjU4ODE2NTYyYzAiLCJtb2JpbGVOdW1iZXIiOiIwMTIzNDU2Nzg5IiwiaWF0IjoxNTA0ODc1Nzk1fQ.cmJ1DSjlNLZA0K3pkIAdDTV0mqj3pW-YZTZ9N3vK6sA' }); // ... Set content type to JSON
            reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        }
        return this.http.get(uri, reqOptions).toPromise().then(this.extractResponse).catch(this.handleErrorForPromise);
    }

    httpPostPromise(path: string, reqBody: any, baseUrl: string, reqOptions?: any): Promise<any> {
        this.HOST = baseUrl;
        let uri = path;
        if (!reqOptions) {
            let headers = new Headers({ 'Content-Type': 'application/json', 'accept': 'application/json' }); // ... Set content type to JSON
            reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        }
        return this.http.post(uri, reqBody, reqOptions).toPromise().then(this.extractResponse).catch(this.handleErrorForPromise);
    }

    httpPutPromise(path: string, reqBody: any, baseUrl: string, reqOptions?: any): Promise<any> {
        this.HOST = baseUrl;
        let uri = path;
        if (!reqOptions) {
            let headers = new Headers({ 'Content-Type': 'application/json', 'accept': 'application/json' }); // ... Set content type to JSON
            reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        }
        return this.http.put(uri, reqBody, reqOptions).toPromise().then(this.extractResponse).catch(this.handleErrorForPromise);
    }

    httpDeletePromise(path: string, baseUrl: string, reqOptions?: any): Promise<any> {
        this.HOST = baseUrl;
        let uri = path;

        if (!reqOptions) {
            let headers = new Headers({ 'Content-Type': 'application/json', 'accept': 'application/json' }); // ... Set content type to JSON
            reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        }
        return this.http.delete(uri).toPromise().then(this.extractResponse).catch(this.handleErrorForPromise);
    }

    extractResponse(res: Response): any {
        let assign = res.json();

        return assign;
    }

    handleErrorForPromise(err: Response | any): any {

        if (err instanceof Response) {
            return Promise.reject(err);
        } else {
            return Promise.reject(err);
        }
    }

    handleErrorForObservable(err: Response | any): any {
        if (err instanceof Response) {
            return Observable.throw(err);
        }
        else {
            return Observable.throw(err);
        }
    }

    /*   login(email: string, password: string): Observable<boolean> {
          if (successfulLogIn(email, password)) {
              this.getLoggedInName.emit(fullName);
              return true;
          } else {
              this.getLoggedInName.emit('Sign In');
              return false;
          }
      } 

    logout(): void {
        this.getLoggedInName.emit('Sign In');
    }*/

}