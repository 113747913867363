import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';

@Component({
    selector: 'app-vehicleHistorySummary',
    templateUrl: './vehicleHistorySummary.component.html',
    styleUrls: ['./vehicleHistorySummary.component.scss']
})

export class VehicleHistorySummaryReport implements OnInit {

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicleNumber: any;
    dashboardData: any;

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.getVehicleList();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicleNumber = this.gpsDevicesArray[0].vehicleNumber;
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
        })
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {
        let body = [];
        this.dashboardData.startAndStopCoords.forEach(element => {
            delete element.imeiNumber;
            delete element.historyTimeStamp;
            delete element.locationDetails;
            delete element._id;
        });
        body.push(this.dashboardData);
        // console.log("this.dashboardData ==== >>>" + JSON.stringify(body));

        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    async getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        let dashBoardParams = {
            vehicleNumber: this.selectedVehicleNumber,
            toDate: this.toDate.formatted,
            fromDate: this.fromDate.formatted
        }

        // console.log("dashBoardParams === >>" + JSON.stringify(dashBoardParams));

        try {
            let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
            let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
            let dashboardData = await this.httpService.httpPostPromise(AppConstants.get_start_and_stop_coordinates_date_list, dashBoardParams, "", reqOptions);
            // console.log(JSON.stringify(dashboardData));
            this.isPdfNotAvailable = false;
            if (dashboardData && dashboardData.statusCode === 200) {
                this.dashboardData = dashboardData;
                for (const element of this.dashboardData.startAndStopCoords) {
                    let dateTimeStamp = new Date(element.historyDate).getTime();
                    element.historyTimeStamp = dateTimeStamp;
                    element.stopDuration = this.getTimeDifference(element.startTime, element.endTime)
                    element.startTime = this.convertToLocalStringFormatHhMmSs(new Date(element.startTime));
                    element.endTime = this.convertToLocalStringFormatHhMmSs(new Date(element.endTime));
                    if (element && element.locationDetails && element.locationDetails.length > 0) {
                        let path = `${AppConstants.Google_GeoCoding_API}latlng=${element.locationDetails[0].lat},${element.locationDetails[0].lng}`;
                        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
                        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option

                        try {
                            let addressObj = await this.httpService.httpGetPromise(path, "", reqOptions);
                            element.startLocation = addressObj.results[0].formatted_address;

                            let path2 = `${AppConstants.Google_GeoCoding_API}latlng=${element.locationDetails[element.locationDetails.length - 1].lat},${element.locationDetails[element.locationDetails.length - 1].lng}`;
                            let addressObj2 = await this.httpService.httpGetPromise(path2, "", reqOptions);
                            element.stopLocation = addressObj2.results[0].formatted_address;
                        } catch (err) {

                        }
                    }
                }
                this.dashboardData.startAndStopCoords.sort((a, b) => (a.historyTimeStamp > b.historyTimeStamp) ? 1 : ((b.historyTimeStamp > a.historyTimeStamp) ? -1 : 0));
                this.loading = false;
            }
        } catch (err) {
            this.loading = false;
        }
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/reportsummary', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    onVehicleNumberChange() {
        this.getTableData();
    }

    convertToLocalStringFormatHhMmSs(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        // minutes = minutes < 10 ? '0'+minutes : minutes;
        minutes = ('0' + minutes).slice(-2);
        seconds = ('0' + seconds).slice(-2);
        // let localTimeFormat = hours + ':' + minutes + ' ' + ampm;
        let localTimeFormat = `${hours}:${minutes}:${seconds} ${ampm}`;
        return localTimeFormat;
    }

    getTimeDifference(startTime, endTime) {
        let startDate = new Date(startTime);
        // Do your operations
        let endDate = new Date(endTime);
        let seconds = (endDate.getTime() - startDate.getTime()) / 1000;

        let d = Number(seconds);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        let hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
        let mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
        let sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
        return hDisplay + mDisplay + sDisplay;
    }

}
