import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-deviationTrackingReports',
    templateUrl: './deviationTrackingReports.component.html',
    styleUrls: ['./deviationTrackingReports.component.scss']
})

export class DeviationTrackingReports implements OnInit {

    @ViewChild("mapRef") mapRef: ElementRef;

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    map: any;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'yyyy-mm-dd',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
    };


    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;

    userToken: any;
    userId: any;
    gpsDevicesArray: any = [];
    selectedVehicleNumber: any;
    speedLimit: any = "40";
    dateWiseData: any = {};
    data: any;
    markersArray: any = [];
    googleBounds: any;
    actualPathPolyLine: any;
    expectedPathPolyLine: any;
    selectedTrip: any = "1";

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {
        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.showMap();
        this.getVehicleList();
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicleNumber = this.gpsDevicesArray[0].vehicleNumber;
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
        })
    }

    onTodayClick() {
        this.isTodayClicked = !this.isTodayClicked;
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    convert() {

        let body = [];
        body.push(this.vehicleDetails);
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));
    }

    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    getTableData() {

        // console.log(this.isTodayClicked);

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.loading = true;

        // this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option 
        let reqBody = {
            "vehicleNumber": this.selectedVehicleNumber,
            "fromDate": this.fromDate.formatted,
            "toDate": this.toDate.formatted
        }
        this.httpService.httpPostPromise(AppConstants.get_deviation_tracking_data_for_report, reqBody, '', reqOptions).then((data) => {
            if(data.length=== 0){
                this.loading = false;
                 Swal.fire({
                  title: 'Livepin',
                  text: 'Please Select Other Date!',
                  imageUrl: '../../assets/statusImg/no_recordFond.png',
                  imageWidth: 400,
                  imageHeight: 200,
                  imageAlt: 'Please Select Other Date',
                  animation: false
                })
            }else{
            // console.log("data" + JSON.stringify(data));
            this.data = data;
            this.isPdfNotAvailable = false;
            this.loading = false;
            this.dateWiseData = data;
            this.dateWiseData.sort((a, b) => (a.historyDate > b.historyDate) ? 1 : ((b.historyDate > a.historyDate) ? -1 : 0));

            // this.constructTableData();
            }

        }).catch((err) => {
            let timerInterval
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong please try again later!',
                  timer: 2000,
                  onBeforeOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                    }, 100)
                  },
                  onClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.timer
                  ) {
                    console.log('I was closed by the timer')
                  }
                })
            console.log(err)
            this.loading = false;

        })
    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', 'http://18.222.222.9:8080/ReportGeneration/tripsdetails', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }

    showMap() {
        // navigator.geolocation.getCurrentPosition((position) => {
        //     console.log("Got position", position.coords);
        //     var options = {
        //         center: { lat: position.coords.latitude, lng: position.coords.longitude },
        //         zoom: 15
        //     }
        //     this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        // });

        const location = { lat: 12.916476, lng: 77.602750 };
        var options = {
            center: location,
            zoom: 15
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
    }
    addMarket(map, pos) {
        return new google.maps.Marker({
            position: pos,
            map: map,
        });
    }

    clearOverlays() {
        // for (var i = 0; i < this.markersArray.length; i++) {
        //     this.markersArray[i].setMap(null);
        // }
        // this.markersArray.length = 0;
        if (this.actualPathPolyLine) {
            this.actualPathPolyLine.setMap(null);
        }

        if (this.expectedPathPolyLine) {
            this.expectedPathPolyLine.setMap(null);
        }
    }

    onVehicleNumberChange() {
        this.showMap();
        this.getTableData();
    }


    onTripClick(trip) {

        this.googleBounds = new google.maps.LatLngBounds();
        this.clearOverlays();
        this.drawPolyline(trip.locationDetails, this.map, false);

        if (trip.locationDetails.length > 11) {
            let otherMarekerList = trip.locationDetails.slice(10);
            this.drawPolyline(otherMarekerList, this.map, true);
        }

    }

    drawPolyline(PolylineCoords, map, forExpectedPolyLine) {
        // console.log("hiiiii 4444");

        PolylineCoords.forEach(element => {
            this.googleBounds.extend(new google.maps.LatLng(element.lat, element.lng));
        });

        this.map.fitBounds(this.googleBounds);

        if (forExpectedPolyLine) {
            this.expectedPathPolyLine = new google.maps.Polyline({
                strokeOpacity: 1.0,
                strokeWeight: 2,
                path: PolylineCoords,
                clickable: false
            });
            this.expectedPathPolyLine.setOptions({ strokeColor: 'green' });
            this.expectedPathPolyLine.setMap(map);
        } else {
            this.actualPathPolyLine = new google.maps.Polyline({
                strokeOpacity: 1.0,
                strokeWeight: 2,
                path: PolylineCoords,
                clickable: false
            });
            this.actualPathPolyLine.setOptions({ strokeColor: 'red' });
            this.actualPathPolyLine.setMap(map);
        }
    }

}
