import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Headers, RequestOptions } from '@angular/http';
import { HttpService } from 'app/base/http.service';
import { AppConstants } from 'app/base/appconstants';
import { Location } from '@angular/common';

@Component({
  selector: 'app-device-assign',
  templateUrl: './device-assign.component.html',
  styleUrls: ['./device-assign.component.scss']
})
export class DeviceAssignComponent implements OnInit {
  public reciveTableData: any = [];
  cols: any[];
  sortF: any;
  statusChange: any;

  date: { year: number, month: number };
  model: NgbDateStruct;
  assignDeviceInfoForm: FormGroup;
  submitted = false;
  uploadedFiles: any[] = [];
  selectedDate: any;
  changedimeiNumber: number;
  changedTransitType: number;
  loading: boolean = false;

  TransitTypes = [
    { 'name': 'Bus', 'value': 0 },
    { 'name': 'Courier', 'value': 1 },
    { 'name': 'Inperson', 'value': 2 }
  ]

  IMEINUMBERS = [
    { 'imeiNumber': '0123456789', 'value': 0 },
    { 'imeiNumber': '1234567890', 'value': 1 },
    { 'imeiNumber': '2345678901', 'value': 2 }
  ]
  userToken: any;
  allIMEINumbers = [];
  onlyImeiNumbers = [];
  selectedImeiNumbers = [];

  constructor(private formBuilder: FormBuilder,
    private httpService: HttpService,
    private location: Location) { }


  ngOnInit() {

    this.userToken = localStorage.getItem("userToken");
    let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken });
    let reqOptions = new RequestOptions({ headers: headers });
    this.httpService.httpGetPromise(AppConstants.get_all_input_stock, "", reqOptions).then((allImeiNumbers) => {

      // console.log('--------------List Of IMEI Numbers>>>>', allImeiNumbers);
      this.allIMEINumbers = allImeiNumbers.inputStockData;
      // console.log('--------------List Of IMEI Numbers>>>>', this.allIMEINumbers);
      this.allIMEINumbers.forEach(IMEIData => {
        this.onlyImeiNumbers.push(IMEIData.imeiNumber);
      })
      console.log('--------------List Of IMEI Numbers>>>>', this.onlyImeiNumbers);
    })


    this.assignDeviceInfo();
  }


  changeIMEIType($event) {
    console.log($event.target.value);
    this.changedimeiNumber = parseInt($event.target.value);
    console.log('this.imeivalue', this.changedimeiNumber);
    this.assignDeviceInfoForm.value.imeiNumber = this.changedimeiNumber;
    console.log(this.assignDeviceInfoForm.value.imeiNumber = this.changedimeiNumber);
    // this.assignDeviceInfoForm.get('imeiNumber').setValue($event.target.value, {
    //   onlySelf: true
    // });
  }

  changeTransitType($event) {
    console.log($event.target.value);
    this.changedTransitType = parseInt($event.target.value);
    console.log('this.typeTransitValue', this.changedTransitType);
    // this.assignDeviceInfoForm.value.typeofTransit = this.changedTransitType;
    // console.log(this.assignDeviceInfoForm.value.typeofTransit = this.changedTransitType);
    this.assignDeviceInfoForm.get('typeofTransit').setValue($event.target.value, {
      onlySelf: true
    });
  }

  toAisDashBoard() {
    this.location.back()
  }

  assignDeviceInfo() {
    this.assignDeviceInfoForm = this.formBuilder.group({
      imeiNumber: '',
      parcelNumber: '',
      parcelType: '',
      typeofTransit: '',
      parcelContactNumber: ''

    });
  }

  onSubmit() {
    this.loading = true;


    // let reqBody1 = this.assignDeviceInfoForm.value;

    console.log(this.assignDeviceInfoForm.value);
    this.allIMEINumbers.forEach(data => {
      data.isAsssigned = true;
    })
    console.log('SSSSSSSSSSSS', this.allIMEINumbers);
    let reqBody = {
      imeiNumber: this.allIMEINumbers,
      parcelContactNumber: this.assignDeviceInfoForm.value.parcelContactNumber,
      parcelNumber: this.assignDeviceInfoForm.value.parcelNumber,
      parcelType: this.assignDeviceInfoForm.value.parcelType,
      typeofTransit: this.assignDeviceInfoForm.value.typeofTransit,
    }

    console.log('reqBody', reqBody);
    let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
    let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
    this.httpService.httpPostPromise(AppConstants.post_create_supply_device_assign, reqBody, "", reqOptions).then((dashboardData) => {
      console.log("dashboarddata..............", JSON.stringify(dashboardData));
      this.loading = false;
      (this.assignDeviceInfoForm).reset();
    });
  }

  public doRemove = (value: any) => {
    console.log('LorryReceiptComponent.doRemove', value);
    // const remove = this.removeDestinatinName(value)
    // console.log('remove===>', remove);
  }

  public doSelectDestination = (value: any) => {
    console.log('value of doSelect', value)

    //this.selectedDestination = this.getDestinationFilter(value)
    this.selectedImeiNumbers.push(this.getDestinationFilter(value)[0])
    console.log('selectedImeiNumbers======>', this.selectedImeiNumbers);
  }
  getDestinationFilter(imeiValue) {
    return this.allIMEINumbers.filter(imeNumber => imeNumber.imeiNumber === imeiValue)
  }

}
