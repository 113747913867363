import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AppConstants } from '../base/appconstants';
import { HttpService } from '../base/http.service';
import { URLStringFormatter } from '../base/url-string-formatter';
declare var require: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');
import $ from 'jquery'
import { Observable } from 'rxjs';
declare var google: any;
import { Headers, RequestOptions } from '@angular/http';
import { VehicleIcons } from '../base/VehicleIcons';
import { AnimateHelpers } from '../base/animatehelpers';

@Component({
    selector: 'app-intervalBandLocation',
    templateUrl: './intervalBandLocation.component.html',
    styleUrls: ['./intervalBandLocation.component.scss']
})

export class IntervalBandLocation implements OnInit {

    @ViewChild("mapRef") mapRef: ElementRef;

    vehicleDetails: any = { 'result': [] };
    isTodayClicked: boolean = false;
    loading = false;
    selectedFromTime: any;
    selectedToTime: any;
    map: any;
    // Initialized to specific date (09.10.2018).
    public fromDate: any;
    public toDate: any;
    isSubmitClick: boolean = false;
    isPdfNotAvailable: boolean = true;
    userToken: any;
    userId: any;
    selectedVehicle: any;
    speedLimit: any = "40";
    dateWiseData: any = {};
    data: any;
    markersArray: any = [];
    polyLinesArray: any = [];
    gpsDevicesArray: any = [];
    googleBounds: any;
    userMobile: any;
    totalDistanceTravelled: any = 0;
    isPauseVisible: boolean = false;
    selectedInterval: any = "60";
    filteredLocations: any = [];
    totalDistance: any = 0;
    asignedGpsDevice: any = [];
    defalultVehicle:any;

    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'dd-mm-yyyy',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }

    };

    public myDatePickerOptions2: IMyDpOptions = {
        // other options...
        dateFormat: 'dd-mm-yyyy',
        disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
    };

    constructor(private httpService: HttpService, private urlFormatter: URLStringFormatter) {

    }

    ngOnInit() {

        this.userToken = localStorage.getItem("userToken");
        this.userId = localStorage.getItem("userId");
        this.userMobile = localStorage.getItem("userMobile");
        // this.showMap();
        this.getVehicleList();
    }
    setFromTime(fromTime){
        this.selectedFromTime = fromTime;
        console.log(this.selectedFromTime);
     }
     setToTime(toTime){
       this.selectedToTime = toTime;
       console.log(this.selectedToTime);
    }

    getVehicleList() {
        let path = `${AppConstants.gps_devices}${this.userId}/gpsdevice`;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpGetPromise(path, "", reqOptions).then((gpsDevicesResponse) => {
            this.gpsDevicesArray = gpsDevicesResponse.gpsDevices;
            this.selectedVehicle = this.gpsDevicesArray[0];
            this.gpsDevicesArray.forEach(element => {
              this.asignedGpsDevice.push(element.vehicleNumber);
            });
            this.defalultVehicle = this.asignedGpsDevice[0];
            // console.log('--------------oooooooooo0000000>>>>', this.gpsDevicesArray);
        })
    }


    onTodayClick() {
        this.isTodayClicked = !this.isTodayClicked;
    }

    onDateChanged(event: IMyDateModel) {


        if (event && event.jsdate) {
            let d: Date = new Date(event.jsdate.getTime());
            d.setDate(d.getDate() - 1);
            let copy: IMyDpOptions = this.getCopyOfEndDateOptions();
            copy.disableUntil = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate()
            };
            this.myDatePickerOptions2 = copy;
        }
    }

    getCopyOfEndDateOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions2));
    }


    onClearClick() {
        this.isPdfNotAvailable = true;
        this.fromDate = null;
        this.toDate = null;
        this.isSubmitClick = false;
        this.vehicleDetails = null;
    }

    todaysDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

     formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    async getTableData() {

        if (!(this.fromDate && this.fromDate.formatted)) {
            alert('Please select From Date first');
            return;
        }

        if (!(this.toDate && this.toDate.formatted)) {
            alert('Please select To Date first');
            return;
        }

        if (!(this.selectedFromTime)) {
            alert('Please select From Time first');
            return;
        }

        if (!(this.selectedToTime)) {
            alert('Please select To Time first');
            return;
        }

        this.isSubmitClick = true;
        this.isPdfNotAvailable = true;
        this.totalDistance = 0;
        var splippedFromDate = this.fromDate.formatted.split("-");
        var splippedToDate = this.toDate.formatted.split("-");
       
         let oriFromDate = new Date(splippedFromDate[2]+"-"+splippedFromDate[1]+"-"+splippedFromDate[0]);
         let oriToDate = new Date(splippedToDate[2]+"-"+splippedToDate[1]+"-"+splippedToDate[0]);

         let FromDateFormated = new Date(oriFromDate.getTime() - oriFromDate.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]
         let ToDateFormated = new Date(oriToDate.getTime() - oriToDate.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]
         
         

        let reqBody = {
            "userId": this.userMobile,
            "fromDate": FromDateFormated,
            "toDate": ToDateFormated,
            "fromTimeStamp": this.selectedFromTime,
            "toTimeStamp": this.selectedToTime,
            "imeiNumber": this.selectedVehicle.imeiNumber,
        }
        this.loading = true;

        // this.userToken = userDataArray.userToken;
        let headers = new Headers({ 'accept': 'application/json', 'x-access-token': this.userToken }); //  ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        this.httpService.httpPostPromise(AppConstants.get_history_for_one_vehicle_bw_interval, reqBody, '', reqOptions).then((data) => {

            delete data.statusMessage;
            this.data = data;
            this.filteredLocations = this.timeBandCalculation(data);
            let tempDistance = 0;
            let tempLocationDetails = this.data.dataList[0].locationDetails;
            console.log(tempLocationDetails);
            this.totalDistanceTravelled = this.calculateTotalDistanceFromHaversineFormula(tempLocationDetails);
            this.data.dataList[0].locationDetails.forEach((locObj, index) => {
                if (index == 0) {
                    locObj.distance = "- NA -";
                } else {
                   // this.totalDistance = this.totalDistance + ((((+this.distanceFrom(this.data.dataList[0].locationDetails[index - 1], locObj) / 1000) * 7) / 100) + (+this.distanceFrom(this.data.dataList[0].locationDetails[index - 1], locObj) / 1000)).toFixed(2);
                      this.totalDistance = this.totalDistance  + parseFloat(this.distanceFromLocal(this.data.dataList[0].locationDetails[index - 1], locObj));
                      locObj.distance = this.totalDistance .toFixed(2) + " Kms";
                }
                if(index >= this.data.dataList[0].locationDetails.length-1 ){
                    locObj.distance = this.totalDistanceTravelled ;
                    locObj.distance = locObj.distance + " Kms"
                 }
            }); 

            

           

            this.filteredLocations.forEach(async locObj => {
                locObj.date = this.getFormattedDate(locObj.timestamp)
                locObj.time = new Date(locObj.timestamp).toLocaleTimeString();
                let path = `${AppConstants.Google_GeoCoding_API}latlng=${locObj.lat},${locObj.lng}`;
                let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
                let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
                try {
                    let addressObj = await this.httpService.httpGetPromise(path, "", reqOptions);
                    locObj.address = addressObj.results[0].formatted_address;
                }

                
                catch (error) {
                    console.log(error)
                }

                for (let i = 0; i < this.data.dataList[0].locationDetails.length - 2; i++) {
               
                    let currentLatLng = this.data.dataList[0].locationDetails[i];
                    let nextLatLng = this.data.dataList[0].locationDetails[i + 1];
                    //this.totalDistance += this.distanceFrom(currentLatLng, nextLatLng);
                 //   console.log(currentLatLng);
                 //   console.log(nextLatLng);
                  }
    
                

            });

            this.isPdfNotAvailable = false;
            this.loading = false;

        }).catch((err) => {
            this.loading = false;
        })
    }
    showMap(bandData) {

        const location = { lat: bandData.lat, lng: bandData.lng };
        var options = {
            center: location,
            zoom: 15
        }

        this.map = new google.maps.Map(this.mapRef.nativeElement, options);
        this.googleBounds = new google.maps.LatLngBounds();
        this.clearOverlays();
        // this.totalDistanceTravelled = this.calculateTotalDistanceFromHaversineFormula(this.data.dataList[0].locationDetails);
        this.data.dataList.forEach(data => {
            let randomColor = "#000000".replace(/0/g, () => { return (~~(Math.random() * 16)).toString(16); });
            this.drawPolyline(data.locationDetails, randomColor)
        });
        this.applymarkers({ lat: bandData.lat, lng: bandData.lng, timestamp: bandData.timestamp }, bandData.speed, bandData.address);
        // this.map.fitBounds(this.googleBounds);
    }

    applymarkers(locObject, speed, address) {

        // console.log(speed);
        // console.log(address);

        let infoWindow = new google.maps.InfoWindow({
            content: ''
        });
        let presentPos = new google.maps.LatLng(locObject.lat, locObject.lng);

        let vehicleMarker = new google.maps.Marker({
            position: presentPos,
            // animation: google.maps.Animation.DROP,
            map: this.map
        });

        google.maps.event.clearInstanceListeners(vehicleMarker);
        this.markersArray.push(vehicleMarker);
        vehicleMarker.addListener('click', () => {

            let contentString = `<table style="width:200px">
            <tr>
              <td> <b> Speed : ` + speed + ` </b></td>
              </tr>
              <tr>
              <td> <b> address : ` + address + ` </b></td>
              </tr>
              <tr>
              <td> <b> Time : ` + new Date(locObject.timestamp).toLocaleTimeString(); + ` </b></td>
              </tr>
              </table>`;
            infoWindow.setContent(contentString);
            infoWindow.open(this.map, vehicleMarker);

        });
    }

    getAddressFromGoogle(lat, lng) {
        let path = `${AppConstants.Google_GeoCoding_API}latlng=${lat},${lng}`;
        let headers = new Headers({ 'accept': 'application/json' }); // ... Set content type to JSON
        let reqOptions = new RequestOptions({ headers: headers }); // Create a request option
        return this.httpService.httpGetPromise(path, "", reqOptions);
    }

    clearOverlays() {
        for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
        }
        this.markersArray.length = 0;

        this.polyLinesArray.forEach(polyLine => {
            polyLine.setMap(null);
        });
    }


    drawPolyline(PolylineCoords, color) {
        // console.log("hiiiii 4444");

        let historyPolyLine = new google.maps.Polyline({
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 3,
            path: PolylineCoords,
            clickable: false
        });
        historyPolyLine.setMap(this.map);
        this.polyLinesArray.push(historyPolyLine)
    }

    onVehicleNumberChange(device) {
        // this.showMap();
        this.selectedVehicle = device;
        this.getTableData();
    }

    onIntervalChange(interval) {
        this.totalDistance = 0;
        this.selectedInterval = interval;
        this.getTableData();

    }

    calculateTotalDistanceFromHaversineFormula(locationDetails) {
        if (locationDetails.length >= 3) {
          // locationDetails.sort((a, b) => Number(new Date(a.timestamp).getTime()) - Number(new Date(b.timestamp).getTime()));
          let lengthOfLocationDetails = locationDetails.length;
    
          let totalDistance = 0;
          for (let i = 0; i < lengthOfLocationDetails - 2; i++) {
            let currentLatLng = locationDetails[i];
            let nextLatLng = locationDetails[i + 1];
            // console.log(JSON.stringify(currentLatLng) + " DDDDDD " + this.distanceFrom(currentLatLng, nextLatLng) + '----' + JSON.stringify(nextLatLng));
            totalDistance += this.distanceFrom(currentLatLng, nextLatLng);
    
           //  console.log(JSON.stringify(currentLatLng) + " distance --" + this.distanceFrom(currentLatLng, nextLatLng));
    
          }
         // let totalDistanceInKms = ((((+totalDistance / 1000) * 7) / 100) + (+totalDistance / 1000)).toFixed(2);
          let totalDistanceInKms = ((+totalDistance / 1000)).toFixed(2);
          return totalDistanceInKms;
        }
        else {
          return;
        }
      }
    distanceFromLocal(oldLatLng, newLatLng) {
        let EarthRadiusMeters = 6378137.0; // meters
        let lat1 = oldLatLng.lat;
        let lon1 = oldLatLng.lng;
        let lat2 = newLatLng.lat;
        let lon2 = newLatLng.lng;
        let dLat = (lat2 - lat1) * Math.PI / 180;
        let dLon = (lon2 - lon1) * Math.PI / 180;
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = EarthRadiusMeters * c;
        return (d / 1000).toFixed(2);

    }
    distanceFrom(oldLatLng, newLatLng) {
        let EarthRadiusMeters = 6378137.0; // meters
        let lat1 = oldLatLng.lat;
        let lon1 = oldLatLng.lng;
        let lat2 = newLatLng.lat;
        let lon2 = newLatLng.lng;
        let dLat = (lat2 - lat1) * Math.PI / 180;
        let dLon = (lon2 - lon1) * Math.PI / 180;
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = EarthRadiusMeters * c;
        return d;
    
      }

    

    getFormattedDate(date) {
        var dates = new Date(date);
        var year = dates.getFullYear();

        var month = (1 + dates.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = dates.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }
    timeBandCalculation(data) {

        let filteredListLocations = [];
        let selectedIntervalTImeStamp = parseInt(this.selectedInterval) * 60 * 1000;
        if (data && data.dataList && data.dataList.length > 0) {
            let locationDetails = data.dataList[0].locationDetails;
            filteredListLocations.push(locationDetails[0]);
            let nextLocationTimeStamp = new Date(locationDetails[0].timestamp).getTime() + selectedIntervalTImeStamp;
            locationDetails.forEach((location, index) => {
                if (new Date(location.timestamp).getTime() > nextLocationTimeStamp) {
                    filteredListLocations.push(locationDetails[index - 1]);
                    nextLocationTimeStamp = new Date(locationDetails[index - 1].timestamp).getTime() + selectedIntervalTImeStamp;
                }
                if(index >= locationDetails.length-1 ){
                    filteredListLocations.push(locationDetails[locationDetails.length - 1]);
                }
            });
        }
        
        return filteredListLocations;
    }

    onBandLocatio(bandData) {
        // console.log(JSON.stringify(bandData));
        this.showMap(bandData);
    }

    convert() {

        let value = [];

        this.filteredLocations.forEach(eachLoc => {
            let subValueArray = [];
            subValueArray.push(eachLoc.date);
            subValueArray.push(eachLoc.time);
            subValueArray.push(eachLoc.address);
            subValueArray.push(eachLoc.distance);
            value.push(subValueArray);
        });


        let headerName = "Interval Band Report (" + this.fromDate.formatted + " to " + this.toDate.formatted + ")";
        let body = {
            "headerName": headerName,
            "excelName": "intervalBandReport",
            "header": [
                "Date",
                "Time",
                "Address",
                "Distance"
            ],
            "value": value
        }
        // console.log("body ====" + JSON.stringify(body));
        this.downloadExcelSheet(body).subscribe(blob => {
            // Doing it this way allows you to name the file
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Report.xlsx";
            link.click();
        }, error => console.log("Error downloading the file."),
            () => console.log('Completed file download.'));



    }

    downloadExcelSheet(reqBody): Observable<Object[]> {
        this.loading = true;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', AppConstants.EXCEL_DOWNLOAD_URL + '/genericxls', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'blob';

            xhr.onreadystatechange = () => {
                this.loading = false;
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {

                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(JSON.stringify(reqBody));

        });
    }
    doSelectVehical(event) {
      const vehicalNo = this.gpsDevicesArray.filter(vehicle=>vehicle.vehicleNumber === event);
      this.selectedVehicle = vehicalNo[0];
      console.log('doSelectVehical', event);
      console.log('selectedVehicle', this.selectedVehicle);
      this.getTableData();
    }
    doNgxDefault(){
      return this.defalultVehicle;
    }
}
